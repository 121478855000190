import { Box, Divider, ListItem, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const ClientDetailsWorkOrdersItem = props => {
  const provider = props.requestedTo;

  return (
    <>
      <ListItem sx={{ paddingY: '1rem' }}>
        <Box>
          <Typography variant="h6">
            <Link to={'/dashboard/ordenes/' + props._id}>Orden {props._id}</Link>
          </Typography>
          <Typography variant="body1">Título: {props.title}</Typography>
          <Typography variant="body1">Solicitada el: {new Date(props.createdAt).toLocaleString()}</Typography>
          <Typography variant="body1">Socio: {provider ? <Link to={'/dashboard/socios/' + provider?._id}>{provider?.name} {provider?.lastName}</Link> : 'Sin asignar'}</Typography>
        </Box>
      </ListItem>
      <Divider />
    </>
  );
}

export default ClientDetailsWorkOrdersItem;
