import { SET_POLICIES_LIST, SET_CURRENT_POLICY, RESET_POLICIES_STATE } from '../actions/types/policiesTypes';

const defaultState = {
  list: [],
  current: {
    buyer: { }
  }
};

const policiesReducer = (state = defaultState, { type, payload }) => {
  const newState = { ...state };

  switch(type) {
    case SET_POLICIES_LIST: newState.list = [...payload.policies]; break;
    case SET_CURRENT_POLICY: newState.current = { ...payload.policy }; break;
    case RESET_POLICIES_STATE: newState.list = []; newState.current = { }; break;

    default: break;
  }

  return newState;
}

export default policiesReducer;
