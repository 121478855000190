import { SET_PRODUCTS_LIST, SET_CURRENT_PRODUCT, RESET_PRODUCTS_STATE } from '../actions/types/productsTypes';

const defaultState = {
  list: [],
  current: { }
};

const productsReducer = (state = defaultState, { type, payload }) => {
  const newState = { ...state };

  switch(type) {
    case SET_PRODUCTS_LIST: newState.list = [...payload.products]; break;
    case SET_CURRENT_PRODUCT: newState.current = { ...payload.product }; break;
    case RESET_PRODUCTS_STATE: newState.list = []; newState.current = { }; break;

    default: break;
  }

  return newState;
}

export default productsReducer;
