import { SET_WORK_ORDERS_LIST, SET_CURRENT_WORK_ORDER, RESET_WORK_ORDERS_STATE } from '../actions/types/workOrdersTypes';

const defaultState = {
  list: [],
  current: { }
};

const workOrdersReducer = (state = defaultState, { type, payload }) => {
  const newState = { ...state };

  switch(type) {
    case SET_WORK_ORDERS_LIST: newState.list = [...payload.workOrders]; break;
    case SET_CURRENT_WORK_ORDER: newState.current = { ...payload.workOrder }; break;
    case RESET_WORK_ORDERS_STATE: newState.list = []; newState.current = { }; break;
    default: break;
  }

  return newState;
}

export default workOrdersReducer;
