import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import LeadDetailsCard from './LeadDetailsCard';

import { getCurrentLead, resetLeadsState, updateCurrentLead } from '../../../../actions/creators/leadsActionCreators';

const styles = {
  title: {
    marginBottom: '1rem'
  },
  column: {
    paddingX: '1rem'
  }
}

const LeadDetails = () => {
  const params = useParams();

  const [lead, setLead] = useState({
    _id: '',
    name: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    source: ''
  });
  
  const dispatch = useDispatch();
  const current = useSelector(state => state.leads.current);
  
  useEffect(() => setLead({ ...lead, ...current }), [current]);

  const handleChange = e => {
    setLead({ ...lead, [e.target.name]: e.target.value });
  }

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(updateCurrentLead(params.id, lead));
  }

  useEffect(() => {
    dispatch(getCurrentLead(params.id));
    return () => dispatch(resetLeadsState())
  }, []);

  return (
    <>
      <Typography variant="h5" sx={styles.title} fontWeight="bold">Lead {params.id}</Typography>
      <Grid container>
        <Grid item xs={12} sx={styles.column}>
          <LeadDetailsCard {...lead} onChange={handleChange} onSubmit={handleSubmit} />
        </Grid>
      </Grid>
    </>
  );
}

export default LeadDetails;
