import { Grid, Typography } from '@mui/material';
import HomeWorkOrdersChart from './HomeWorkOrdersChart';
import HomeTotals from './HomeTotals';
import { useQuery, gql } from '@apollo/client';

const styles = {
  title: {
    marginBottom: '1rem'
  },
  column: {
    paddingX: '1rem'
  }
}

const GET_QUIZZES = gql`
  query Query($options: PaginationOptions!) {
  getQuizzs(options: $options) {
    docs {
      _id
      answers {
        blockName
        name
        values
      }
    }
    totalPages
    totalDocs
    prevPage
    pagingCounter
    page
    offset
    nextPage
    limit
    hasPrevPage
    hasNextPage
  }
}
`;

const Home = () => {
  const { loading, error, data } = useQuery(GET_QUIZZES,{variables:{
  "options": {
    "limit": 10,
    "page": 1
  }
}});
  console.log(data)
  return (
    <>
      <Typography variant="h5" fontWeight="bold" sx={styles.title}>Resumen</Typography>
      <Grid container>
        <Grid item xs={12} md={7} lg={8} sx={styles.column}>
          <HomeWorkOrdersChart />
        </Grid>
        <Grid item xs={12} md={5} lg={4} sx={styles.column}>
          <HomeTotals />
        </Grid>
      </Grid>
    </>
  );
}

export default Home;
