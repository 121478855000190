import { useDispatch } from 'react-redux';
import { Box, Button, Card, CardContent, CardHeader } from '@mui/material';
import QRCode from 'react-qr-code';

import { finishCurrentActivation } from '../../../../../actions/creators/activationsActionCreators';

const styles = {
  card: {
    marginBottom: '1rem'
  },
  content: {
    textAlign: 'center'
  },
  button: {
    marginBottom: 0,
    marginTop: '1.5rem'
  }
}

const ProviderActivationQr = props => {
  const dispatch = useDispatch();

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(finishCurrentActivation(props.id));
  }

  return(
    <Card sx={styles.card}>
      <CardHeader title="Código de afiliación" />
      <CardContent sx={styles.content}>
        <Box>
          <QRCode value={props.id} />
        </Box>
        <Button variant="text" sx={styles.button} onClick={handleSubmit}>Activar</Button>
      </CardContent>
    </Card>
  );
}

export default ProviderActivationQr;
