import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Collapse, Typography, Box, Grid, TextField, Button, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { getServicesList } from '../../../actions/creators/servicesActionCreators';


const states = [
  'Aguascalientes',      'Baja California',
  'Baja California Sur', 'Chiapas',
  'Chihuahua',           'Ciudad de México',
  'Coahuila',            'Colima',
  'Durango',             'Estado de México',
  'Guanajuato',          'Guerrero',
  'Hidalgo',             'Jalisco',
  'Michoacán',           'Morelos',
  'Nayarit',             'Nuevo León',
  'Oaxaca',              'Puebla',
  'Querétaro',           'Quintana Roo',
  'San Luis Potosí',     'Sinaloa',
  'Sonora',              'Tabasco',
  'Tamaulipas',          'Tlaxcala',
  'Veracruz',            'Yucatán'
]

const styles = {
  paper: {
    marginBottom: '1rem'
  },
  header: {
    padding: '1rem',
    display: 'flex',
    justifyContent: 'around'
  },
  title: {
    flex: 1
  },
  container: {
    padding: '1rem',
    paddingTop: '0'
  },
  inputWrapper: {
    paddingX: '0.5rem'
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-around'
  }
}

const ProvidersFilter = props => {
  const dispatch = useDispatch();
  const services = useSelector(state => state.services.list);

  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(!open);

  useEffect(() => dispatch(getServicesList()), []);

  return (
    <Paper elevation={3} sx={styles.paper}>
      <Box sx={styles.header} onClick={toggleOpen}>
        <Typography variant="h6" fontWeight="bold" sx={styles.title}>Filtro</Typography>
        <ArrowDropDownIcon />
      </Box>
      <Collapse in={open}>
        <Grid container component="form" sx={styles.container} onSubmit={props.onSubmit}>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Nombre" name="name" value={props.name} onChange={props.onChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Apellido" name="lastName" value={props.lastName} onChange={props.onChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Correo" name="email" value={props.email} onChange={props.onChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Teléfono" name="phoneNumber" value={props.phoneNumber} onChange={props.onChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField select label="Servicio" name="service" value={props.service} onChange={props.onChange}>
              <MenuItem value="">Cualquiera</MenuItem>
              {services.map(service => <MenuItem key={service._id} value={service._id}>{service.name}</MenuItem>)}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField select label="Status" name="status" value={props.status} onChange={props.onChange}>
              <MenuItem value="">Cualquiera</MenuItem>
              <MenuItem value="Disponible">Disponible</MenuItem>
              <MenuItem value="Indispuesto">Indispuesto</MenuItem>
              <MenuItem value="Desactivado">Desactivado</MenuItem>
              <MenuItem value="Bloqueado">Bloqueado</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField select label="Estado" name="state" value={props.state} onChange={props.onChange}>
              <MenuItem value="">Todos</MenuItem>
              {states.map(state => <MenuItem key={state} value={state}>{state}</MenuItem>)}         
            </TextField>
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField select label="Factura" name="invoice" value={props.invoice} onChange={props.onChange}>
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="Si">Si</MenuItem>
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Regularizarse">Regularizarse</MenuItem>              
            </TextField>
          </Grid>
          <Grid item xs={12} sx={styles.actions}>
            <Button type="submit">Buscar</Button>
            <Button onClick={props.onClear} color="neutral">Limpiar</Button>
          </Grid>
        </Grid>
      </Collapse>
    </Paper>
  );
}

export default ProvidersFilter;