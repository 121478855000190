import { Box, Card, CardHeader, CardContent, Typography, TextField, Button, MenuItem } from '@mui/material';

import BenefitDetailsFormAudience from './BenefitDetailsFormAudience';

const styles = {
  card: {
    marginBottom: '1.5rem'
  },
  audiencesContainer: {
    margin: '0.5rem'
  },
}

const BenefitDetailsForm = props => {
  const audiences = [
    { label: 'Clientes', value: 'Client' },
    { label: 'Socios', value: 'Provider' },
  ];

  return (
    <Card sx={styles.card}>
      <CardHeader title={'Beneficio: ' + props.title} />
      <CardContent>
        <TextField label="Título" name="title" value={props.title} onChange={props.onChange} />
        <TextField label="Descripción" name="description" value={props.description} onChange={props.onChange} multiline rows={4} />
        <TextField label="CTA (texto)" name="ctaText" value={props.ctaText} onChange={props.onChange} />
        <TextField label="CTA (url)" name="ctaUrl" value={props.ctaUrl} onChange={props.onChange} />
        <TextField select label="Status" name="enabled" value={props.enabled} onChange={props.onChange}>
          <MenuItem value={true}>Encendido</MenuItem>
          <MenuItem value={false}>Apagado</MenuItem>
        </TextField>
        <TextField select label="Tipo" name="type" value={props.type} onChange={props.onChange}>
          <MenuItem value="Seguro">Seguro</MenuItem>
          <MenuItem value="Crédito">Crédito</MenuItem>
          <MenuItem value="Anuncio">Anuncio</MenuItem>
        </TextField>
        <Box sx={styles.audiencesContainer}>
          <Typography variant="body2">Audiencias</Typography>
          <Box sx={{ padding: '0.5rem 0' }}>
            {audiences.map(audience => <BenefitDetailsFormAudience key={audience.value} {...audience} audiences={props.audiences} onChange={props.onChange} />)}
          </Box>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Button variant="contained" onClick={props.onSubmit}>Guardar</Button>
        </Box>
        <Typography align="right" variant="body2">Creado: {new Date(props.createdAt)?.toLocaleString()}</Typography>
      </CardContent>
    </Card>
  );
}

export default BenefitDetailsForm;
