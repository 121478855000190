import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Grid, TextField, Dialog, DialogContent, DialogTitle, Button, DialogActions, MenuItem } from '@mui/material';
import moment from 'moment';

import { createNewPromo } from '../../../actions/creators/promosActionCreators';

const styles = {
  container: {
    display: 'block'
  },
  content: {
    paddingY: 0
  },
  actions: {
    paddingTop: 0,
    paddingRight: '1rem'
  },
  inputWrapper: {
    paddingX: '0.25rem'
  },
}

const PromosCreateDialog = props => {
  const [promo, setPromo] = useState({
    code: '',
    action: '',
    expiration: moment().format('YYYY-MM-DDTHH:mm'),
    type: '',
    value: 0
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = e => {
    e.preventDefault();
    props.onClose();
  }

  const handleChange = e => {
    setPromo({ ...promo, [e.target.name]: e.target.value });
  }

  const handleRedirect = id => {
    navigate('/dashboard/promociones/' + id);
  }

  const handleSubmit = e => {
    e.preventDefault();

    dispatch(createNewPromo(promo, handleRedirect));
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>Nueva promoción</DialogTitle>
      <DialogContent sx={styles.content}>
        <Grid container>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField label="Código" name="code" value={promo.code} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField select label="Acción" name="action" value={promo.action} onChange={handleChange}>
              <MenuItem value="APPLY_AMOUNT_DISCOUNT">Descuento monto</MenuItem>
              <MenuItem value="APPLY_PERCENTAGE_DISCOUNT">Descuento procentaje</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField type="number" label="Valor" name="value" value={promo.value} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField select label="Tipo" name="type" value={promo.type} onChange={handleChange}>
              <MenuItem value="work_order">Servicio</MenuItem>
              <MenuItem value="product">Producto</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField type="datetime-local" label="Expiración" name="expiration" value={promo.expiration} onChange={handleChange} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.actions}>
      <Button variant="text" onClick={handleSubmit}>Guardar</Button>
        <Button variant="text" onClick={handleClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default PromosCreateDialog;
