import { useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import Home from './pages/Dashboard/Home/Home';
import Clients from './pages/Dashboard/Clients/Clients';
import ClientDetails from './pages/Dashboard/Clients/ClientDetails/ClientDetails';
import Providers from './pages/Dashboard/Providers/Providers';
import ProviderDetails from './pages/Dashboard/Providers/ProviderDetails/ProviderDetails';
import ProviderActivation from './pages/Dashboard/Providers/ProviderDetails/ProviderActivation/ProviderActivation';
import WorkOrders from './pages/Dashboard/WorkOrders/WorkOrders';
import WorkOrderDetails from './pages/Dashboard/WorkOrders/WorkOrderDetails/WorkOrderDetails';
import Services from './pages/Dashboard/Services/Services';
import Issues from './pages/Dashboard/Issues/Issues';
import IssueDetails from './pages/Dashboard/Issues/IssueDetails/IssueDetails';
import Benefits from './pages/Dashboard/Benefits/Benefits';
import BenefitDetails from './pages/Dashboard/Benefits/BenefitDetails/BenefitDetails';
import Products from './pages/Dashboard/Products/Products';
import ProductDetails from './pages/Dashboard/Products/ProductDetails/ProductDetails';
import ProductForms from './pages/Dashboard/Products/ProductsForms/ProductsForms';
import Policies from './pages/Dashboard/Policies/Policies';
import PolicyDetails from './pages/Dashboard/Policies/PolicyDetails/PolicyDetails';
import Leads from './pages/Dashboard/Leads/Leads';
import LeadDetails from './pages/Dashboard/Leads/LeadDetails/LeadDetails';
import Promos from './pages/Dashboard/Promos/Promos';
import PromoDetails from './pages/Dashboard/Promos/PromoDetails/PromoDetails';
import Accidents from './pages/Dashboard/Accidents/Accidents';
import AccidentDetails from './pages/Dashboard/Accidents/AccidentDetails/AccidentDetails';
import Bundles from './pages/Dashboard/Bundles/Bundles';
import BundleDetails from './pages/Dashboard/Bundles/BundleDetails/BundleDetails';
import Marketing from './pages/Dashboard/Marketing/Marketing';
import Ikea from './pages/Dashboard/Ikea/Ikea';
import ReportsPage from './pages/Dashboard/Reports/Reports';

const Router = () => {
  const token = useSelector(state => state.auth.token);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={token ? <Navigate to="/dashboard" /> : <Login />} />
        <Route path="/dashboard/*" element={token ? <Dashboard /> : <Navigate to="/login" />}>
          <Route index element={<Home />} />
          <Route path="clientes" element={<Clients />} />
          <Route path="clientes/:id" element={<ClientDetails />} />
          <Route path="socios" element={<Providers />} />
          <Route path="socios/:id" element={<ProviderDetails />} />
          <Route path="socios/:id/afiliacion" element={<ProviderActivation />} />
          <Route path="ordenes" element={<WorkOrders />} />
          <Route path="ordenes/:id" element={<WorkOrderDetails />} />
          <Route path="servicios" element={<Services />} />
          <Route path="incidencias" element={<Issues />} />
          <Route path="incidencias/:id" element={<IssueDetails />} />
          <Route path="beneficios" element={<Benefits />} />
          <Route path="beneficios/:id" element={<BenefitDetails />} />
          <Route path="productos" element={<Products />} />
          <Route path="productos/:id" element={<ProductDetails />} />
          <Route path="productos/:id/nuevo" element={<ProductForms />} />
          <Route path="polizas" element={<Policies />} />
          <Route path="polizas/:id" element={<PolicyDetails />} />
          <Route path="leads" element={<Leads />} />
          <Route path="leads/:id" element={<LeadDetails />} />
          <Route path="promociones" element={<Promos />} />
          <Route path="promociones/:id" element={<PromoDetails />} />
          <Route path="siniestros" element={<Accidents />} />
          <Route path="siniestros/:id" element={<AccidentDetails />} />
          <Route path="paquetes" element={<Bundles />} />
          <Route path="paquetes/:id" element={<BundleDetails />} />
          <Route path="marketing" element={<Marketing />} />
          <Route path="ikea-demo" element={<Ikea />} />
          <Route path="reports" element={<ReportsPage />} />
          <Route path="*" element={<Navigate to="" />} />
        </Route>
        <Route path="*" element={token ? <Navigate to="/dashboard" /> : <Navigate to="/login" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
