import { getAccident, getAccidents, updateAccident } from '../../api/accidentsApi';
import { SET_ACCIDENTS_LIST, SET_CURRENT_ACCIDENT, RESET_ACCIDENTS_STATE } from '../types/accidentsTypes';
import { startLoading, endLoading } from './loadingActionCreators';
import { showAlert } from './alertsActionCreators';
import { handleError } from './errorsActionCreators';

export const setAccidentsList = accidents => ({
  type: SET_ACCIDENTS_LIST,
  payload: { accidents }
});

export const setCurrentAccident = accident => ({
  type: SET_CURRENT_ACCIDENT,
  payload: { accident }
});

export const resetAccidentsState = () => ({
  type: RESET_ACCIDENTS_STATE,
  payload: { }
});

export const getAccidentsList = query => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getAccidents(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setAccidentsList(data.accidents)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
};

export const getCurrentAccident = id => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getAccident(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentAccident(data.accident)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const updateCurrentAccident = (id, accident) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  updateAccident(token, id, accident)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentAccident(data.accident)))
  .then(() => dispatch(showAlert('Accident actualizado', 'success')))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}
