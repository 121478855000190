import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Card, CardHeader, CardContent, Table, TableBody, TableRow, TableCell, Typography } from '@mui/material';

import { getMetricsTotals } from '../../../actions/creators/metricsActionCreators';

const styles = {
  tableWrapper: {
    marginBottom: '1rem'
  }
}

const HomeTotals = () => {
  const totals = useSelector(state => state.metrics.totals);
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch();

  useEffect(() => dispatch(getMetricsTotals()), []);


  if(!auth) return

  return (
    <Card>
      <CardHeader title="Totales" />
      <CardContent>
        <Box sx={styles.tableWrapper}>
          <Typography variant="h6" fontWeight="bold" align="center">General</Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Clientes</TableCell>
                <TableCell>{totals?.total?.clients}</TableCell>
              </TableRow>
              {auth.org ==="milusos" && 
                <TableRow>
                  <TableCell>Socios</TableCell>
                  <TableCell>{totals?.total?.providers}</TableCell>
                </TableRow>
              }              
              <TableRow>
                <TableCell>Órdenes</TableCell>
                <TableCell>{totals?.total?.workOrders}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
        <Box sx={styles.tableWrapper}>
          <Typography variant="h6" fontWeight="bold" align="center">Esta semana</Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Clientes</TableCell>
                <TableCell>{totals?.week?.clients}</TableCell>
              </TableRow>
              {auth.org ==="milusos" && 
                <TableRow>
                  <TableCell>Socios</TableCell>
                  <TableCell>{totals?.week?.providers}</TableCell>
                </TableRow>
              }                 
              <TableRow>
                <TableCell>Órdenes</TableCell>
                <TableCell>{totals?.week?.workOrders}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
        <Box sx={styles.tableWrapper}>
          <Typography variant="h6" fontWeight="bold" align="center">Este mes</Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Clientes</TableCell>
                <TableCell>{totals?.month?.clients}</TableCell>
              </TableRow>
              {auth.org ==="milusos" && 
                <TableRow>
                  <TableCell>Socios</TableCell>
                  <TableCell>{totals?.month?.providers}</TableCell>
                </TableRow>
              }              
              <TableRow>
                <TableCell>Órdenes</TableCell>
                <TableCell>{totals?.month?.workOrders}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </CardContent>
    </Card>
  );
}

export default HomeTotals;
