const { REACT_APP_API_BASE_URL } = process.env;

export const getLastWorkOrders = (token, query) => new Promise((resolve, reject) => {
  const queryParams = new URLSearchParams({
    ...query,
  }).toString();

  const request = new Request(REACT_APP_API_BASE_URL + '/metrics/last?' + queryParams, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getTotals = (token, query) => new Promise((resolve, reject) => {
  const queryParams = new URLSearchParams({
    ...query,
  }).toString();

  const request = new Request(REACT_APP_API_BASE_URL + '/metrics/totals?' + queryParams, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getServicesStatistics = (token, query) => new Promise((resolve, reject) => {
  const queryParams = new URLSearchParams({
    ...query,
  }).toString();

  const request = new Request(REACT_APP_API_BASE_URL + '/metrics/services/statistics?' + queryParams, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getServicesCosts = (token, query) => new Promise((resolve, reject) => {
  const queryParams = new URLSearchParams({
    ...query,
  }).toString();

  const request = new Request(REACT_APP_API_BASE_URL + '/metrics/services/costs?' + queryParams, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});
