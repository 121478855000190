import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import ProviderDetailsCard from './ProviderDetailsCard';
import ProviderDetailsForm from './ProviderDetailsForm';
import ProviderDetailsServices from './ProviderDetailsServices';

import { getCurrentProvider, resetProvidersState } from '../../../../actions/creators/providersActionCreators';
import { getServicesList } from '../../../../actions/creators/servicesActionCreators';
import ProviderDetailsResetPassword from './ProviderDetailsResetPassword';
import { getBundlesList } from '../../../../actions/creators/bundlesActionCreators';
import ProviderDetailsWorkOrders from './ProviderDetailsWorkOrders';

const styles = {
  title: {
    marginBottom: '1rem'
  },
  column: {
    paddingX: '1rem'
  }
}

const ProviderDetails = () => {
  const params = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentProvider(params.id));
    dispatch(getServicesList());
    dispatch(getBundlesList());
    return () => dispatch(resetProvidersState())
  }, [dispatch, params.id]);

  return (
    <>
      <Typography variant="h5" sx={styles.title} fontWeight="bold">Socio {params.id}</Typography>
      <Grid container>
        <Grid item xs={12} md={4} sx={styles.column}>
          <ProviderDetailsCard />
          <ProviderDetailsResetPassword id={params.id} />
        </Grid>
        <Grid item xs={12} md={8} sx={styles.column}>
          <ProviderDetailsForm />
          <ProviderDetailsServices />
          <ProviderDetailsWorkOrders id={params.id} />
        </Grid>
      </Grid>
    </>
  );
}

export default ProviderDetails;
