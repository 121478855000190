import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import PromoDetailsCard from './PromoDetailsCard';

import { getCurrentPromo, resetPromosState, updateCurrentPromo } from '../../../../actions/creators/promosActionCreators';

const styles = {
  title: {
    marginBottom: '1rem'
  },
  column: {
    paddingX: '1rem'
  }
}

const PromoDetails = () => {
  const params = useParams();

  const [promo, setPromo] = useState({
    _id: '',
    code: '',
    action: '',
    expiration: moment().format('YYYY-MM-DDTHH:mm'),
    type: '',
    value: 0,
    active: false,
  });
  
  const dispatch = useDispatch();
  const current = useSelector(state => state.promos.current);
  
  useEffect(() => setPromo({ ...promo, ...current, expiration: moment(current.expiration).format('YYYY-MM-DDTHH:mm') }), [current]);

  const handleChange = e => {
    setPromo({ ...promo, [e.target.name]: e.target.value });
  }

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(updateCurrentPromo(params.id, promo));
  }

  useEffect(() => {
    dispatch(getCurrentPromo(params.id));
    return () => dispatch(resetPromosState())
  }, []);

  return (
    <>
      <Typography variant="h5" sx={styles.title} fontWeight="bold">Promoción {params.id}</Typography>
      <Grid container>
        <Grid item xs={12} sx={styles.column}>
          <PromoDetailsCard {...promo} onChange={handleChange} onSubmit={handleSubmit} />
        </Grid>
      </Grid>
    </>
  );
}

export default PromoDetails;
