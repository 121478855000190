import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardContent } from '@mui/material';
import Chart from 'chart.js/auto';
import { getMarketingWorkOrdersSource } from '../../../actions/creators/marketingActionCreators';

const styles = {
  card: {
    marginBottom: '1.5rem'
  },
  container: {
    height: '70vh',
    display: 'flex',
    flexDirection: 'column'
  },
  chart: {
    flex: '1'
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
}

const buildCountChart = (workOrdersSource) => ({
  type: 'bar',
  data: {
    labels: workOrdersSource.map(item => item._id),
    datasets:  [
      {
            label: "Origen",
            data: workOrdersSource.map(item => item.count),
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1
          },
    ]
  },
  options: {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      }
    },
    plugins: {
      title: {
        display: true,
        text: 'Origen del servicio',
        font: { size: 30 }
      }
    }
  }
});

const MarketingStatisticsChart = () => {
  const canvas = useRef();

  const workOrdersSource = useSelector(state => state.marketing.workOrdersSource);
  const dispatch = useDispatch();
  console.log(workOrdersSource)

  useEffect(() => {
    dispatch(getMarketingWorkOrdersSource());
  }, []);

  useEffect(() => {
    const chart = new Chart(canvas.current, buildCountChart(workOrdersSource));

    chart.update();

    return () => chart.destroy();
  }, [canvas,  workOrdersSource]);


  return (
    <Card elevation={3} sx={styles.card}>
      <CardContent sx={styles.container}>
        <Box sx={styles.chart}>
          <canvas style={styles.canvas} ref={canvas} />
        </Box>
      </CardContent>
    </Card>
  );
}

export default MarketingStatisticsChart;
