import { SET_ORGANIZATIONS_LIST } from '../actions/types/organizationsTypes';

const defaultState = {
  list: []
};

const organizationsReducer = (state = defaultState, { type, payload }) => {
  const newState = { ...state };

  switch(type) {
    case SET_ORGANIZATIONS_LIST: newState.list = [...payload.organizations]; break;
    default: break;
  }

  return newState;
}

export default organizationsReducer;
