import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import LeadsListTable from './LeadsListTable';
import LeadsFilter from './LeadsFilter';
import AppPagination from '../../../components/AppPagination';

import { getLeadsList, resetLeadsState } from '../../../actions/creators/leadsActionCreators';
import { useFilteredPagination } from '../../../utils/tablesUtils';

const defaultFilter = {
  name: '',
  lastName: '',
  email: '',
  phoneNumber: ''
};

const Leads = () => {
  const {
    filter, 
    handleChange, 
    onSubmit, 
    onClear, 
    nextPage, 
    prevPage, 
    page
  } = useFilteredPagination(defaultFilter, resetLeadsState, getLeadsList);
  const dispatch = useDispatch();
  const leads = useSelector(state => state.leads.list);

  useEffect(() => {
    return () => dispatch(resetLeadsState());
  }, [dispatch]);



  return (
    <>
      <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '1rem' }}>Leads</Typography>
      <LeadsFilter {...filter} onChange={handleChange} onSubmit={onSubmit} onClear={onClear} />
      <LeadsListTable leads={leads} />
      <AppPagination page={page} prev={prevPage} next={nextPage} hasNextPage={leads.length !== 0} />
    </>
  );
}

export default Leads;
