import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Card, CardContent, CardHeader, List, Typography } from '@mui/material';
import ProviderActivationUpdatesItem from './ProviderActivationUpdatesItem';

const styles = {
  card: {
    marginBottom: '1rem'
  },
  emptyList: {
    textAlign: 'center',
    py: 4
  }
}

const ProviderActivationUpdates = () => {
  const [updatesList, setUpdatesList] = useState([]);
  const updates = useSelector(state => state.activations.current?.updates);

  useEffect(() => setUpdatesList(updates ?? []), [updates]);

  return (
    <Card sx={styles.card}>
      <CardHeader title="Actualizaciones" />
      <CardContent>
        {updatesList.length > 0
          ? <List>{updatesList.map(update => <ProviderActivationUpdatesItem {...update} key={update.createdAt} />)}</List>
          : <Box sx={styles.emptyList}><Typography fontWeight="bold">Sin actualizaciones para mostrar</Typography></Box>
        }
      </CardContent>
    </Card>
  );
}

export default ProviderActivationUpdates;
