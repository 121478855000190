import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardContent, Checkbox } from '@mui/material';
import Chart from 'chart.js/auto';

import { getMetricsServicesStatistics } from '../../../actions/creators/metricsActionCreators';

const styles = {
  card: {
    marginBottom: '1.5rem'
  },
  container: {
    height: '70vh',
    display: 'flex',
    flexDirection: 'column'
  },
  chart: {
    flex: '1'
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
}

const buildCountChart = (statistics, segmented = false) => ({
  type: 'bar',
  data: {
    labels: statistics.map(s => s.serviceRef?.name ?? 'Servicio'),
    datasets: !segmented ? [{
      label: 'Servicios solicitados',
      backgroundColor: 'rgba(75, 192, 192, 0.7)',
      data: statistics.map(s => s.total),
    }] : [
      {
        label: 'Esperando',
        backgroundColor: 'rgba(255, 205, 86, 0.7)',
        data: statistics.map(s => s.awaiting),
      },
      {
        label: 'Aceptado',
        backgroundColor: 'rgba(75, 192, 192, 0.7)',
        data: statistics.map(s => s.accepted),
      },
      {
        label: 'Iniciado',
        backgroundColor: 'rgba(153, 102, 255, 0.7)',
        data: statistics.map(s => s.started),
      },
      {
        label: 'Completado',
        backgroundColor: 'rgba(54, 102, 255, 0.7)',
        data: statistics.map(s => s.completed),
      },
      {
        label: 'Terminado',
        backgroundColor: 'rgba(54, 162, 235, 0.7)',
        data: statistics.map(s => s.finished),
      },
      {
        label: 'Cancelado',
        backgroundColor: 'rgba(255, 99, 132, 0.7)',
        data: statistics.map(s => s.cancelled),
      },
      {
        label: 'Otro',
        backgroundColor: 'rgba(201, 203, 207, 0.7)',
        data: statistics.map(s => s.other),
      },
    ]
  },
  options: {
    maintainAspectRatio: false,
    scales: {
      x: { stacked: true, },
      y: { stacked: true }
    },
    plugins: {
      title: {
        display: true,
        text: 'Demanda de servicios',
        font: { size: 30 }
      }
    }
  }
});

const ServicesStatisticsChart = () => {
  const canvas = useRef();
  const [segmented, setSegmented] = useState(true);

  const statistics = useSelector(state => state.metrics.services.statistics);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMetricsServicesStatistics());
  }, []);

  useEffect(() => {
    const chart = new Chart(canvas.current, buildCountChart(statistics, segmented));

    chart.update();

    return () => chart.destroy();
  }, [canvas, segmented, statistics]);

  const handleChange = e => setSegmented(e.target.checked);

  return (
    <Card elevation={3} sx={styles.card}>
      <CardContent sx={styles.container}>
        <Box sx={styles.checkboxWrapper}>
          <Checkbox name="interval" checked={segmented} onChange={handleChange} />Segmentar
        </Box>
        <Box sx={styles.chart}>
          <canvas style={styles.canvas} ref={canvas} />
        </Box>
      </CardContent>
    </Card>
  );
}

export default ServicesStatisticsChart;
