import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import IssueDetailsCard from './IssueDetailsCard';
import IssueDetailsUser from './IssueDetailsUser';

import { getCurrentIssue, resetIssuesState } from '../../../../actions/creators/issuesActionCreators';

const styles = {
  title: {
    marginBottom: '1rem'
  },
  column: {
    paddingX: '1rem'
  }
}

const IssueDetails = () => {
  const params = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentIssue(params.id));
    return () => dispatch(resetIssuesState())
  }, []);

  return (
    <>
      <Typography variant="h5" sx={styles.title} fontWeight="bold">Incidencia {params.id}</Typography>
      <Grid container>
        <Grid item xs={12} md={5} sx={styles.column}>
          <IssueDetailsCard />
        </Grid>
        <Grid item xs={12} md={7} sx={styles.column}>
          <IssueDetailsUser />
        </Grid>
      </Grid>
    </>
  );
}

export default IssueDetails;
