import { TextField } from '@mui/material';

const ProductBeneficiaryForm = props => {
  const handleChange = e => {
    props.onChange(e, props.index);
  }

  return (
    <>
      <TextField label="Nombre" name="name" value={props.name} onChange={handleChange} />
      <TextField label="Parentesco" name="relationship" value={props.relationship} onChange={handleChange} />
      <TextField type="number" label="Porcentaje" name="percentage" value={props.percentage} onChange={handleChange} />
    </>
  );
}

export default ProductBeneficiaryForm;
