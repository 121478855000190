import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardHeader, CardMedia, Typography } from '@mui/material';
import ProviderActivationImageCard from './ProviderActivationImageCard';

const { REACT_APP_HOST_URL } = process.env;

const styles = {
  card: {
    marginBottom: '1rem'
  },
  media: {
    height: '25vh',
    cursor: 'pointer'
  },
  avatar: {
    width: '80px',
    height: '80px'
  },
  header: {
    color: '#000',
    backgroundColor: '#FFF',
    textAlign: 'start'
  },
  content: {
    paddingTop: '0'
  },
  bottonWrapper: {
    textAlign: 'center',
    marginTop: '1rem'
  }
}

const ProviderActivationCard = props => {
  const [openPhoto, setOpenPhoto] = useState(false);
  const [timestamp, setTimestamp] = useState(new Date().toISOString());

  const getField = type => {
    switch(type) {
      case 'ine-front': return 'ineFrontUrl';
      case 'ine-back': return 'ineBackUrl';
      case 'proof-of-address': return 'proofOfAddressUrl';
     default: return '';
    }
  }

  const documentsUrls = useSelector(state => state.activations.current?.documentsUrls);

  const imgUrl = documentsUrls?.[getField(props.type)];

  const handleOpenPhoto = () => setOpenPhoto(true);
  const handleClosePhoto = () => setOpenPhoto(false);
  
  const photoUrl = (imgUrl ? REACT_APP_HOST_URL + imgUrl : '/img/missing.png') + '?timestamp=' + timestamp;

  useEffect(() => setTimestamp(new Date().toISOString()), [documentsUrls]);

  return (
    <>
      <Card sx={styles.card}>
        <CardHeader sx={styles.header} title={<Typography variant="subtitle1">{props.title}</Typography>} />
        <CardMedia key={Date.now()} component="img" image={photoUrl} sx={styles.media} onClick={handleOpenPhoto} />
      </Card>
      <ProviderActivationImageCard open={openPhoto} title={props.title} type={props.type} id={props.id} src={photoUrl} onClose={handleClosePhoto} />
    </>
  );
}

export default ProviderActivationCard;
