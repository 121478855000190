import { START_LOADING, END_LOADING } from '../types/loadingTypes';

export const startLoading = () => ({
  type: START_LOADING,
  payload: { }
});

export const endLoading = () => ({
  type: END_LOADING,
  payload: { }
});
