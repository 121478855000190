import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Grid, TextField, Dialog, DialogContent, DialogTitle, Button, DialogActions } from '@mui/material';

import { createNewProvider } from '../../../actions/creators/providersActionCreators';

const styles = {
  container: {
    display: 'block'
  },
  content: {
    paddingY: 0
  },
  actions: {
    paddingTop: 0,
    paddingRight: '1rem'
  },
  inputWrapper: {
    paddingX: '0.25rem'
  },
}

const ProvidersCreateDialog = props => {
  const [provider, setProvider] = useState({
    _id: '',
    name: '',
    lastName: '',
    email: '',
    phoneNumber: '+52',
    password: '123456'
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = e => {
    e.preventDefault();
    props.onClose();
  }

  const handleChange = e => {
    setProvider({ ...provider, [e.target.name]: e.target.value });
  }

  const handleRedirect = id => {
    navigate('/dashboard/socios/' + id);
  }

  const handleSubmit = e => {
    e.preventDefault();

    dispatch(createNewProvider(provider, handleRedirect));
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>Nuevo socio</DialogTitle>
      <DialogContent sx={styles.content}>
        <Grid container>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Nombre" name="name" value={provider.name} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Apellido" name="lastName" value={provider.lastName} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Correo" name="email" value={provider.email} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Teléfono" name="phoneNumber" value={provider.phoneNumber} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField label="Contraseña" name="password" value={provider.password} onChange={handleChange} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.actions}>
      <Button variant="text" onClick={handleSubmit}>Guardar</Button>
        <Button variant="text" onClick={handleClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ProvidersCreateDialog;
