import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import WorkOrdersListTable from './WorkOrdersListTable';
import AppPagination from '../../../components/AppPagination';
import WorkOrdersFilter from './WorkOrdersFilter';

import { getWorkOrdersList, resetWorkOrdersState } from '../../../actions/creators/workOrdersActionCreators';
import WorkOrdersCreateDialog from './WorkOrdersCreateDialog';
import { useFilteredPagination } from '../../../utils/tablesUtils';

const defaultFilter = {
  title: '',
  description: '',
  status: '',
  service: '',
  organization: 'Todas',
  record: false
};

const WorkOrders = () => {
  const {
    filter, 
    handleChange, 
    onSubmit, 
    onClear, 
    nextPage, 
    prevPage, 
    page
  } = useFilteredPagination(defaultFilter, resetWorkOrdersState, getWorkOrdersList);
  const dispatch = useDispatch();
  const workOrders = useSelector(state => state.workOrders.list);
  const [showCreate, setShowCreate] = useState(false);

  useEffect(() => {
    return () => dispatch(resetWorkOrdersState());
  }, [dispatch]);

  const openCreate = () => setShowCreate(true);
  const closeCreate = () => setShowCreate(false);

  return (
    <>
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '1rem' }}>Órdenes de trabajo</Typography>
        <Button sx={{ marginTop: '0' }} color="primary" onClick={openCreate}>Nuevo</Button>
      </Box>
      
      <WorkOrdersFilter {...filter} onChange={handleChange} onSubmit={onSubmit} onClear={onClear} />
      <WorkOrdersListTable workOrders={workOrders} />
      <AppPagination page={page} prev={prevPage} next={nextPage} hasNextPage={workOrders.length !== 0}/>
      <WorkOrdersCreateDialog open={showCreate} onClose={closeCreate} />
    </>
  );
}

export default WorkOrders;
