import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import IssuesListItem from './IssuesListItem';

const IssuesListTable = props => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead color="primary">
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Usuario</TableCell>
            <TableCell>Problema</TableCell>
            <TableCell>Creado en</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.issues?.map(issue => <IssuesListItem key={issue._id} {...issue} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default IssuesListTable;
