import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import BenefitsListTable from './BenefitsListTable';
import BenefitsFilter from './BenefitsFilter';
import BenefitsCreateDialog from './BenefitsCreateDialog';
import AppPagination from '../../../components/AppPagination';

import { getBenefitsList, resetBenefitsState } from '../../../actions/creators/benefitsActionCreators';
import { useFilteredPagination } from '../../../utils/tablesUtils';

const defaultFilter = {
  title: '',
  description: '',
  enabled: true,
  type: '',
  audiences: ''
};

const Benefits = () => {
  const {
    filter, 
    handleChange, 
    onSubmit, 
    onClear, 
    nextPage, 
    prevPage, 
    page
  } = useFilteredPagination(defaultFilter, resetBenefitsState, getBenefitsList);
  const dispatch = useDispatch();
  const benefits = useSelector(state => state.benefits.list);
  const [showCreate, setShowCreate] = useState(false);

  useEffect(() => {
    return () => dispatch(resetBenefitsState());
  }, [dispatch]);


  const openCreate = () => setShowCreate(true);
  const closeCreate = () => setShowCreate(false);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '1rem' }}>Beneficios</Typography>
        <Button sx={{ marginTop: '0' }} color="primary" onClick={openCreate}>Nuevo</Button>
      </Box>
      <BenefitsFilter {...filter} onChange={handleChange} onSubmit={onSubmit} onClear={onClear} />
      <BenefitsListTable benefits={benefits} />
      <AppPagination page={page} prev={prevPage} next={nextPage} hasNextPage={benefits.length !== 0} />
      <BenefitsCreateDialog open={showCreate} onClose={closeCreate} />
    </>
  );
}

export default Benefits;
