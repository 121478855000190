import { TableRow, TableCell } from '@mui/material';
import { getExtrasLabels } from '../../../../utils/extrasUtils';

const WorkOrderDetailsPaymentsItem = props => {
  const concepts = [];

  props.quote ? concepts.push(props.quote.description) : null;

  props.extras ? concepts.push(...getExtrasLabels(props.extras)) : null;

  return (
    <TableRow>
      <TableCell>${props.total}</TableCell>
      <TableCell>{concepts.join(', ')}</TableCell>
      <TableCell>{props.paid ? 'Sí' : 'No'}</TableCell>
      <TableCell>{props.method ?? 'Sin pagar'}</TableCell>
    </TableRow>
  );
}

export default WorkOrderDetailsPaymentsItem;
