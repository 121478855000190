import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardContent, CardHeader, Table, TableBody, TableRow, TableCell } from "@mui/material";

const styles = {
  card: {
    marginBottom: '1.5rem'
  },
  inputWrapper: {
    paddingX: '0.5rem'
  },
}

const IssueDetailsForm = () => {
  const [issue, setIssue] = useState({
    _id: '',
    issue: '',
    workOrderRef: { }
  });

  const current = useSelector(state => state.issues.current);
    
  useEffect(() => setIssue({ ...issue, ...current }), [current]);

  return (
    <Card sx={styles.card}>
      <CardHeader title="Detalles del problema" />
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Problema</TableCell>
              <TableCell>{issue.issue}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ID orden de trabajo</TableCell>
              <TableCell>
                <Link to={'/dashboard/ordenes/' + issue.workOrderRef?._id}>{issue.workOrderRef?._id ?? 'Sin asignar'}</Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Título orden de trabajo</TableCell>
              <TableCell>{issue.workOrderRef?.title}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Descripción orden de trabajo</TableCell>
              <TableCell>{issue.workOrderRef?.description}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

export default IssueDetailsForm;
