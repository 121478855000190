const { REACT_APP_API_BASE_URL } = process.env;

export const getProducts = (token, query) => new Promise((resolve, reject) => {
  const queryParams = new URLSearchParams({
    results: 10,
    ...query
  }).toString();

  const request = new Request(REACT_APP_API_BASE_URL + '/products?' + queryParams, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getProduct = (token, id) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/products/' + id, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const createProduct = (token, product) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/products', {
    method: 'POST',
    body: JSON.stringify({ product }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const updateProduct = (token, id, product) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/products/' + id, {
    method: 'PUT',
    body: JSON.stringify({ product }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const uploadProductTemplate = (token, id, doc) => new Promise((resolve, reject) => {
  const data = new FormData();
  data.append('template', doc);

  const request = new Request(`${REACT_APP_API_BASE_URL}/products/${id}/template`, {
    method: 'PUT',
    body: data,
    headers: {
      'Authorization': 'Bearer ' + token
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});
