
import { Box } from '@mui/material';
import MarketingStatisticsChart from './MarketingStatisticsChart';



/* const defaultFilter = {
  interval: false
}; */

const MarketingCharts = () => {
  /* const [filter, setFilter] = useState({ ...defaultFilter });

  const dispatch = useDispatch();

  const handleChange = e => setFilter({ ...filter, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value });


  const handleClear = () => setFilter({ ...defaultFilter }); */

  return (
    <Box>
      {/* <MarketingStatisticsFilter {...filter} onChange={handleChange} onSubmit={handleSubmit} onClear={handleClear} /> */}
      <MarketingStatisticsChart />
    </Box>
  );
}

export default MarketingCharts;
