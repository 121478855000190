import { Box, Card, CardHeader, CardContent, Typography, TextField, Button, MenuItem } from '@mui/material';

const styles = {
  card: {
    marginBottom: '1.5rem'
  }
}

const PromoDetailsCard = props => {
  return (
    <Card sx={styles.card}>
      <CardHeader title={'Promoción ' + props.code} />
      <CardContent>
        <TextField label="Código" name="code" value={props.code} onChange={props.onChange} />
        <TextField select label="Acción" name="action" value={props.action} onChange={props.onChange}>
          <MenuItem value="APPLY_AMOUNT_DISCOUNT">Descuento monto</MenuItem>
          <MenuItem value="APPLY_PERCENTAGE_DISCOUNT">Descuento procentaje</MenuItem>
        </TextField>
        <TextField type="number" label="Valor" name="value" value={props.value} onChange={props.onChange} />
        <TextField select label="Tipo" name="type" value={props.type} onChange={props.onChange}>
          <MenuItem value="work_order">Servicio</MenuItem>
          <MenuItem value="product">Producto</MenuItem>
        </TextField>
        <TextField select label="Estado" name="active" value={props.active} onChange={props.onChange}>
          <MenuItem value={true}>Activo</MenuItem>
          <MenuItem value={false}>Desactivado</MenuItem>
        </TextField>
        <TextField type="datetime-local" label="Expiración" name="expiration" value={props.expiration} onChange={props.onChange} />
        <Box sx={{ textAlign: 'center' }}>
          <Button variant="contained" onClick={props.onSubmit}>Guardar</Button>
        </Box>
        <Typography align="right" variant="body2">Promoe desde: {new Date(props.createdAt)?.toLocaleString()}</Typography>
      </CardContent>
    </Card>
  );
}

export default PromoDetailsCard;
