import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardHeader, CardContent, TextField, Button } from '@mui/material';
import { reassignCurrentWorkOrder } from '../../../../actions/creators/workOrdersActionCreators';
import SearchInput from '../../../../components/SearchInput';
import { getProviders } from '../../../../api/providersApi';

const styles = {
  card: {
    marginBottom: '1.5rem'
  },
  buttonWrapper: {
    textAlign: 'center'
  }
}

const WorkOrderDetailsReassign = props => {
  const [email, setEmail] = useState('');
  const token = useSelector(state => state.auth.token);
  const dispatch = useDispatch();

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(reassignCurrentWorkOrder(props.id, email));
  }

  const searchProviders = async (request,callback)=>{
    const query = { page: 1, multiSearch: true };

    request.input.length > 0 ? query.name = request.input : null;
    const result = await getProviders(token, query).then(response => response.json() )
    callback(result?.providers || [])
  }

  return (
    <Card sx={styles.card}>
      <CardHeader title="Reasignar" />
      <CardContent>
        <SearchInput label="Socio" name="provider" callToApi={searchProviders} placeholder={"Sin Socio"} onSelected={(value)=>setEmail(value?.email)}/>
        <Box sx={styles.buttonWrapper}>
          <Button onClick={handleSubmit} disabled={!email}>Reasignar</Button>
        </Box>
      </CardContent>
    </Card>
  );
}

export default WorkOrderDetailsReassign;
