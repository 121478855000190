import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';

import BundleDetailsForm from './BundleDetailsForm';

import { getCurrentBundle, resetBundlesState, updateCurrentBundle } from '../../../../actions/creators/bundlesActionCreators';

const styles = {
  title: {
    marginBottom: '1rem'
  }
}

const BundleDetails = () => {
  const [bundle, setBundle] = useState({
    _id: '',
    name: '',
    price: '',
    description: '',
    stripeSubscription: '',
  });

  const params = useParams();
  const dispatch = useDispatch();
  const current = useSelector(state => state.bundles.current);

  useEffect(() => {
    dispatch(getCurrentBundle(params.id));
    return () => dispatch(resetBundlesState());
  }, []);
  
  useEffect(() => setBundle({ ...bundle, ...current }), [current]);

  const handleChange = e => {
    setBundle({ ...bundle, [e.target.name]: e.target.value });
  }

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(updateCurrentBundle(params.id, bundle));
  }

  return (
    <>
      <Typography variant="h5" sx={styles.title} fontWeight="bold">Paquete {bundle._id}</Typography>
      <Grid container>
        <Grid item sm={12} sx={{ paddingX: '1rem' }}>
          <BundleDetailsForm {...bundle} onChange={handleChange} onSubmit={handleSubmit} />
        </Grid>
      </Grid>
    </>
  );
}

export default BundleDetails;
