import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import ClientsListTable from './ClientsListTable';
import ClientsFilter from './ClientsFilter';
import AppPagination from '../../../components/AppPagination';

import { getClientsList, resetClientsState } from '../../../actions/creators/clientsActionCreators';
import { useFilteredPagination } from '../../../utils/tablesUtils';

const defaultFilter = {
  name: '',
  email: '',
  phoneNumber: '',
  status: 'Activo',
  state: '',
};

const Clients = () => {
  const {
    filter, 
    handleChange, 
    onSubmit, 
    onClear, 
    nextPage, 
    prevPage, 
    page
  } = useFilteredPagination(defaultFilter, resetClientsState, getClientsList);
  const dispatch = useDispatch();
  const clients = useSelector(state => state.clients.list);

  useEffect(() => {
    return () => dispatch(resetClientsState());
  }, [dispatch]);

  return (
    <>
      <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '1rem' }}>Clientes</Typography>
      <ClientsFilter {...filter} onChange={handleChange} onSubmit={onSubmit} onClear={onClear} />
      <ClientsListTable clients={clients} />
      <AppPagination page={page} prev={prevPage} next={nextPage} hasNextPage={clients.length !== 0}/>
    </>
  );
}

export default Clients;
