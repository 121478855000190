import { TableRow, TableCell } from '@mui/material';

const WorkOrderDetailsQuotesItem = props => {
  return (
    <TableRow>
      <TableCell>${props.quote}</TableCell>
      <TableCell>{props.description}</TableCell>
      <TableCell>{props.duration}</TableCell>
    </TableRow>
  );
}

export default WorkOrderDetailsQuotesItem;
