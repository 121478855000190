const { REACT_APP_API_BASE_URL } = process.env;

export const getQuotes = (token, workOrderId, query) => new Promise((resolve, reject) => {
  const queryParams = new URLSearchParams({
    ...query
  }).toString();

  const request = new Request(`${REACT_APP_API_BASE_URL}/work-orders/${workOrderId}/quotes?${queryParams}`, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getQuote = (token, workOrderId, id) => new Promise((resolve, reject) => {
  const request = new Request(`${REACT_APP_API_BASE_URL}/work-orders/${workOrderId}/quotes/${id}`, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const createQuote = (token, workOrderId, quote) => new Promise((resolve, reject) => {
  const request = new Request(`${REACT_APP_API_BASE_URL}/work-orders/${workOrderId}/quotes`, {
    method: 'POST',
    body: JSON.stringify({ quote }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const updateQuote = (token, workOrderId, id, quote) => new Promise((resolve, reject) => {
  const request = new Request(`${REACT_APP_API_BASE_URL}/work-orders/${workOrderId}/quotes/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ quote }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});
