import { RESET_LEADS_STATE, SET_LEADS_LIST, SET_CURRENT_LEAD } from '../actions/types/leadsTypes';

const defaultState = {
  list: [],
  current: { }
};

const leadsReducer = (state = defaultState, { type, payload }) => {
  const newState = { ...state };

  switch(type) {
    case SET_LEADS_LIST: newState.list = [...payload.leads]; break;
    case SET_CURRENT_LEAD: newState.current = { ...payload.lead }; break;
    case RESET_LEADS_STATE: newState.list = []; newState.current = { }; break;
    default: break;
  }

  return newState;
}

export default leadsReducer;
