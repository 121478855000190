import { RESET_ISSUES_STATE, SET_ISSUES_LIST, SET_CURRENT_ISSUE } from '../actions/types/issuesTypes';

const defaultState = {
  list: [],
  current: { }
};

const issuesReducer = (state = defaultState, { type, payload }) => {
  const newState = { ...state };

  switch(type) {
    case SET_ISSUES_LIST: newState.list = [...payload.issues]; break;
    case SET_CURRENT_ISSUE: newState.current = { ...payload.issue }; break;
    case RESET_ISSUES_STATE: newState.list = []; newState.current = { }; break;
    default: break;
  }

  return newState;
}

export default issuesReducer;
