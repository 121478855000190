import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';

import ProductDetailsForm from './ProductDetailsForm';

import { getCurrentProduct, resetProductsState, updateCurrentProduct } from '../../../../actions/creators/productsActionCreators';

const styles = {
  title: {
    marginBottom: '1rem'
  }
}

const ProductDetails = () => {
  const [product, setProduct] = useState({
    _id: '',
    name: '',
    description: '',
    type: '',
    enabled: false,
    prefix: '',
    price: 0,
    duration: 0,
    stripeSubscription: ''
  });

  const params = useParams();
  const dispatch = useDispatch();
  const current = useSelector(state => state.products.current);

  useEffect(() => {
    dispatch(getCurrentProduct(params.id));
    return () => dispatch(resetProductsState());
  }, []);
  
  useEffect(() => setProduct({ ...product, ...current }), [current]);

  const handleChange = e => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  }

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(updateCurrentProduct(params.id, product));
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5" sx={styles.title} fontWeight="bold">Producto {product._id}</Typography>
        <Link to={`nuevo?form=${product.prefix}`}><Button sx={{ marginTop: '0' }} color="primary">Nueva Póliza</Button></Link>
      </Box>
      <Grid container>
        <Grid item sm={12} sx={{ paddingX: '1rem' }}>
          <ProductDetailsForm {...product} onChange={handleChange} onSubmit={handleSubmit} />
        </Grid>
      </Grid>
    </>
  );
}

export default ProductDetails;
