import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

const { REACT_APP_HOST_URL } = process.env;

const styles = {
  iframe: {
    width: '100%',
    height: '100%'
  },
  container: {
    width: '100%',
    height: {
      xs: '45vh',
      sm: '70vh',
      md: '100%'
    }
  }
}

const PolicyDetailsPreview = () => {
  const docUrl = useSelector(state => state.policies.current.docUrl);

  return (
    <Box sx={styles.container}>
      {docUrl ? <iframe style={styles.iframe} src={`https://docs.google.com/gview?url=${REACT_APP_HOST_URL + docUrl}&embedded=true`} title="preview" />: null}
    </Box>
  );
}

export default PolicyDetailsPreview;
