import { Box, Card, CardHeader, CardContent, Typography, TextField, Button, MenuItem } from '@mui/material';

const styles = {
  card: {
    marginBottom: '1.5rem'
  },
}

const ProductDetailsForm = props => {
  return (
    <Card sx={styles.card}>
      <CardHeader title={'Producto: ' + props.name} />
      <CardContent>
        <TextField label="Nombre" name="name" value={props.name} onChange={props.onChange} />
        <TextField label="Descripción" name="description" value={props.description} onChange={props.onChange} multiline rows={4} />
        <TextField select label="Tipo" name="type" value={props.type} onChange={props.onChange}>
          <MenuItem value="Seguro">Seguro</MenuItem>
          <MenuItem value="Crédito">Crédito</MenuItem>
          <MenuItem value="Anuncio">Anuncio</MenuItem>
        </TextField>
        <TextField label="Prefijo" name="prefix" value={props.prefix} onChange={props.onChange} />
        <TextField type="number" label="Precio" name="price" value={props.price} onChange={props.onChange} />
        <TextField type="number" label="Duración (días)" name="duration" value={props.duration} onChange={props.onChange} />
        <TextField select label="Status" name="enabled" value={props.enabled} onChange={props.onChange}>
          <MenuItem value={true}>Encendido</MenuItem>
          <MenuItem value={false}>Apagado</MenuItem>
        </TextField>
        <TextField label="ID Stripe" name="stripeSubscription" value={props.stripeSubscription} onChange={props.onChange} />
        <Box sx={{ textAlign: 'center' }}>
          <Button variant="contained" onClick={props.onSubmit}>Guardar</Button>
        </Box>
        <Typography align="right" variant="body2">Creado: {new Date(props.createdAt)?.toLocaleString()}</Typography>
      </CardContent>
    </Card>
  );
}

export default ProductDetailsForm;
