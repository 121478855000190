import { SET_BENEFITS_LIST, SET_CURRENT_BENEFIT, RESET_BENEFITS_STATE } from '../actions/types/benefitsTypes';

const defaultState = {
  list: [],
  current: { }
};

const benefitsReducer = (state = defaultState, { type, payload }) => {
  const newState = { ...state };

  switch(type) {
    case SET_BENEFITS_LIST: newState.list = [...payload.benefits]; break;
    case SET_CURRENT_BENEFIT: newState.current = { ...payload.benefit }; break;
    case RESET_BENEFITS_STATE: newState.list = []; newState.current = { }; break;

    default: break;
  }

  return newState;
}

export default benefitsReducer;
