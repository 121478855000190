import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Card, CardContent, CardHeader, TextField, IconButton, MenuItem, Box } from '@mui/material';
import { Add } from '@mui/icons-material';
import ProviderDetailsServicesItem from './ProviderDetailsServicesItem';

import { updateCurrentProvider } from '../../../../actions/creators/providersActionCreators';

const styles = {
  newServiceContainer: {
    display: 'flex'
  },
  newServiceInput: {
    flex: '1'
  },
  newServiceButton: {
    marginX: '1rem',
    my: 'auto'
  }
}

const ProviderDetailsServices = () => {
  const [provider, setProvider] = useState({
    offeredServices: []
  });

  const [newService, setNewService] = useState('');

  const dispatch = useDispatch();
  const current = useSelector(state => state.providers.current);
  const services = useSelector(state => state.services.list);
    
  useEffect(() => setProvider({ ...provider, ...current }), [current]);

  const updateServices = services => {
    const offeredServices = services.map(service => ({ ...service, serviceRef: service.serviceRef._id }));

    dispatch(updateCurrentProvider(provider._id, { offeredServices }));
  }
  
  const handleToggle = id => {
    const services = [...provider.offeredServices];

    const service = services.find(service => service.serviceRef._id === id);
    service.available = !service.available;

    updateServices(services);
  }

  const handleDelete = id => {
    const services = provider.offeredServices.filter(service => service.serviceRef._id !== id);

    updateServices(services);
  }

  const handleChange = e => {
    setNewService(e.target.value)
  }

  const handleAdd = e => {
    e.preventDefault();

    const services = [...provider.offeredServices, {
      available: true,
      serviceRef: { _id: newService }
    }];

    updateServices(services);
  }

  return (
    <Card>
      <CardHeader title="Servicios" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} textAlign="center">
            {provider.offeredServices.map(service => <ProviderDetailsServicesItem key={service.serviceRef._id} onToggle={handleToggle} onDelete={handleDelete} {...service} />)}
          </Grid>
          <Grid item xs={12} sx={styles.newServiceContainer}>
            <TextField select label="Servicio nuevo" sx={styles.newServiceInput} name="newService" value={newService} onChange={handleChange}>
              <MenuItem value="">Agregar nuevo servicio</MenuItem>
              {services.map(service => <MenuItem key={service._id} value={service._id}>{service.name}</MenuItem>)}
            </TextField>
            <Box sx={styles.newServiceButton}>
              <IconButton onClick={handleAdd} disabled={newService === ''}><Add /></IconButton>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ProviderDetailsServices;
