import { getProvider, getProviders, createProvider, updateProvider, uploadProviderPhoto, resetProviderPassword } from '../../api/providersApi';
import { searchPostalCode } from '../../api/postalCodesApi';
import { SET_PROVIDERS_LIST, SET_CURRENT_PROVIDER, RESET_PROVIDERS_STATE } from '../types/providersTypes';
import { startLoading, endLoading } from './loadingActionCreators';
import { showAlert } from './alertsActionCreators';
import { handleError } from './errorsActionCreators';

export const setProvidersList = providers => ({
  type: SET_PROVIDERS_LIST,
  payload: { providers }
});

export const setCurrentProvider = provider => ({
  type: SET_CURRENT_PROVIDER,
  payload: { provider }
});

export const resetProvidersState = () => ({
  type: RESET_PROVIDERS_STATE,
  payload: { }
});

export const getProvidersList = query => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getProviders(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setProvidersList(data.providers)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
};

export const getCurrentProvider = id => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getProvider(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentProvider(data.provider)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const createNewProvider = (provider, callback) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  createProvider(token, provider)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(data => callback(data.provider))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const updateCurrentProvider = (id, provider) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  updateProvider(token, id, provider)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentProvider(data.provider)))
  .then(() => dispatch(showAlert('Socio actualizado', 'success')))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const uploadCurrentProviderPhoto = (id, photo, type) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  uploadProviderPhoto(token, id, photo, type)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentProvider(data.provider)))
  .then(() => dispatch(showAlert('Imagen actualizada', 'success')))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const resetCurrentProviderPassword = (id, password) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  resetProviderPassword(token, id, password)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(() => dispatch(showAlert('Contraseña actualizada', 'success')))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const setProviderPostalCodeData = code => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  searchPostalCode(token, { code })
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => data.postalCodes.pop())
  .then(postalCode => postalCode
    ? dispatch(setCurrentProvider({ city: postalCode.city, state: postalCode.state, country: postalCode.country }))
    : dispatch(showAlert('Código postal inválido', 'error'))
  )
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}
