import { getQuote, getQuotes, createQuote, updateQuote } from '../../api/quotesApi';
import { SET_QUOTES_LIST, SET_CURRENT_QUOTE, RESET_QUOTES_STATE } from '../types/quotesTypes';
import { startLoading, endLoading } from './loadingActionCreators';
import { showAlert } from './alertsActionCreators';
import { handleError } from './errorsActionCreators';

export const setQuotesList = quotes => ({
  type: SET_QUOTES_LIST,
  payload: { quotes }
});

export const setCurrentQuote = quote => ({
  type: SET_CURRENT_QUOTE,
  payload: { quote }
});

export const resetQuotesState = () => ({
  type: RESET_QUOTES_STATE,
  payload: { }
});

export const getQuotesList = (workOrderId, query) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getQuotes(token, workOrderId, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setQuotesList(data.quotes)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
};

export const getCurrentQuote = (workOrderId, id) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getQuote(token, workOrderId, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentQuote(data.quote)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const createNewQuote = (workOrderId, quote) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  createQuote(token, workOrderId, quote)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(() => dispatch(getQuotesList(workOrderId)))
  .then(() => dispatch(showAlert('Presupuesto agregado', 'success')))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const updateCurrentQuote = (workOrderId, id, quote) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  updateQuote(token, workOrderId, id, quote)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentQuote(data.quote)))
  .then(() => dispatch(showAlert('Presupuesto actualizado', 'success')))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}
