import {  SET_FILTER_REPORT, RESET_FILTER_REPORT } from '../actions/types/reportsTypes';

const defaultState = {
  state: '',
  city:'',
};

const reportsReducer = (state = defaultState, { type, payload }) => {
  const newState = { ...state };

  switch(type) {
    case SET_FILTER_REPORT: 
      return {...newState, ...payload}
    case RESET_FILTER_REPORT: 
      return defaultState;
    default:  break;
  }

  return newState;
}

export default reportsReducer;
