import { SET_LAST_WORK_ORDERS, SET_TOTALS, SET_SERVICES_STATISTICS, SET_SERVICES_COSTS } from '../actions/types/metricsTypes';

const defaultState = {
  lastWorkOrders: [],
  totals: { },
  services: {
    statistics: [],
    costs: []
  }
};

const metricsReducer = (state = defaultState, { type, payload }) => {
  const newState = { ...state };

  switch(type) {
    case SET_LAST_WORK_ORDERS: newState.lastWorkOrders = [...payload.lastWorkOrders]; break;
    case SET_TOTALS: newState.totals = { ...payload.totals }; break;
    case SET_SERVICES_STATISTICS: newState.services.statistics = [...payload.statistics]; break;
    case SET_SERVICES_COSTS: newState.services.costs = [...payload.costs]; break;
    default: break;
  }

  return newState;
}

export default metricsReducer;
