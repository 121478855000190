import { TextField } from '@mui/material';

const ProductInsuredForm = props => {
  const handleChange = e => {
    props.onChange(e, props.index);
  }
  return (
    <>
      <TextField label="Nombre" name="name" value={props.name} onChange={handleChange} />
      <TextField label="Apellido" name="lastName" value={props.lastName} onChange={handleChange} />
      <TextField label="Parentesco" name="relationship" value={props.relationship} onChange={handleChange} />
      <TextField type="date" label="Fecha de nacimiento" name="birthdate" value={props.birthdate} onChange={handleChange} />
    </>
  );
}

export default ProductInsuredForm;
