import { Link } from 'react-router-dom';
import { Box, ListItem, Typography } from '@mui/material';
import { getUserPath } from '../../../../../utils/usersUtills';

const ProviderActivationUpdatesItem = props => {
  const updater = `${props.updater?.name} ${props.updater?.lastName}`;
  const updaterType = props.updaterType;
  const updaterId = props.updater?._id;

  return (
    <ListItem>
      <Box>
        <Typography variant="h6" fontWeight="bold">{props.action}</Typography>
        <Typography variant="subtitle1">Por <Link to={`${getUserPath(updaterType)}${updaterId}`}>{updater}</Link> el {new Date(props.createdAt).toLocaleString()}</Typography>
      </Box>
    </ListItem>
  );
}

export default ProviderActivationUpdatesItem;
