import { useState } from 'react';
import { Paper, Collapse, Typography, Box, Grid, TextField, Button, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const styles = {
  paper: {
    marginBottom: '1rem'
  },
  header: {
    padding: '1rem',
    display: 'flex',
    justifyContent: 'around'
  },
  title: {
    flex: 1
  },
  container: {
    padding: '1rem',
    paddingTop: '0'
  },
  inputWrapper: {
    paddingX: '0.5rem'
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-around'
  }
}

const BenefitsFilter = props => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(!open);

  return (
    <Paper elevation={3} sx={styles.paper}>
      <Box sx={styles.header} onClick={toggleOpen}>
        <Typography variant="h6" fontWeight="bold" sx={styles.title}>Filtro</Typography>
        <ArrowDropDownIcon />
      </Box>
      <Collapse in={open}>
        <Grid container component="form" sx={styles.container} onSubmit={props.onSubmit}>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Título" name="title" value={props.title} onChange={props.onChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Descripción" name="description" value={props.description} onChange={props.onChange} />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField select label="Tipo" name="type" value={props.type} onChange={props.onChange}>
              <MenuItem value="">Cualquiera</MenuItem>
              <MenuItem value="Seguro">Seguro</MenuItem>
              <MenuItem value="Crédito">Crédito</MenuItem>
              <MenuItem value="Anuncio">Anuncio</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField select label="Audiencia" name="audiences" value={props.audiences} onChange={props.onChange}>
              <MenuItem value="">Cualquiera</MenuItem>
              <MenuItem value="Client">Clientes</MenuItem>
              <MenuItem value="Provider">Socios</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField select label="Status" name="enabled" value={props.enabled} onChange={props.onChange}>
              <MenuItem value={true}>Encendido</MenuItem>
              <MenuItem value={false}>Apagado</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sx={styles.actions}>
            <Button type="submit">Buscar</Button>
            <Button onClick={props.onClear} color="neutral">Limpiar</Button>
          </Grid>
        </Grid>
      </Collapse>
    </Paper>
  );
}

export default BenefitsFilter;
