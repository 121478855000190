import { searchPostalCode } from '../../api/postalCodesApi';
import {  SET_FILTER_REPORT, RESET_FILTER_REPORT} from '../types/reportsTypes';
import { startLoading, endLoading } from './loadingActionCreators';
import { showAlert } from './alertsActionCreators';
import { handleError } from './errorsActionCreators';
import { getProvidersReportApi } from '../../api/reportsApi';

export const setFilterReport = ({state, city}) => ({
  type: SET_FILTER_REPORT,
  payload: { state, city }
});


export const resetFilterReport = () => ({
  type: RESET_FILTER_REPORT,
  payload: { }
});

export const getProvidersReport = query => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getProvidersReportApi(token, query)
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
};

export const getReport= id => (dispatch, getState) => {
  // const { token } = getState().auth;

  dispatch(startLoading());
  setTimeout(() => {
    dispatch(endLoading())
  }, 1500);

  // getProvider(token, id)
  // .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  // .then(data => console.log(data))
  // .catch(err => dispatch(handleError(err)))
  // .finally(() => dispatch(endLoading()));
}


export const setReportPostalCodeData = code => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  searchPostalCode(token, { code })
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => data.postalCodes.pop())
  .then(postalCode => postalCode
    ? dispatch(setFilterReport({ city: postalCode.city, state: postalCode.state, country: postalCode.country }))
    : dispatch(showAlert('Código postal inválido', 'error'))
  )
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}
