import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';

import WorkOrderDetailsCard from './WorkOrderDetailsCard';
import WorkOrderDetailsParticipant from './WorkOrderDetailsParticipant';
import WorkOrderDetailsReassign from './WorkOrderDetailsReassign';
import WorkOrderDetailsChat from './WorkOrderDetailsChat';
import WorkOrderDetailsQuotes from './WorkOrderDetailsQuotes';

import { getCurrentWorkOrder, resetWorkOrdersState, updateCurrentWorkOrder } from '../../../../actions/creators/workOrdersActionCreators';
import { getQuotesList, resetQuotesState } from '../../../../actions/creators/quotesActionCreators';
import { getPaymentsList, resetPaymentsState } from '../../../../actions/creators/paymentsActionCreators';
import WorkOrderDetailsPayments from './WorkOrderDetailsPayments';
import WorkOrderDetailsActions from './WorkOrderDetailsActions';

const styles = {
  title: {
    marginBottom: '1rem'
  }
}

const WorkOrderDetails = () => {
  const [workOrder, setWorkOrder] = useState({ status: '' });

  const params = useParams();
  const dispatch = useDispatch();
  const current = useSelector(state => state.workOrders.current);
  const auth = useSelector(state => state.auth)

  useEffect(() => {
    dispatch(getCurrentWorkOrder(params.id));
    dispatch(getQuotesList(params.id));
    dispatch(getPaymentsList({ workOrder: params.id }))
    return () => {
      dispatch(resetWorkOrdersState());
      dispatch(resetQuotesState());
      dispatch(resetPaymentsState());
    }
  }, []);
  
  useEffect(() => setWorkOrder({ ...workOrder, ...current }), [current]);

  const handleChange = e => {
    setWorkOrder({ ...workOrder, [e.target.name]: e.target.value });
  }

  const handleSubmit = e => {
    e.preventDefault();

    const { status } = workOrder;
    dispatch(updateCurrentWorkOrder(params.id, { status }));
  }

  if(!auth) return 

  return (
    <>
      <Typography variant="h5" sx={styles.title} fontWeight="bold">Órden {workOrder._id}</Typography>
      <Grid container>
        <Grid item sm={12} md={6} sx={{ paddingX: '1rem' }}>
          <WorkOrderDetailsCard {...workOrder} onChange={handleChange} onSubmit={handleSubmit} org={auth.org}/>
          {auth.org === "milusos" &&  <WorkOrderDetailsQuotes id={params.id} /> }
          {auth.org === "milusos" && <WorkOrderDetailsPayments /> }
        </Grid>
        <Grid item xs={12} md={6} sx={{ paddingX: '1rem' }}>
          {auth.org === "milusos" && <WorkOrderDetailsActions id={params.id} status={workOrder.status}/>}          
          {auth.org === "milusos" &&  <WorkOrderDetailsReassign id={params.id} /> }
          <WorkOrderDetailsParticipant title="Solicitado para (socio)" participant="socio" {...workOrder.requestedTo} org={auth.org}/>
          <WorkOrderDetailsParticipant title="Solicitado por (cliente)" participant="cliente" {...workOrder.requestedBy} org={auth.org}/>
          {auth.org === "milusos" &&  <WorkOrderDetailsChat id={params.id} />}
        </Grid>
      </Grid>
    </>
  );
}

export default WorkOrderDetails;
