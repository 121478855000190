import { getWorkOrdersSource } from '../../api/marketingApi';
import { SET_WORK_ORDERS_SOURCE } from '../types/marketingTypes';
import { startLoading, endLoading } from './loadingActionCreators';
import { handleError } from './errorsActionCreators';

export const setWorkOrdersSource = workOrdersSource => ({
  type: SET_WORK_ORDERS_SOURCE,
  payload: { workOrdersSource }
});

export const getMarketingWorkOrdersSource = query => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getWorkOrdersSource(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setWorkOrdersSource(data.data)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
};
