import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';

import { getCurrentClient, resetClientsState, updateCurrentClient } from '../../../../actions/creators/clientsActionCreators';
import ClientDetailsCard from './ClientDetailsCard';
import ClientDetailsWorkOrders from './ClientDetailsWorkOrders';
import ClientDetailsResetPassword from './ClientDetailsResetPassword';

const styles = {
  title: {
    marginBottom: '1rem'
  }
}

const ClientDetails = () => {
  const [client, setClient] = useState({
    _id: '',
    name: '',
    email: '',
    phoneNumber: '',
    status: ''
  });

  const params = useParams();
  const dispatch = useDispatch();
  const current = useSelector(state => state.clients.current);
  const auth = useSelector(state => state.auth)

  useEffect(() => {
    dispatch(getCurrentClient(params.id));
    return () => dispatch(resetClientsState());
  }, []);
  
  useEffect(() => setClient({ ...client, ...current }), [current]);

  const handleChange = e => {
    setClient({ ...client, [e.target.name]: e.target.value });
  }

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(updateCurrentClient(params.id, client));
  }

  if(!auth) return

  return (
    <>
      <Typography variant="h5" sx={styles.title} fontWeight="bold">Cliente {client._id}</Typography>
      <Grid container>
        <Grid item sm={12} md={5} lg={4} sx={{ paddingX: '1rem' }}>
          <ClientDetailsCard {...client} onChange={handleChange} onSubmit={handleSubmit} org={auth.org}/>
          {auth.org === "milusos" && 
            <ClientDetailsResetPassword id={params.id} />
          }          
        </Grid>
        <Grid item sm={12} md={7} lg={8} sx={{ paddingX: '1rem' }}>
          <ClientDetailsWorkOrders id={params.id} />
        </Grid>
      </Grid>
    </>
  );
}

export default ClientDetails;
