import { startLoading, endLoading } from './loadingActionCreators';
import { handleError } from './errorsActionCreators';
import { getDownloadFileUrl } from '../../api/utilsApi';


export const getDownloadUrl = (key, fileName) => (dispatch, getState) => {  const { token } = getState().auth;

  dispatch(startLoading());

  getDownloadFileUrl(token, key)
  .then(response => response.signedUrl  ? response : Promise.reject(response))
  .then(data => fetch(data.signedUrl,{mode:"cors"})
.then(res => res.blob())
.then(data => {
  var a = document.createElement("a");
  a.href = window.URL.createObjectURL(data);
  a.download = fileName;
  a.click();
}))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
};
