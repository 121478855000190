import { Card, CardHeader, CardContent, Box, Typography } from '@mui/material';

const { REACT_APP_HOST_URL } = process.env;

const styles = {
  card: {
    marginBottom: '1.5rem'
  }
}

const AccidentDetailsFiles = props => {
  return (
    <Card sx={styles.card}>
      <CardHeader title='Documentos' />
      <CardContent>
        <Typography variant="body1">Documentos del siniestro {props.identifier}</Typography>
        {props.files.map(file =>
          <Box my={3}>
            <a href={`${REACT_APP_HOST_URL}${file}`} target="_blank" rel="noreferrer">{file}</a>
          </Box>  
        )}
      </CardContent>
    </Card>
  );
}

export default AccidentDetailsFiles;
