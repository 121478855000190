/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {  useDispatch } from 'react-redux';
import {useNavigate, useSearchParams } from 'react-router-dom';

const useFilteredPagination = (defaultFilter, resetAction, fetchAction) => {
  const [searchParams] = useSearchParams();
  const pageFromUrl = searchParams.get('page') && searchParams.get('page') > 0 
    ? parseInt(searchParams.get('page'), 10) 
    : 1;

  const [currentFilter, setCurrentFilter] = useState({ ...defaultFilter, page: pageFromUrl });
  const [pendingFilter, setPendingFilter] = useState(currentFilter);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Listen for changes in the page number from the URL
  useEffect(() => {
    setCurrentFilter(prev => ({ ...prev, page: pageFromUrl }));
  }, [pageFromUrl]);

  useEffect(() => {
      return () => dispatch(resetAction());
  }, [dispatch]);

  const handleChange = e => setPendingFilter(prev => ({ ...prev, [e.target.name]: e.target.value }));

  const onSubmit = e => {
    e.preventDefault();
    setCurrentFilter(pendingFilter);
    navigate(`${window.location.pathname}?page=1`);
  };

  const onClear = () => {
    const resetFilters = { ...defaultFilter, page: 1 };
    setPendingFilter(resetFilters);
    setCurrentFilter(resetFilters);
  };

  const nextPage = () => {
    const newPage = currentFilter.page + 1;
    setCurrentFilter(prev => ({ ...prev, page: newPage }));
    navigate(`${window.location.pathname}?page=${newPage}`);
  };

  const prevPage = () => {
    const newPage = currentFilter.page - 1;
    setCurrentFilter(prev => ({ ...prev, page: newPage }));
    navigate(`${window.location.pathname}?page=${newPage}`);
  };

  useEffect(() => {
      dispatch(fetchAction(currentFilter));
  }, [currentFilter, dispatch, fetchAction]);

  return {
    filter: pendingFilter,
    handleChange, 
    onSubmit, 
    onClear, 
    nextPage, 
    prevPage, 
    page: currentFilter.page
  };
}

const useFilterHandler = (defaultFilter, resetAction, fetchAction) => {
  const [pendingFilter, setPendingFilter] = useState(defaultFilter);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = e => 
    setPendingFilter(prev => ({ ...prev, [e.target.name]: e.target.value }));

  const onSubmit = e => {
    e.preventDefault();
    // Instead of setting the currentFilter like in useFilteredPagination, 
    // we directly dispatch the fetch action with the pendingFilter
    dispatch(fetchAction(pendingFilter));
    // If you still want to update the URL after filtering, you can keep this line
    navigate(`${window.location.pathname}`);
  };

  const onClear = () => {
    setPendingFilter(defaultFilter);
    dispatch(resetAction());
  };

  return {
    filter: pendingFilter,
    handleChange,
    onSubmit,
    onClear
  };
}



export {useFilteredPagination,useFilterHandler}