import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardContent, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { getUserPath, getUserType } from '../../../../utils/usersUtills';

const styles = {
  card: {
    marginBottom: '1.5rem'
  }
}

const IssueDetailsParticipant = () => {
  const [issue, setIssue] = useState({
    _id: '',
    userRef: { },
    userType: ''
  });

  const current = useSelector(state => state.issues.current);
    
  useEffect(() => setIssue({ ...issue, ...current }), [current]);

  return (
    <Card sx={styles.card}>
      <CardHeader title="Enviada por" />
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>
                <Link to={getUserPath(issue.userType) + issue.userRef?._id}>
                  {issue.userRef?._id ? `${issue.userRef?.name} ${issue.userRef?.lastName}` : 'Sin asignar'}
                </Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tipo de usuario</TableCell>
              <TableCell>{getUserType(issue.userType)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Correo</TableCell>
              <TableCell>{issue.userRef?.email ?? 'Sin asignar'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Teléfono</TableCell>
              <TableCell>{issue.userRef?.phoneNumber ?? 'Sin asignar'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

export default IssueDetailsParticipant;
