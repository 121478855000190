import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardHeader, CardContent, Typography } from '@mui/material';

const { REACT_APP_HOST_URL, REACT_APP_STRIPE_BASE_URL } = process.env;

const styles = {
  card: {
    marginBottom: '1.5rem'
  },
  audiencesContainer: {
    margin: '0.5rem'
  },
}

const PolicyDetailsForm = () => {
  const [policy, setPolicy] = useState({
    _id: '',
    series: '',
    validUntil: '',
    createdAt: '',
    docUrl: ''
  });
  
  const current = useSelector(state => state.policies.current);

  useEffect(() => setPolicy({ ...policy, ...current }), [current]);

  const getStripeUrl = paymentIntent => {
    if(paymentIntent.match(/^in_/)) {
      return `${REACT_APP_STRIPE_BASE_URL}/invoices/${paymentIntent}`;
    }

    return `${REACT_APP_STRIPE_BASE_URL}/payments/${paymentIntent}`; 
  }

  return (
    <Card sx={styles.card}>
      <CardHeader title={'Póliza: ' + policy.series} />
      <CardContent>
        <Typography variant="body1">Número de serie: {policy.series}</Typography>
        <Typography variant="body1">Validez: {new Date(policy.createdAt)?.toLocaleString()}</Typography>
        <Typography variant="body1">Regalo: {policy.temporal ? 'Sí' : 'No'}</Typography>
        <Typography variant="body1">Método de pago: {policy.payment?.method ?? 'Externo'}</Typography>
        <Typography variant="body1">Promoción: {policy.payment?.promo ?? 'No'}</Typography>
        <Typography variant="body1">Documento: <a href={REACT_APP_HOST_URL + policy.docUrl} rel="noreferrer" target="_blank">Click aquí</a></Typography>
        <Typography variant="body1">
          Pago: {policy.payment?.paymentIntent ? <a href={getStripeUrl(policy.payment?.paymentIntent)} target="_blank" rel="noreferrer">{policy.payment?.paymentIntent}</a> : 'No'}
        </Typography>
        <Typography align="right" variant="body2">Creado: {new Date(policy.createdAt)?.toLocaleString()}</Typography>
      </CardContent>
    </Card>
  );
}

export default PolicyDetailsForm;
