import { getClient, getClients, createClient, updateClient, resetClientPassword } from '../../api/clientsApi';
import { RESET_CLIENTS_STATE, SET_CLIENTS_LIST, SET_CURRENT_CLIENT } from '../types/clientsTypes';
import { startLoading, endLoading } from './loadingActionCreators';
import { showAlert } from './alertsActionCreators';
import { handleError } from './errorsActionCreators';

export const setClientsList = clients => ({
  type: SET_CLIENTS_LIST,
  payload: { clients }
});

export const setCurrentClient = client => ({
  type: SET_CURRENT_CLIENT,
  payload: { client }
});

export const resetClientsState = () => ({
  type: RESET_CLIENTS_STATE,
  payload: { }
});

export const getClientsList = query => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getClients(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setClientsList(data.clients)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
};

export const getCurrentClient = id => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getClient(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentClient(data.client)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const createNewClient = (client, callback) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  createClient(token, client)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(data => callback(data.client))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const updateCurrentClient = (id, client) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  updateClient(token, id, client)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentClient(data.client)))
  .then(() => dispatch(showAlert('Cliente actualizado', 'success')))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const resetCurrentClientPassword = (id, password) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  resetClientPassword(token, id, password)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(() => dispatch(showAlert('Contraseña actualizada', 'success')))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}
