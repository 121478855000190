import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Button, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import ProductBuyerForm from '../../../../components/products/ProductBuyerForm';
import ProductBeneficiaryForm from '../../../../components/products/ProductBeneficiaryForm';

import { createNewPolicy } from '../../../../actions/creators/policiesActionCreators';

const styles = {
  card: {
    marginBottom: '1rem'
  }
}

const ProductFormPER = () => {
  const [buyer, setBuyer] = useState({
    email: '',
    name: '',
    lastName: '',
    phoneNumber: '',
    birthdate: '1990-01-01',
    address: 'CDMX'
  });

  const [beneficiaries, setBeneficiaries] = useState([{
    name: '',
    relationship: '',
    percentage: 100
  }]);

  const dispatch = useDispatch();

  const params = useParams();
  const navigate = useNavigate();

  const handleBuyerChange = e => setBuyer({ ...buyer, [e.target.name]: e.target.value });

  const handleBeneficiaryChange = (e, index) => {
    const newBeneficiaries = [...beneficiaries];
    newBeneficiaries[index][e.target.name] = e.target.value;
    setBeneficiaries(newBeneficiaries);
  }
  
  const handleSubmit = e => {
    e.preventDefault();
    dispatch(createNewPolicy({
      product: params.id,
      buyer,
      beneficiaries
    }, handleRedirect));
  }

  const handleRedirect = policy => navigate('/dashboard/polizas/' + policy);

  return (
    <>
      <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '1rem' }}>Nueva póliza Seguro AP Personal</Typography>
      <Grid container>
        <Grid item xs={12}>
          <Card sx={styles.card}>
            <CardHeader title="Datos del titular" />
            <CardContent>
              <ProductBuyerForm {...buyer} onChange={handleBuyerChange} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={styles.card}>
            <CardHeader title="Datos del beneficiario" />
            <CardContent>
              {beneficiaries.map((beneficiary, index) => 
                <Box key={index}> 
                  <Typography variant="h6" fontWeight="bold">Beneficiario {index + 1}</Typography>
                  <ProductBeneficiaryForm index={index} {...beneficiary} onChange={handleBeneficiaryChange} />
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button onClick={handleSubmit}>Guardar</Button>
        </Grid>
      </Grid>
    </>
  );
}

export default ProductFormPER;
