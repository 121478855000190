import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardHeader, CardContent, CardMedia, Table, TableBody, TableRow, TableCell, Avatar, Divider, Button } from '@mui/material';
import ProviderDetailsImageCard from './ProviderDetailsImageCard';

const { REACT_APP_HOST_URL } = process.env;

const styles = {
  card: {
    marginBottom: '1.5rem'
  },
  media: {
    height: '25vh',
    cursor: 'pointer'
  },
  avatar: {
    width: '80px',
    height: '80px',
    cursor: 'pointer'
  },
  header: {
    color: '#000',
    backgroundColor: '#FFF',
    textAlign: 'start'
  },
  content: {
    paddingTop: '0'
  },
  bottonWrapper: {
    textAlign: 'center',
    marginTop: '1rem'
  }
}

const ProviderDetailsCard = () => {
  const [provider, setProvider] = useState({
    _id: '',
    name: '',
    lastName: '',
    email: '',
    averageScores: { }
  });

  const [openPhoto, setOpenPhoto] = useState(false);
  const [openWorkplace, setOpenWorkPlace] = useState(false);
  const [timestamp, setTimestamp] = useState(new Date().toISOString());

  const current = useSelector(state => state.providers.current);

  const navigate = useNavigate();
    
  useEffect(() => {
    setProvider({ ...provider, ...current });
    setTimestamp(new Date().toISOString());
  }, [current]);

  const navigateToActivation = () => navigate('afiliacion');

  const handleOpenPhoto = () => setOpenPhoto(true);
  const handleOpenWorkPlace = () => setOpenWorkPlace(true);

  const handleClosePhoto = () => setOpenPhoto(false);
  const handleCloseWorkPlace = () => setOpenWorkPlace(false);

  const photoUrl = (provider.photoUrl ? REACT_APP_HOST_URL + provider.photoUrl : '/img/blank-profile.png') + '?timestamp=' + timestamp;
  const workplacePhotoUrl = (provider.workplacePhotoUrl ? REACT_APP_HOST_URL + provider.workplacePhotoUrl : '/img/missing.png') + '?timestamp=' + timestamp;

  return (
    <>
      <Card sx={styles.card}>
        <CardMedia component="img" image={workplacePhotoUrl} sx={styles.media} onClick={handleOpenWorkPlace} />
        <CardHeader sx={styles.header}
          avatar={<Avatar sx={styles.avatar} alt="foto" src={photoUrl} onClick={handleOpenPhoto} />}
          title={`${provider.name} ${provider.lastName}`}
          subheader={provider.email}
        />
        <CardContent sx={styles.content}>
          <Divider />
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Socio desde</TableCell>
                <TableCell>{new Date(provider.createdAt).toLocaleString()}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Afiliador</TableCell>
                <TableCell>{provider.affiliator ? `${provider.affiliator.name} ${provider.affiliator.lastName}` : 'Sin asignar'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Precio</TableCell>
                <TableCell>{provider.averageScores?.price}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Calidad</TableCell>
                <TableCell>{provider.averageScores?.quality}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Limpieza</TableCell>
                <TableCell>{provider.averageScores?.cleaning}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Box sx={styles.bottonWrapper}>
            <Button variant="text" onClick={navigateToActivation}>Afiliación</Button>
          </Box>
        </CardContent>
      </Card>
      <ProviderDetailsImageCard title="Foto del socio" type="photo" id={provider?._id} src={photoUrl} open={openPhoto} onClose={handleClosePhoto} />
      <ProviderDetailsImageCard title="Lugar de trabajo" type="workplace" id={provider?._id} src={workplacePhotoUrl} open={openWorkplace} onClose={handleCloseWorkPlace} />
    </>
  );
}

export default ProviderDetailsCard;
