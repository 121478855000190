import { Typography } from '@mui/material';

import MarketingCharts from './MarketingCharts';

const Marketing = () => {
  return (
    <>
      <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '1rem' }}>Marketing</Typography>
      <MarketingCharts />
    </>
  );
}

export default Marketing;
