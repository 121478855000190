import { getProduct, getProducts, createProduct, updateProduct, uploadProductTemplate } from '../../api/productsApi';
import { SET_PRODUCTS_LIST, SET_CURRENT_PRODUCT, RESET_PRODUCTS_STATE } from '../types/productsTypes';
import { startLoading, endLoading } from './loadingActionCreators';
import { showAlert } from './alertsActionCreators';
import { handleError } from './errorsActionCreators';

export const setProductsList = products => ({
  type: SET_PRODUCTS_LIST,
  payload: { products }
});

export const setCurrentProduct = product => ({
  type: SET_CURRENT_PRODUCT,
  payload: { product }
});

export const resetProductsState = () => ({
  type: RESET_PRODUCTS_STATE,
  payload: { }
});

export const getProductsList = query => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getProducts(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setProductsList(data.products)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
};

export const getCurrentProduct = id => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getProduct(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentProduct(data.product)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const createNewProduct = (product, callback) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  createProduct(token, product)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(data => callback(data.product))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const updateCurrentProduct = (id, product) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  updateProduct(token, id, product)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentProduct(data.product)))
  .then(() => dispatch(showAlert('Producto actualizado', 'success')))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const uploadCurrentProductTemplate = (id, doc) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  uploadProductTemplate(token, id, doc)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentProduct(data.product)))
  .then(() => dispatch(showAlert('Plantilla actualizada', 'success')))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}
