import { Box, Card, CardHeader, CardContent, Typography, TextField, Button } from '@mui/material';

const styles = {
  card: {
    marginBottom: '1.5rem'
  }
}

const AccidentDetailsCard = props => {
  return (
    <Card sx={styles.card}>
      <CardHeader title={'Siniestro ' + props.identifier} />
      <CardContent>
        <TextField label="Correo" name="email" value={props.email} onChange={props.onChange} />
        <TextField label="Teléfono" name="phoneNumber" value={props.phoneNumber} onChange={props.onChange} />
        <TextField label="Póliza" name="policy" value={props.policy} onChange={props.onChange} />
        <Box sx={{ textAlign: 'center' }}>
          <Button variant="contained" onClick={props.onSubmit}>Guardar</Button>
        </Box>
        <Typography align="right" variant="body2">Creado: {new Date(props.createdAt)?.toLocaleString()}</Typography>
      </CardContent>
    </Card>
  );
}

export default AccidentDetailsCard;
