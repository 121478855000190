import { Box, Card, CardHeader, CardContent, Typography, TextField, Button } from '@mui/material';

const styles = {
  card: {
    marginBottom: '1.5rem'
  }
}

const LeadDetailsCard = props => {
  return (
    <Card sx={styles.card}>
      <CardHeader title={'Info de ' + props.email} />
      <CardContent>
        <Typography variant="body2" sx={{ marginBottom: '1rem' }}><b>Fuente: </b><span dangerouslySetInnerHTML={{ __html: props.source }}></span></Typography>
        <TextField label="Correo" name="email" value={props.email} onChange={props.onChange} disabled />
        <TextField label="Nombre" name="name" value={props.name} onChange={props.onChange} />
        <TextField label="Apellido" name="lastName" value={props.lastName} onChange={props.onChange} />
        <TextField label="Teléfono" name="phoneNumber" value={props.phoneNumber} onChange={props.onChange} />
        <Box sx={{ textAlign: 'center' }}>
          <Button variant="contained" onClick={props.onSubmit}>Guardar</Button>
        </Box>
        <Typography align="right" variant="body2">Leade desde: {new Date(props.createdAt)?.toLocaleString()}</Typography>
      </CardContent>
    </Card>
  );
}

export default LeadDetailsCard;
