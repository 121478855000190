import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Box, Card, CardContent, CardHeader, TextField, IconButton } from '@mui/material';
import { Send } from '@mui/icons-material';
import WorkOrderDetailsChatMessage from './WorkOrderDetailsChatMessage';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';

import { listenChat, sendMessage, deleteMessage } from '../../../../services/firebaseService';

const styles = {
  messagesContainer: {
    maxHeight: '500px',
    overflowY: 'scroll',
    px: '0.5rem'
  },
  newMessageContainer: {
    display: 'flex',
    marginTop: '0.5rem'
  },
  sendButtonWrapper: {
    m: 'auto',
    paddingX: '0.5rem'
  }
}

const WorkOrderDetailsChat = props => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [deleted, setDeleted] = useState({ id: '', message: '' });
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const client = useSelector(state => state.workOrders.current?.requestedBy?._id);
  const provider = useSelector(state => state.workOrders.current?.requestedTo?._id);

  const chatContainer = useRef();

  useEffect(() => {
    const unsubscribe = listenChat(props.id, handleNewMessage);
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const el = chatContainer.current;
    setTimeout(() => el.scrollTop = el.scrollHeight - el.clientHeight, 0);
  }, [messages, chatContainer]);

  const handleNewMessage = snapshot => {
    const messages = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    setMessages(messages);
  }

  const handleChange = e => {
    setNewMessage(e.target.value)
  }

  const handleSend = e => {
    e.preventDefault();

    if(newMessage.length < 1) {
      return;
    }

    sendMessage(props.id, {
      from: provider,
      to: client,
      type: 'text',
      content: newMessage
    });

    setNewMessage('');
  }

  const handleSelectToDelete = (id, message) => {
    setDeleted({ id, message });
    setDeleteConfirmation(true);
  }

  const handleCloseConfirmation = () => {
    setDeleteConfirmation(false);
  }

  const handleDeleteConfirmation = e => {
    e.preventDefault();
    deleteMessage(props.id, deleted.id);
    handleCloseConfirmation();
  }

  return (
    <>
      <Card>
        <CardHeader title="Chat" />
        <CardContent>
          <Box sx={styles.messagesContainer} ref={chatContainer}>
            {client ? messages.map(message => <WorkOrderDetailsChatMessage key={message.id} client={client} {...message} onDelete={handleSelectToDelete} />) : null}
          </Box>
          <Box sx={styles.newMessageContainer} component="form" onSubmit={handleSend}>
            <TextField label="Mensaje nuevo" value={newMessage} onChange={handleChange} />
            <Box sx={styles.sendButtonWrapper}>
              <IconButton type="submit"><Send /></IconButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <ConfirmationDialog
        open={deleteConfirmation}
        title="Borrar mensaje"
        message={`¿Deseas borrar el mensaje "${deleted.message}"?`}
        onClose={handleCloseConfirmation}
        onConfirm={handleDeleteConfirmation}
      />
    </>
  );
}

export default WorkOrderDetailsChat;
