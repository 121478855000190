import { SHOW_ERROR, CLOSE_ERROR } from '../types/errorsTypes';

export const showError = error => ({
  type: SHOW_ERROR,
  payload: { error }
});

export const closeError = () => ({
  type: CLOSE_ERROR,
  payload: { }
});

export const handleError = err => async dispatch => {
  try {
    console.error(err);

    if(err.json) {
      const error = await err.json();

      console.error(error);

      const errorMessages = [
        error.error?.message ?? 'No especificado',
        ...(error.error?.errors?.map?.(error => error.msg) ?? [])
      ];

      return dispatch(showError(errorMessages));
    }
  
    throw err;
  } catch(err) {
    dispatch(showError(['Error desconocido']));
  }
}
