import { TableRow, TableCell } from '@mui/material';
import { Link } from 'react-router-dom';

const WorkOrdersListItem = props => {
  const { requestedBy, requestedTo } = props;
  const client = requestedBy?.name
  const provider = `${requestedTo?.name} ${requestedTo?.lastName}`;

  return (
    <TableRow>
      <TableCell><Link to={props._id}>{props._id}</Link></TableCell>
      <TableCell>{props.title}</TableCell>
      <TableCell>{props?.organization?.name}</TableCell>
      <TableCell>{requestedBy ? <Link to={'/dashboard/clientes/' + props.requestedBy._id}>{client}</Link> : 'Sin cliente'}</TableCell>
      <TableCell>{requestedTo ? <Link to={'/dashboard/socios/' + props.requestedTo._id}>{provider}</Link> : 'Sin socio'}</TableCell>
      <TableCell>{props.serviceType?.name ?? props.other}</TableCell>
      <TableCell>{props.status}</TableCell>
      <TableCell>{new Date(props.createdAt)?.toLocaleString()}</TableCell>
    </TableRow>
  );
}

export default WorkOrdersListItem;
