import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { Typography } from '@mui/material';

import ProductFormPER from './ProductFormPER';
import ProductFormFAM from './ProductFormFAM';

const ProductForms = () => {
  const location = useLocation();

  const search = parse(location.search);

  return (
    <>
      {search.form === 'PER' ? <ProductFormPER /> : null}
      {search.form === 'FAM' ? <ProductFormFAM /> : null}
      {!search.form ? <Typography fontWeight="bold">No se ha seleccionado una forma válida</Typography> : null}
    </>
  );
}

export default ProductForms;
