const { REACT_APP_API_BASE_URL } = process.env;

export const getServices = (token, query) => new Promise((resolve, reject) => {
  const queryParams = new URLSearchParams({
    ...query,
  }).toString();

  const request = new Request(REACT_APP_API_BASE_URL + '/services?' + queryParams, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getService = (token, id) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/services/' + id, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const createService = (token, service) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/services', {
    method: 'POST',
    body: JSON.stringify({ service }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const updateService = (token, id, service) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/services/' + id, {
    method: 'PUT',
    body: JSON.stringify({ service }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});
