import { Typography } from '@mui/material';
import { useFilterHandler } from '../../../utils/tablesUtils';

import ReportsFilter from './ReportFilter';
import { getProvidersList, resetProvidersState } from '../../../actions/creators/providersActionCreators';
import { getProvidersReport } from '../../../actions/creators/reportsActionCreators';

const defaultFilter = {
  state: '',
  city:'',
  postalCode:'',
  reportType:'Socios',
};

const ReportsPage = () => {
    const {
        filter, 
        handleChange, 
        onSubmit, 
        onClear, 
      } = useFilterHandler(defaultFilter, resetProvidersState, getProvidersReport);
    
  return (
    <>
        <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '1rem' }}>Reportes</Typography>
        <ReportsFilter {...filter} onChange={handleChange} onSubmit={onSubmit} onClear={onClear}   />
    </>
  );
}

export default ReportsPage;