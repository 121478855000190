import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';

import PolicyDetailsForm from './PolicyDetailsForm';
import PolicyDetailsPreview from './PolicyDetailsPreview';

import { getCurrentPolicy, resetPoliciesState } from '../../../../actions/creators/policiesActionCreators';
import PolicyDetailsBuyerForm from './PolicyDetailsBuyerForm';

const styles = {
  title: {
    marginBottom: '1rem'
  }
}

const PolicyDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentPolicy(params.id));
    return () => dispatch(resetPoliciesState());
  }, []);
  
  return (
    <>
      <Typography variant="h5" sx={styles.title} fontWeight="bold">Póliza {params.id}</Typography>
      <Grid container>
        <Grid item sm={12} md={7} sx={{ paddingX: '1rem' }}>
          <PolicyDetailsForm />
          <PolicyDetailsBuyerForm />
        </Grid>
        <Grid item sm={12} md={5} sx={{ paddingX: '1rem' }}>
          <PolicyDetailsPreview />
        </Grid>
      </Grid>
    </>
  );
}

export default PolicyDetails;
