import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardContent, CardHeader, List } from '@mui/material';
import AppPagination from '../../../../components/AppPagination';
import ClientDetailsWorkOrdersItem from './ClientDetailsWorkOrdersItem';

import { getWorkOrdersList, resetWorkOrdersState } from '../../../../actions/creators/workOrdersActionCreators';

const ClientDetailsWorkOrders = props => {
  const dispatch = useDispatch();
  const workOrders = useSelector(state => state.workOrders.list);

  const [pagination, setPagination] = useState({ page: 1 });

  useEffect(() => {
    setPagination({ page: 1 });
    return () => dispatch(resetWorkOrdersState())
  }, []);

  useEffect(() => dispatch(getWorkOrdersList({ page: pagination.page, requestedBy: props.id, results: 5 })), [pagination]);

  const nextPage = () => setPagination({ page: pagination.page + 1 });
  const prevPage = () => setPagination({ page: Math.max(pagination.page - 1, 1) });

  return (
    <>
      <Card>
        <CardHeader title="Órdenes de trabajo" />
        <CardContent>
          <List>
            {workOrders.map(workOrder => <ClientDetailsWorkOrdersItem key={workOrder._id} {...workOrder} />)}
          </List>
        </CardContent>
      </Card>
      <AppPagination page={pagination.page} prev={prevPage} next={nextPage} />
    </>
  );
}

export default ClientDetailsWorkOrders;
