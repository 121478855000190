import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import ProductsListTable from './ProductsListTable';
import ProductsFilter from './ProductsFilter';
import ProductsCreateDialog from './ProductsCreateDialog';
import AppPagination from '../../../components/AppPagination';

import { getProductsList, resetProductsState } from '../../../actions/creators/productsActionCreators';
import { useFilteredPagination } from '../../../utils/tablesUtils';

const defaultFilter = {
  name: '',
  description: '',
  prefix: '',
  enabled: true,
};

const Products = () => {
  const {
    filter, 
    handleChange, 
    onSubmit, 
    onClear, 
    nextPage, 
    prevPage, 
    page
  } = useFilteredPagination(defaultFilter, resetProductsState, getProductsList);
  const dispatch = useDispatch();
  const products = useSelector(state => state.products.list);
  
  const [showCreate, setShowCreate] = useState(false);

  useEffect(() => {
    return () => dispatch(resetProductsState());
  }, [dispatch]);

  const openCreate = () => setShowCreate(true);
  const closeCreate = () => setShowCreate(false);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '1rem' }}>Productos</Typography>
        <Button sx={{ marginTop: '0' }} color="primary" onClick={openCreate}>Nuevo</Button>
      </Box>
      <ProductsFilter {...filter} onChange={handleChange} onSubmit={onSubmit} onClear={onClear} />
      <ProductsListTable products={products} />
      <AppPagination page={page} prev={prevPage} next={nextPage} hasNextPage={products.length !== 0} />
      <ProductsCreateDialog open={showCreate} onClose={closeCreate} />
    </>
  );
}

export default Products;
