import { SET_CURRENT_ACTIVATION, RESET_ACTIVATIONS_STATE } from '../actions/types/activationsTypes';

const defaultState = {
  current: { }
};

const activationsReducer = (state = defaultState, { type, payload }) => {
  const newState = { ...state };

  switch(type) {
    case SET_CURRENT_ACTIVATION: newState.current = { ...payload.activation }; break;
    case RESET_ACTIVATIONS_STATE: newState.current = { }; break;

    default: break;
  }

  return newState;
}

export default activationsReducer;
