import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import { Alert, Box, Button, Card, CardContent, CardHeader, CircularProgress, Grid, ImageList, ImageListItem, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";

import { getUploadFileUrl, uploadFile } from '../../../api/utilsApi';
const { REACT_APP_API_BASE_URL } = process.env;

export const getIkeaQuote = (token,key) => new Promise((resolve, reject) => {

  const queryParams = new URLSearchParams({
    key
  }).toString();

  const request = new Request(REACT_APP_API_BASE_URL + '/utils/ikea-quote?' + queryParams, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response.json()))
  .catch(err => reject(err))
});

function toNormalForm(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[()]/g, "");
}


const styles = {
  card: {
    marginBottom: '1.5rem'
  },
  inputWrapper: {
    paddingX: '0.5rem'
  },
}

const Ikea = () => {
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [openAlertError, setOpenAlertError] = useState(false);
  const [quote, setQuote ]= useState({
    hours: 0,
    minutes: 0,
    minutesBuild: 0,
    objects: [],
    objectsCount: 0,
    imagesCount: 0
  })
  const [isUploadingFiles, setIsUploadingFiles ]= useState(false)
  const [file, setFile] = useState(null);
  const inputRef = useRef(null);
  const token = useSelector(state => state.auth.token);
  const [images, setImages] = useState([]);
  const [ikeaAssemblyQuote, setIkeaAssemblyQuote]= useState({
    days: 0,
    cost: 0,
    minutes: 0,
    daysHours: 0,
    hours: 0,
    productsCount: 0,
    products: []
  });

  const handleChange = e => {
    console.log(e.target.files[0])
    setFile(e.target.files[0]);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setIsUploadingFiles(true)

    let fileUrl;
    for (const fileUpload of [file]) {
      if(fileUpload){
        const {url,signedUrl} =await getUploadFileUrl(token,`ikea`,toNormalForm(fileUpload?.name?.trim()?.replaceAll(" ","-").toLowerCase()))
        await uploadFile(signedUrl, fileUpload)
        fileUrl=url
        setImages([...images,URL.createObjectURL(fileUpload)])
      }
    }

    console.log(fileUrl)
    try {
      const result = await getIkeaQuote(token,fileUrl)
      console.log(result)

      const {
        averages
      } = result?.quote;


      if(Object.values(averages).some(value => value < 80)){
        setOpenAlertError(true)
      }else{
        const HOUR_COST = 300;
        setIkeaAssemblyQuote(current => {
            const totalMinutes = current.minutes + (result?.quote?.minutesBuild || 0);
            const totalDays = Math.floor(totalMinutes / 480); // Full days
            const remainderHours = (totalMinutes % 480) / 60; // Hours of the other day, if any

            return {
                ...current,
                productsCount: current.productsCount + (result?.quote.objectsCount || 0),
                minutes: totalMinutes,
                products: [...current.products, ...(result?.quote?.objects || [])],
                days: totalDays,
                daysHours: Math.ceil(remainderHours),
                hours: Math.ceil(totalMinutes / 60),
                cost: Math.ceil(totalMinutes / 60) * HOUR_COST < 600 ? 600 : Math.ceil(totalMinutes / 60) * HOUR_COST,
            };
        });

        setOpenAlertSuccess(true)
      }   
    } catch (error) {
      console.log(error)
    }finally{
      setIsUploadingFiles(false)
    }
  }

  const reset = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setIkeaAssemblyQuote({
    days: 0,
    cost: 0,
    minutes: 0,
    hours: 0,
    productsCount: 0,
    products: []
  });
  };

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlertSuccess(false);
    setOpenAlertError(false);
  };

  return (
    <>
    <Card sx={styles.card}>
      <CardHeader title="Ikea Demo" />
      <CardContent>
        <Grid container> 
          <Grid container style={{position:"relative"}}>
            <Grid item xs={12} md ={4} sx={styles.inputWrapper}>
              <Typography>Imágen</Typography>
              <TextField name="file" inputRef={inputRef} onChange={handleChange} type="file" />
            </Grid>

            {/* <ImageList sx={{ width: "100%", height: 450 }} cols={6} rowHeight={164}>
              {images.map((item) => (
                <ImageListItem key={item}>
                  <img
                    srcSet={`${item}`}
                    src={`${item}`}
                    alt={item.title}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList> */}

            {isUploadingFiles && <Box sx={{ display: 'flex', position: "absolute", top: 0, left: 0, width:"100%", height:"100%", justifyContent:"center", alignItems:"center", backgroundColor:"rgba(256,256,256,0.5)" }}>
              <CircularProgress />
            </Box>}
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button color="primary" onClick={handleSubmit}>Analizar</Button>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        <Grid container> 
          <Grid container style={{position:"relative"}}>
            <Grid item xs={12} md ={1} sx={styles.inputWrapper}>
              <Typography>Skus: {ikeaAssemblyQuote?.productsCount || "---"}</Typography>
            </Grid>
            <Grid item xs={12} md ={1} sx={styles.inputWrapper}>
              <Typography>{ikeaAssemblyQuote?.hours ? ikeaAssemblyQuote?.hours + " Horas Total" : "Horas Total"}</Typography>
            </Grid>
            <Grid item xs={12} md ={1} sx={styles.inputWrapper}>
              <Typography>MinutosTotal: {ikeaAssemblyQuote?.minutes || "---"}</Typography>
            </Grid>
            {/* <Grid item xs={12} md ={2} sx={styles.inputWrapper}>
              <Typography>Precisión skus: {quote?.averages?.skusAverageConfidence ? quote?.averages?.skusAverageConfidence.toFixed(2) + "%" : " ---%"}</Typography> 
              <Typography>Precisión piezas: {quote?.averages?.unitsAverageConfidence ? quote?.averages?.unitsAverageConfidence.toFixed(2) + "%" : " ---%"}</Typography>
            </Grid>
            <Grid item xs={12} md ={2} sx={styles.inputWrapper}>
              <Typography>mPrecisiónSkus: {quote?.averages?.minConfidenceSku ? quote?.averages?.minConfidenceSku.toFixed(2) + "%" : " ---%"}</Typography> 
              <Typography>mPrecisiónPiezas: {quote?.averages?.minConfidenceUnit ? quote?.averages?.minConfidenceUnit.toFixed(2) + "%" : " ---%"}</Typography>
            </Grid> */}
            <Grid item xs={12} md ={1} sx={styles.inputWrapper}>
              <Typography>Días: {ikeaAssemblyQuote?.days || "---"}</Typography>
              <Typography>Horas: {ikeaAssemblyQuote?.daysHours || "---"}</Typography>              
            </Grid>
            <Grid item xs={12} md ={1} sx={styles.inputWrapper}>
              <Typography>Costo:{ikeaAssemblyQuote?.cost || "---"}</Typography>
            </Grid>
            {isUploadingFiles && <Box sx={{ display: 'flex', position: "absolute", top: 0, left: 0, width:"100%", height:"100%", justifyContent:"center", alignItems:"center", backgroundColor:"rgba(256,256,256,0.5)" }}>
              <CircularProgress />
            </Box>}
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button color="primary" onClick={reset}>Reset</Button>
          </Grid>
        </Grid>
      </CardContent>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Sku</TableCell>
            <TableCell align="left">Descripción</TableCell>
            <TableCell align="left">Piezas</TableCell>
            <TableCell align="left">Minutos</TableCell>
            <TableCell align="left">Minutos Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ikeaAssemblyQuote?.products && ikeaAssemblyQuote.products.map((row, i) => (
            <TableRow
              key={i}
            >
              <TableCell align="right">
                {row?.sku}
              </TableCell>
              <TableCell align="left" component="th" scope="row">{row?.description}</TableCell>
              <TableCell align="left">{row?.items}</TableCell>
              <TableCell align="left">{row?.minutes}</TableCell>
              <TableCell align="left">{row?.minutes * row?.items}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Card>
    <Snackbar open={openAlertSuccess} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Imágen procesada
        </Alert>
      </Snackbar>
      <Snackbar open={openAlertError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
          Imágen demasiado borrosa, intenta con una nueva imágen
        </Alert>
      </Snackbar>
    </>
  );
}

export default Ikea;
