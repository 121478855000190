import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import PoliciesListItem from './PoliciesListItem';

const PoliciesListTable = props => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead color="primary">
          <TableRow>
            <TableCell>Número de serie</TableCell>
            <TableCell>Correo titular</TableCell>
            <TableCell>Nombre titular</TableCell>
            <TableCell>Validez</TableCell>
            <TableCell>Regalo</TableCell>
            <TableCell>Promoción</TableCell>
            <TableCell>Método de pago</TableCell>
            <TableCell>Creada</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.policies?.map(policy => <PoliciesListItem key={policy._id} {...policy} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PoliciesListTable;
