import { loginAdmin, logoutAdmin } from '../../api/authApi';
import { CLEAR_TOKEN, SET_TOKEN } from '../types/authTypes';
import { startLoading, endLoading } from './loadingActionCreators';
import { handleError } from './errorsActionCreators';
import jwt_decode from "jwt-decode";

export const setToken = (token,org) => ({
  type: SET_TOKEN,
  payload: { token, org }
});

export const clearToken = () => ({
  type: CLEAR_TOKEN,
  payload: { }
});

export const login = (email, password, callback) => dispatch => {
  dispatch(startLoading());

  loginAdmin(email, password)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => {
    dispatch(setToken(data.token, jwt_decode(data.token)?.org));
    window.localStorage.setItem('token', data.token);
  })
  .then(() => callback())
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
};

export const logout = callback => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  logoutAdmin(token)
  .then(() => {
    dispatch(clearToken());
    window.localStorage.removeItem('token');
  })
  .then(() => callback())
  .finally(() => dispatch(endLoading()));
}
