import { SHOW_ALERT, CLOSE_ALERT } from '../types/alertsTypes';

export const showAlert = (message, type) => ({
  type: SHOW_ALERT,
  payload: { message, type }
});

export const closeAlert = () => ({
  type: CLOSE_ALERT,
  payload: { }
});
