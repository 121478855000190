import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';

import ProviderActivationCard from './ProviderActivationCard';
import ProviderActivationQr from './ProviderActivationQr';

import { getCurrentActivation, resetActivationsState } from '../../../../../actions/creators/activationsActionCreators';
import ProviderActivationUpdates from './ProviderActivationUpdates';

const styles = {
  title: {
    marginBottom: '1rem'
  },
  column: {
    paddingX: '1rem'
  }
}

const ProviderActivation = () => {
  const params = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentActivation(params.id));
    return () => dispatch(resetActivationsState())
  }, []);

  return (
    <>
      <Typography variant="h5" sx={styles.title} fontWeight="bold">Activación del socio {params.id}</Typography>
      <Grid container>
        <Grid item xs={12} md={6} sx={styles.column}>
          <ProviderActivationQr id={params.id} />
          <ProviderActivationUpdates />
        </Grid>
        <Grid item xs={12} md={6} sx={styles.column}>
          <ProviderActivationCard id={params.id} type="ine-front" title="INE Frontal" />
          <ProviderActivationCard id={params.id} type="ine-back" title="INE Reverso" />
          <ProviderActivationCard id={params.id} type="proof-of-address" title="Comprobante de domicilio" />
        </Grid>
      </Grid>
    </>
  );
}

export default ProviderActivation;
