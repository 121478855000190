import React from 'react';
import ReactDOM from 'react-dom';
import { applyMiddleware, createStore } from 'redux';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import jwt_decode from "jwt-decode";

import './index.css';
import App from './App';

import rootReducer from './reducers/rootReducer';

import { setToken } from './actions/creators/authActionCreators';

import { initializeFirebase } from './services/firebaseService';
import { client } from './apollo/client';

const store = createStore(rootReducer, applyMiddleware(thunk));

const token = window.localStorage.getItem('token');

if(token) {
  store.dispatch(setToken(token, jwt_decode(token)?.org));
}

initializeFirebase();

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
    <Provider store={store}>
      <App />
    </Provider>
     </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
