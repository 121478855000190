import { getPromo, getPromos, createPromo, updatePromo } from '../../api/promosApi';
import { SET_PROMOS_LIST, SET_CURRENT_PROMO, RESET_PROMOS_STATE } from '../types/promosTypes';
import { startLoading, endLoading } from './loadingActionCreators';
import { showAlert } from './alertsActionCreators';
import { handleError } from './errorsActionCreators';

export const setPromosList = promos => ({
  type: SET_PROMOS_LIST,
  payload: { promos }
});

export const setCurrentPromo = promo => ({
  type: SET_CURRENT_PROMO,
  payload: { promo }
});

export const resetPromosState = () => ({
  type: RESET_PROMOS_STATE,
  payload: { }
});

export const getPromosList = query => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getPromos(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setPromosList(data.promos)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
};

export const getCurrentPromo = id => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getPromo(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentPromo(data.promo)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const createNewPromo = (promo, callback) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  createPromo(token, promo)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(data => callback(data.promo))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const updateCurrentPromo = (id, promo) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  updatePromo(token, id, promo)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentPromo(data.promo)))
  .then(() => dispatch(showAlert('Promoción actualizada', 'success')))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}
