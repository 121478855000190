import { START_LOADING, END_LOADING } from '../actions/types/loadingTypes';

const defaultState = {
  isLoading: false
};

const loadingReducer = (state = defaultState, { type }) => {
  const newState = { ...state };

  switch(type) {
    case START_LOADING: newState.isLoading = true; break;
    case END_LOADING: newState.isLoading = false; break;
    default: break;
  }

  return newState;
}

export default loadingReducer;
