import { Box, Card, CardHeader, CardContent, Typography, TextField, Button, MenuItem } from '@mui/material';

const styles = {
  card: {
    marginBottom: '1.5rem'
  }
}

const ClientDetailsCard = props => {

  const onlyRead = props.org !== "milusos";

  return (
    <Card sx={styles.card}>
      <CardHeader title={'Info de ' + props.name.split(' ')[0]} />
      <CardContent>
        <TextField label="Nombre" name="name" value={props.name} onChange={props.onChange} disabled={onlyRead}/>
        <TextField label="Correo" name="email" value={props.email} onChange={props.onChange} disabled />
        <TextField label="Teléfono" name="phoneNumber" value={props.phoneNumber} onChange={props.onChange} disabled={onlyRead}/>
        <TextField select label="Status" name="status" value={props.status} onChange={props.onChange} disabled={onlyRead}>
          <MenuItem value="Activo">Activo</MenuItem>
          <MenuItem value="Desactivado">Desactivado</MenuItem>
          <MenuItem value="Bloqueado">Bloqueado</MenuItem>
        </TextField>
        <Box sx={{ textAlign: 'center' }}>
          {props.org === "milusos" && <Button variant="contained" onClick={props.onSubmit}>Guardar</Button>}          
        </Box>
        <Typography align="right" variant="body2">Cliente desde: {new Date(props.createdAt)?.toLocaleString()}</Typography>
      </CardContent>
    </Card>
  );
}

export default ClientDetailsCard;
