import { SET_WORK_ORDERS_SOURCE } from '../actions/types/marketingTypes';

const defaultState = {
  workOrdersSource: [],
};

const marketingReducer = (state = defaultState, { type, payload }) => {
  const newState = { ...state };

  switch(type) {
    case SET_WORK_ORDERS_SOURCE: newState.workOrdersSource = [...payload.workOrdersSource]; break;
    default: break;
  }

  return newState;
}

export default marketingReducer;
