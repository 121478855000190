const { REACT_APP_API_BASE_URL } = process.env;

export const getWorkOrders = (token, query) => new Promise((resolve, reject) => {
  const queryParams = new URLSearchParams({
    results: 10,
    ...query,
  }).toString();

  const request = new Request(REACT_APP_API_BASE_URL + '/work-orders?' + queryParams, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getWorkOrder = (token, id) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/work-orders/' + id, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const createWorkOrder = (token, workOrder) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/work-orders', {
    method: 'POST',
    body: JSON.stringify({ workOrder }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const updateWorkOrder = (token, id, workOrder) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/work-orders/' + id, {
    method: 'PUT',
    body: JSON.stringify({ workOrder }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const reassignWorkOrder = (token, id, data) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/work-orders/' + id + '/reassign', {
    method: 'PUT',
    body: JSON.stringify({ data }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});


export const reesendNotificationWorkOrder = (token, id, data) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/work-orders/' + id + '/sendNotifications', {
    method: 'POST',
    body: JSON.stringify({ data }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const deleteWorkOrder = (token, id) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/work-orders/'+id, {
    method: 'DELETE',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});
