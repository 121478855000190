import { getPayment, getPayments, createPayment } from '../../api/paymentsApi';
import { SET_PAYMENTS_LIST, SET_CURRENT_PAYMENT, RESET_PAYMENTS_STATE } from '../types/paymentsTypes';
import { startLoading, endLoading } from './loadingActionCreators';
import { handleError } from './errorsActionCreators';

export const setPaymentsList = payments => ({
  type: SET_PAYMENTS_LIST,
  payload: { payments }
});

export const setCurrentPayment = payment => ({
  type: SET_CURRENT_PAYMENT,
  payload: { payment }
});

export const resetPaymentsState = () => ({
  type: RESET_PAYMENTS_STATE,
  payload: { }
});

export const getPaymentsList = query => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getPayments(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setPaymentsList(data.payments)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
};

export const getCurrentPayment = id => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getPayment(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentPayment(data.payment)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const createNewPayment = (payment, callback) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  createPayment(token, payment)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(data => callback(data.payment))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}
