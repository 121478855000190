import { TableRow, TableCell } from '@mui/material';
import { Link } from 'react-router-dom';

const IssuesListItem = props => {
  const getPath = userType => {
    let path = '/dashboard/';

    switch(userType) {
      case 'Client': path += 'clientes/'; break;
      case 'Provider': path += 'socios/'; break;
      case 'Affiliator': path += 'afiliadores/'; break;
      case 'Admin': path += 'administradores/'; break;
      default: break;
    }

    return path;
  }

  return (
    <TableRow>
      <TableCell><Link to={props._id}>{props._id}</Link></TableCell>
      <TableCell><Link to={getPath(props.userType) + props.userRef?._id}>{props.userRef?.email}</Link></TableCell>
      <TableCell>{props.issue}</TableCell>
      <TableCell>{new Date(props.createdAt)?.toLocaleString()}</TableCell>
    </TableRow>
  );
}

export default IssuesListItem;
