import { combineReducers } from 'redux';
import authReducer from './authReducer';
import clientsReducer from './clientsReducer';
import providersReducer from './providersReducer';
import activationsReducer from './activationsReducer';
import workOrdersReducer from './workOrdersReducer';
import quotesReducer from './quotesReducer';
import paymentsReducer from './paymentsReducer';
import servicesReducer from './servicesReducer';
import organizationsReducer from './organizationsReducer';
import benefitsReducer from './benefitsReducer';
import productsReducer from './productsReducer';
import policiesReducer from './policiesReducer';
import issuesReducer from './issuesReducer';
import leadsReducer from './leadsReducer';
import promosReducer from './promosReducer';
import accidentsReducer from './accidentsReducer';
import metricsReducer from './metricsReducer';
import marketingReducer from './marketingReducer';
import bundlesReducer from './bundlesReducer';
import loadingReducer from './loadingReducer';
import alertsReducer from './alertsReducer';
import errorsReducer from './errorsReducer';
import reportsReducer from './reportsReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  clients: clientsReducer,
  providers: providersReducer,
  activations: activationsReducer,
  workOrders: workOrdersReducer,
  quotes: quotesReducer,
  payments: paymentsReducer,
  services: servicesReducer,
  organizations: organizationsReducer,
  benefits: benefitsReducer,
  products: productsReducer,
  policies: policiesReducer,
  issues: issuesReducer,
  leads: leadsReducer,
  promos: promosReducer,
  accidents: accidentsReducer,
  metrics: metricsReducer,
  marketing: marketingReducer,
  bundles: bundlesReducer,
  loading: loadingReducer,
  alerts: alertsReducer,
  errors: errorsReducer,
  reports: reportsReducer,
});

export default rootReducer;
