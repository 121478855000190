import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import PromosListTable from './PromosListTable';
import PromosFilter from './PromosFilter';
import PromosCreateDialog from './PromosCreateDialog';
import AppPagination from '../../../components/AppPagination';

import { getPromosList, resetPromosState } from '../../../actions/creators/promosActionCreators';
import { useFilteredPagination } from '../../../utils/tablesUtils';

const defaultFilter = {
  code: '',
  action: '',
  type: '',
  active: true,
};

const Promos = () => {
  const {
    filter, 
    handleChange, 
    onSubmit, 
    onClear, 
    nextPage, 
    prevPage, 
    page
  } = useFilteredPagination(defaultFilter, resetPromosState, getPromosList);
  const dispatch = useDispatch();
  const promos = useSelector(state => state.promos.list);
  
  const [showCreate, setShowCreate] = useState(false);

  useEffect(() => {
    return () => dispatch(resetPromosState());
  }, [dispatch]);


  const openCreate = () => setShowCreate(true);
  const closeCreate = () => setShowCreate(false);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '1rem' }}>Promociones</Typography>
        <Button sx={{ marginTop: '0' }} color="primary" onClick={openCreate}>Nuevo</Button>
      </Box>
      <PromosFilter {...filter} onChange={handleChange} onSubmit={onSubmit} onClear={onClear} />
      <PromosListTable promos={promos} />
      <AppPagination page={page} prev={prevPage} next={nextPage} hasNextPage={promos.length !== 0} />
      <PromosCreateDialog open={showCreate} onClose={closeCreate} />
    </>
  );
}

export default Promos;
