const { REACT_APP_API_BASE_URL } = process.env;

export const getProvidersReportApi = (token, query) => new Promise((resolve, reject) => {
  const queryParams = new URLSearchParams({
    ...query
  }).toString();

  const request = new Request(REACT_APP_API_BASE_URL + '/reports/providers?' + queryParams, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob();
    })
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'providers_report.csv'; // You can rename the file if you want
      document.body.appendChild(a); // This is required for Firefox
      a.click();
      a.remove();  // After triggering download, remove the anchor element
      window.URL.revokeObjectURL(url);
      resolve();
    })
    .catch(err => reject(err));
});
