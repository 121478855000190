import { TableRow, TableCell } from '@mui/material';
import { Link } from 'react-router-dom';

const PoliciesListItem = props => {
  console.log(props)
  return (
    <TableRow>
      <TableCell><Link to={props._id}>{props.series}</Link></TableCell>
      <TableCell>{props.buyer?.email}</TableCell>
      <TableCell>{props.buyer?.name} {props.buyer?.lastName}</TableCell>
      <TableCell>{new Date(props.validUntil)?.toLocaleString()}</TableCell>
      <TableCell>{props.temporal ? 'Sí' : 'No'}</TableCell>
      <TableCell>{props.payment?.promo ?? 'No'}</TableCell>
      <TableCell>{props.payment?.method ?? 'Externo'}</TableCell>
      <TableCell>{new Date(props.createdAt)?.toLocaleString()}</TableCell>
    </TableRow>
  );
}

export default PoliciesListItem;
