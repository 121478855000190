/* eslint-disable no-loop-func */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Grid, MenuItem, TextField, Typography } from "@mui/material";

import { setProviderPostalCodeData, updateCurrentProvider } from '../../../../actions/creators/providersActionCreators';
import { getUploadFileUrl, uploadFile } from '../../../../api/utilsApi';
import { getDownloadUrl } from '../../../../actions/creators/utilsActionCreators';

function toNormalForm(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[()]/g, "");
}


const styles = {
  card: {
    marginBottom: '1.5rem'
  },
  inputWrapper: {
    paddingX: '0.5rem'
  },
}

const ProviderDetailsForm = () => {
  const [isUploadingFiles, setIsUploadingFiles ]= useState(false)
  const [provider, setProvider] = useState({
    _id: '',
    name: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    curp: '',
    status: 'Desactivado',
    contacted:false,
    invoice: "No",
    invoiceFile0: null,
    invoiceFile1: null,
    invoiceFile2: null,
    address: '',
    postalCode: '',
    city: '',
    state: '',
    country: '',
    homeAddress: '',
    bundleRef: ''
  });

  const dispatch = useDispatch();
  const current = useSelector(state => state.providers.current);
  const bundles = useSelector(state => state.bundles.list);
      const token = useSelector(state => state.auth.token);
  useEffect(() => setProvider({ ...provider, ...current , invoiceFile0: provider?.invoiceFiles ? provider?.invoiceFiles[0]: null, invoiceFile1: provider?.invoiceFiles ? provider?.invoiceFiles[1]: null,invoiceFile2: provider?.invoiceFiles ? provider?.invoiceFiles[2]: null }), [current]);
  useEffect(() => provider.postalCode.length === 5 ? dispatch(setProviderPostalCodeData(provider.postalCode)) : null, [provider.postalCode]);

  const handleChange = e => {
    setProvider( (prevState) => 
      { 
        if(e?.target?.files && e?.target?.files[0]){
          return { ...prevState, [e.target.name]: e.target.files[0] };
        }else{

          return { ...prevState, [e.target.name]: e.target.value };
        }
      });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setIsUploadingFiles(true)

    const { name, lastName, email, phoneNumber, curp, status, contacted, address, postalCode, country, homeAddress, bundleRef, invoice, invoiceFile0, invoiceFile1, invoiceFile2 } = provider;
    const invoiceFiles = []
    let counter = 0
    for (const file of [invoiceFile0, invoiceFile1, invoiceFile2]) {
      console.log(file)
      if(file){
        if(typeof file === "string"){
          invoiceFiles[counter]=file
        }else{
          const {url,signedUrl} =await getUploadFileUrl(token,`tmp/socios/${provider._id}/docs`,toNormalForm(file?.name?.trim()?.replaceAll(" ","-").toLowerCase()))
          await uploadFile(signedUrl, file)
          invoiceFiles[counter]=url
        }
        
      }else{
        invoiceFiles[counter]= null        
      }


      setProvider( (prevState) => 
          { 
              return { ...prevState, "invoiceFile0": invoiceFiles[0], "invoiceFile1": invoiceFiles[1], "invoiceFile2": invoiceFiles[2], invoiceFiles };
            
          });

      counter+=1;
    }

    console.log(invoiceFiles)

    setIsUploadingFiles(false)

    dispatch(updateCurrentProvider(provider._id, { name, lastName, email, phoneNumber, curp, status, contacted, address, postalCode, country, homeAddress, bundleRef, invoice, invoiceFiles }));   

   

  }

  const downloadFile = (key, fileName)=>{
    dispatch(getDownloadUrl(key,fileName))
  }

  return (
    <Card sx={styles.card}>
      <CardHeader title="Datos del socio" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Nombre" name="name" value={provider.name} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Apellido" name="lastName" value={provider.lastName} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Correo" name="email" value={provider.email} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Teléfono" name="phoneNumber" value={provider.phoneNumber} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="CURP" name="curp" value={provider.curp} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={2} sx={styles.inputWrapper}>
           <TextField select label="Status" name="status" value={provider.status} onChange={handleChange}>
              <MenuItem value="Disponible">Disponible</MenuItem>
              <MenuItem value="Indispuesto">Indispuesto</MenuItem>
              <MenuItem value="Desactivado">Desactivado</MenuItem>
              <MenuItem value="Bloqueado">Bloqueado</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6} md={2} sx={styles.inputWrapper}>
           <TextField select label="Factura" name="invoice" value={provider?.invoice} onChange={handleChange}>
              <MenuItem value={"Si"}>Si</MenuItem>
              <MenuItem value={"No"}>No</MenuItem>
              <MenuItem value={"Regularizarse"}>Regularizarse</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6} md={2} sx={styles.inputWrapper}>
           <TextField select label="Contactado" name="contacted" value={provider.contacted} onChange={handleChange}>
              <MenuItem value={true}>Si</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </TextField>
          </Grid>
          {provider?.invoice!== "No" && (
          <Grid container style={{position:"relative"}}>
            
            <Grid item xs={12} md ={4} sx={styles.inputWrapper}>
              <Typography>Opinión de cumplimiento</Typography>
              {
                provider?.invoiceFiles && provider?.invoiceFiles[0] && provider?.invoiceFile0 ? 
                (
                <>
                  <Button style={{marginRight:"1em"}} onClick={()=>{
                    downloadFile(provider?.invoiceFiles[0],"cumplimiento-"+toNormalForm(provider?.name?.replace(" ","-")?.toLowerCase()))
                  }}>Descargar</Button>
                  <Button color="error" onClick={()=>{
                    setProvider( (prevState) => 
                        { 
                            return { ...prevState, "invoiceFile0": null };
                          
                        });
                  }}>Eliminar</Button>
                </> 
                ) : 
                (<TextField name="invoiceFile0" /* value={provider?.invoiceFiles[0]} */ onChange={handleChange} type="file" />)
              }
              
            </Grid>
            <Grid item xs={12} md ={4} sx={styles.inputWrapper}>
              <Typography>CSF</Typography>
              {
                provider?.invoiceFiles && provider?.invoiceFiles[1] && provider?.invoiceFile1?
                (
                <>
                  <Button style={{marginRight:"1em"}} onClick={()=>{
                    downloadFile(provider?.invoiceFiles[1],"csf-"+toNormalForm(provider?.name?.replace(" ","-")?.toLowerCase()))
                  }}>Descargar</Button>
                  <Button color="error" onClick={()=>{
                    setProvider( (prevState) => 
                        { 
                            return { ...prevState, "invoiceFile1": null };
                          
                        });
                  }}>Eliminar</Button>
                </> 
                ) : 
                (<TextField name="invoiceFile1" /* value={provider?.invoiceFiles[0]} */ onChange={handleChange} type="file" />)
              }
            </Grid>
            <Grid item xs={12} md ={4} sx={styles.inputWrapper}>
              <Typography>Estado de cuenta</Typography>
              {
                provider?.invoiceFiles && provider?.invoiceFiles[2] && provider?.invoiceFile2 ? 
                (
                <>
                  <Button style={{marginRight:"1em"}} onClick={()=>{
                    downloadFile(provider?.invoiceFiles[2],"estado-cuenta-"+toNormalForm(provider?.name?.replace(" ","-")?.toLowerCase()))
                  }}>Descargar</Button>
                  <Button color="error" onClick={()=>{
                    setProvider( (prevState) => 
                        { 
                            return { ...prevState, "invoiceFile2": null };
                          
                        });
                  }}>Eliminar</Button>
                </> 
                ) : 
                (<TextField name="invoiceFile2" /* value={provider?.invoiceFiles[0]} */ onChange={handleChange} type="file" />)
              }
            </Grid>
            {isUploadingFiles && <Box sx={{ display: 'flex', position: "absolute", top: 0, left: 0, width:"100%", height:"100%", justifyContent:"center", alignItems:"center", backgroundColor:"rgba(256,256,256,0.5)" }}>
              <CircularProgress />
            </Box>}
          </Grid>)}
          <Grid item xs={12} md ={9} sx={styles.inputWrapper}>
            <TextField label="Dirección de trabajo" name="address" value={provider.address} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={3} sx={styles.inputWrapper}>
            <TextField label="Código postal" name="postalCode" value={provider.postalCode} onChange={handleChange} inputProps={{ maxLength: 5 }} />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField label="Ciudad" name="city" value={provider.city} onChange={handleChange} disabled />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField label="Estado" name="state" value={provider.state} onChange={handleChange} disabled />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField label="País" name="country" value={provider.country} onChange={handleChange} disabled />
          </Grid>
          <Grid item xs={12} md={9} sx={styles.inputWrapper}>
            <TextField label="Dirección de casa" name="homeAddress" value={provider.homeAddress} onChange={handleChange} />
          </Grid>
          {/* <Grid item xs={12} md={9} sx={styles.inputWrapper}>
            <input type="file" id="file1" name="file" onChange={handleImageChange} />
            {file && <p>Selected file: {file.name}</p>}
          </Grid> */}
          <Grid item xs={12} md={3} sx={styles.inputWrapper}>
           <TextField select label="Plan" name="bundleRef" value={provider.bundleRef} onChange={handleChange}>
              <MenuItem value="">Sin plan</MenuItem>
              {bundles.map(bundle => <MenuItem key={bundle._id} value={bundle._id}>{bundle.name}</MenuItem>)}
            </TextField>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button color="primary" onClick={handleSubmit}>Guardar</Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ProviderDetailsForm;
