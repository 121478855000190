import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import IssuesListTable from './IssuesListTable';
import IssuesFilter from './IssuesFilter';
import AppPagination from '../../../components/AppPagination';

import { getIssuesList, resetIssuesState } from '../../../actions/creators/issuesActionCreators';
import { useFilteredPagination } from '../../../utils/tablesUtils';

const defaultFilter = {
  issue: ''
};

const Issues = () => {
  const {
    filter, 
    handleChange, 
    onSubmit, 
    onClear, 
    nextPage, 
    prevPage, 
    page
  } = useFilteredPagination(defaultFilter, resetIssuesState, getIssuesList);
  const dispatch = useDispatch();
  const issues = useSelector(state => state.issues.list);

  useEffect(() => {
    return () => dispatch(resetIssuesState());
  }, [dispatch]);

  return (
    <>
      <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '1rem' }}>Incidencias</Typography>
      <IssuesFilter {...filter} onChange={handleChange} onSubmit={onSubmit} onClear={onClear} />
      <IssuesListTable issues={issues} />
      <AppPagination page={page} prev={prevPage} next={nextPage} hasNextPage={issues.length !== 0} />
    </>
  );
}

export default Issues;
