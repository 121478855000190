import { getPolicy, getPolicies, createPolicy, updatePolicy } from '../../api/policiesApi';
import { SET_POLICIES_LIST, SET_CURRENT_POLICY, RESET_POLICIES_STATE } from '../types/policiesTypes';
import { startLoading, endLoading } from './loadingActionCreators';
import { showAlert } from './alertsActionCreators';
import { handleError } from './errorsActionCreators';

export const setPoliciesList = policies => ({
  type: SET_POLICIES_LIST,
  payload: { policies }
});

export const setCurrentPolicy = policy => ({
  type: SET_CURRENT_POLICY,
  payload: { policy }
});

export const resetPoliciesState = () => ({
  type: RESET_POLICIES_STATE,
  payload: { }
});

export const getPoliciesList = query => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getPolicies(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setPoliciesList(data.policies)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
};

export const getCurrentPolicy = id => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getPolicy(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentPolicy(data.policy)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const createNewPolicy = (policy, callback) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  createPolicy(token, policy)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(data => callback(data.policy))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const updateCurrentPolicy = (id, policy) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  updatePolicy(token, id, policy)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentPolicy(data.policy)))
  .then(() => dispatch(showAlert('Póliza actualizada', 'success')))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}
