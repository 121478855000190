import { getLastWorkOrders, getTotals, getServicesStatistics, getServicesCosts } from '../../api/metricsApi';
import { SET_LAST_WORK_ORDERS, SET_TOTALS, SET_SERVICES_STATISTICS, SET_SERVICES_COSTS } from '../types/metricsTypes';
import { startLoading, endLoading } from './loadingActionCreators';
import { handleError } from './errorsActionCreators';

export const setLastWorkOrders = lastWorkOrders => ({
  type: SET_LAST_WORK_ORDERS,
  payload: { lastWorkOrders }
});

export const setTotals = totals => ({
  type: SET_TOTALS,
  payload: { totals }
});

export const setServicesStatistics = statistics => ({
  type: SET_SERVICES_STATISTICS,
  payload: { statistics }
});

export const setServicesCosts = costs => ({
  type: SET_SERVICES_COSTS,
  payload: { costs }
});

export const getMetricsLastWorkOrders = query => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getLastWorkOrders(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setLastWorkOrders(data.data)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
};

export const getMetricsTotals = query => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getTotals(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setTotals(data.totals)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
};

export const getMetricsServicesStatistics = query => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getServicesStatistics(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setServicesStatistics(data.statistics)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
};

export const getMetricsServicesCosts = query => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getServicesCosts(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setServicesCosts(data.costs)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
};
