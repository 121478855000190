import { Card, CardHeader, CardContent, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { Link } from 'react-router-dom';

const styles = {
  card: {
    marginBottom: '1.5rem'
  }
}

const WorkOrderDetailsParticipant = props => {
  return (
    <Card sx={styles.card}>
      <CardHeader title={props.title} />
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>
                {props.org === "milusos" && props._id ? 
                  <Link to={`/dashboard/${props.participant}s/${props._id}`}>
                  {props.name ? `${props.name} ${props.lastName}` : 'Sin asignar'}
                </Link>
                :
                  props.name ? `${props.name} ${props.lastName}` : 'Sin asignar'
                 }                
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Correo</TableCell>
              <TableCell>{props.email ?? 'Sin asignar'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Teléfono</TableCell>
              <TableCell>{props.phoneNumber ?? 'Sin asignar'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

export default WorkOrderDetailsParticipant;
