import { SET_PROVIDERS_LIST, SET_CURRENT_PROVIDER, RESET_PROVIDERS_STATE } from '../actions/types/providersTypes';

const defaultState = {
  list: [],
  current: { }
};

const providersReducer = (state = defaultState, { type, payload }) => {
  const newState = { ...state };

  switch(type) {
    case SET_PROVIDERS_LIST: newState.list = [...payload.providers]; break;
    case SET_CURRENT_PROVIDER: newState.current = { ...payload.provider }; break;
    case RESET_PROVIDERS_STATE: newState.list = []; newState.current = { }; break;

    default: break;
  }

  return newState;
}

export default providersReducer;
