import { SHOW_ALERT, CLOSE_ALERT } from '../actions/types/alertsTypes';

const defaultState = {
  show: false,
  message: '',
  type: 'info'
};

const alertsReducer = (state = defaultState, { type, payload }) => {
  const newState = { ...state };

  switch(type) {
    case SHOW_ALERT: newState.show = true; newState.message = payload.message; newState.type = payload.type; break;
    case CLOSE_ALERT: newState.show = false; break;
    default: break;
  }

  return newState;
}

export default alertsReducer;
