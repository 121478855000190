import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';


export default function SearchInput({onSelected, label, placeholder, callToApi}) {
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request,
          callback,
        ) => {
          setLoading(true)
          //Call API
          callToApi(
            request,
            callback,
          );
        },
        400,
      ),
    [],
  );

  React.useEffect(() => {
    let active = true;

    /* if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    } */

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      setLoading(false)
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="google-map-demo"
      sx={{ width: "100%" }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : ""
      }
      loading={loading}
      loadingText={"Cargando"}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText={placeholder}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        onSelected(newValue)
        if(newValue){
          setValue(newValue?.name + " "+newValue?.lastName + ` (${newValue?.email})`);
        }else{
          setValue(null);
        }

      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} fullWidth required/>
      )}
      renderOption={(props, option) => {
        console.log(option)
        const match =
          option

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <PersonAddAlt1Icon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                
                  <Box
                    component="span"
                    sx={{ fontWeight: 'regular' }}
                  >
                    {match?.name} {match?.lastName}
                  </Box>
                
                <Typography variant="body2" color="text.secondary">
                  {match.email}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
