import { finishActivation, getActivation, uploadActivationPhoto } from '../../api/activationsApi';
import { SET_CURRENT_ACTIVATION, RESET_ACTIVATIONS_STATE } from '../types/activationsTypes';
import { startLoading, endLoading } from './loadingActionCreators';
import { showAlert } from './alertsActionCreators';
import { handleError } from './errorsActionCreators';

export const setCurrentActivation = activation => ({
  type: SET_CURRENT_ACTIVATION,
  payload: { activation }
});

export const resetActivationsState = () => ({
  type: RESET_ACTIVATIONS_STATE,
  payload: { }
});

export const getCurrentActivation = id => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getActivation(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentActivation(data.activation)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const uploadCurrentActivationPhoto = (id, photo, type) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  uploadActivationPhoto(token, id, photo, type)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentActivation(data.activation)))
  .then(() => dispatch(showAlert('Imagen actualizada', 'success')))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const finishCurrentActivation = id => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  finishActivation(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentActivation(data.activation)))
  .then(() => dispatch(showAlert('Socio activado', 'success')))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}
