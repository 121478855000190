import { getIssue, getIssues, createIssue, updateIssue } from '../../api/issuesApi';
import { SET_ISSUES_LIST, SET_CURRENT_ISSUE, RESET_ISSUES_STATE } from '../types/issuesTypes';
import { startLoading, endLoading } from './loadingActionCreators';
import { showAlert } from './alertsActionCreators';
import { handleError } from './errorsActionCreators';

export const setIssuesList = issues => ({
  type: SET_ISSUES_LIST,
  payload: { issues }
});

export const setCurrentIssue = issue => ({
  type: SET_CURRENT_ISSUE,
  payload: { issue }
});

export const resetIssuesState = () => ({
  type: RESET_ISSUES_STATE,
  payload: { }
});

export const getIssuesList = query => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getIssues(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setIssuesList(data.issues)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
};

export const getCurrentIssue = id => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getIssue(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentIssue(data.issue)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const createNewIssue = (issue, callback) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  createIssue(token, issue)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(data => callback(data.issue))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const updateCurrentIssue = (id, issue) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  updateIssue(token, id, issue)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentIssue(data.issue)))
  .then(() => dispatch(showAlert('Incidencia actualizada', 'success')))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}
