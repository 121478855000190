import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardHeader, CardContent, TextField, Button } from '@mui/material';

import { updateCurrentPolicy } from '../../../../actions/creators/policiesActionCreators';

const styles = {
  card: {
    marginBottom: '1.5rem'
  },
  audiencesContainer: {
    margin: '0.5rem'
  },
}

const PolicyDetailsBuyerForm = () => {
  const [buyer, setBuyer] = useState({
    name: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    birthdate: ''
  });

  const params = useParams();

  const current = useSelector(state => state.policies.current.buyer);
  const dispatch = useDispatch();

  useEffect(() => setBuyer({ ...buyer, ...current }), [current]);

  const handleChange = e => {
    setBuyer({ ...buyer, [e.target.name]: e.target.value });
  }

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(updateCurrentPolicy(params.id, { buyer }));
  }

  return (
    <Card sx={styles.card}>
      <CardHeader title="Titular" />
      <CardContent>
        <TextField label="Nombre" name="name" value={buyer.name} onChange={handleChange} />
        <TextField label="Apellido" name="lastName" value={buyer.lastName} onChange={handleChange} />
        <TextField label="Correo" name="email" value={buyer.email} onChange={handleChange} />
        <TextField label="Teléfono" name="phoneNumber" value={buyer.phoneNumber} onChange={handleChange} />
        <TextField type="date" label="Fecha de nacimiento" name="birthdate" value={buyer.birthdate.slice(0, 10)} onChange={handleChange} rows={4} />
        <Box sx={{ textAlign: 'center' }}>
          <Button variant="contained" onClick={handleSubmit}>Guardar</Button>
        </Box>
      </CardContent>
    </Card>
  );
}

export default PolicyDetailsBuyerForm;
