import { Box, Card, CardHeader, CardContent, Typography, TextField, Button } from '@mui/material';

const styles = {
  card: {
    marginBottom: '1.5rem'
  },
  audiencesContainer: {
    margin: '0.5rem'
  },
}

const BundleDetailsForm = props => {
  return (
    <Card sx={styles.card}>
      <CardHeader title={'Paquete: ' + props.name} />
      <CardContent>
        <TextField label="Nombre" name="name" value={props.name} onChange={props.onChange} />
        <TextField label="Precio" name="price" value={props.price} onChange={props.onChange} />
        <TextField label="ID de Stripe" name="stripeSubscription" value={props.stripeSubscription} onChange={props.onChange} />
        <TextField label="Descripción" name="description" value={props.description} onChange={props.onChange} multiline rows={4} />
        <Box sx={{ textAlign: 'center' }}>
          <Button variant="contained" onClick={props.onSubmit}>Guardar</Button>
        </Box>
        <Typography align="right" variant="body2">Creado: {new Date(props.createdAt)?.toLocaleString()}</Typography>
      </CardContent>
    </Card>
  );
}

export default BundleDetailsForm;
