import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, TextField, Dialog, DialogContent, DialogTitle, Button, DialogActions, MenuItem } from '@mui/material';

import { createNewProvider, getProvidersList } from '../../../actions/creators/providersActionCreators';
import { getServicesList } from '../../../actions/creators/servicesActionCreators';
import { createNewWorkOrder } from '../../../actions/creators/workOrdersActionCreators';
import AddressSearchInput from '../../../components/AddressSearchInput';
import { getClientsList } from '../../../actions/creators/clientsActionCreators';
import SearchInput from '../../../components/SearchInput';
import { getClients } from '../../../api/clientsApi';
import { getProviders } from '../../../api/providersApi';

const styles = {
  container: {
    display: 'block'
  },
  content: {
    paddingY: 0
  },
  actions: {
    paddingTop: 0,
    paddingRight: '1rem'
  },
  inputWrapper: {
    paddingX: '0.25rem'
  },
}

const WorkOrdersCreateDialog = props => {
  const services = useSelector(state => state.services.list);
  const token = useSelector(state => state.auth.token);
  const [workOrder, setWorkOrder] = useState({
    title: '',
    description: '',
    serviceType: '',
    requestedTo: '',
    requestedBy: '',
    address: ''
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => dispatch(getServicesList()), []);

  const searchClients = async (request,callback)=>{
    const query = { page: 1, multiSearch: true };

    request.input.length > 0 ? query.name = request.input : null;
    /* filterClients.email.length > 0 ? query.email = filterClients.email : null; */

    const result = await getClients(token, query).then(response => response.json() )
    callback(result?.clients || [])
  }

  const searchProviders = async (request,callback)=>{
    const query = { page: 1, multiSearch: true };

    request.input.length > 0 ? query.name = request.input : null;
    /* filterClients.email.length > 0 ? query.email = filterClients.email : null; */

    const result = await getProviders(token, query).then(response => response.json() )
    console.log(result)
    callback(result?.providers || [])
  }

  const handleClose = e => {
    e.preventDefault();
    props.onClose();
  }

  const handleChange = e => {
    setWorkOrder({ ...workOrder, [e.target.name]: e.target.value });
  }

  const handleRedirect = id => {
    navigate('/dashboard/ordenes/' + id);
  }

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(createNewWorkOrder({...workOrder, status:"Aceptado"}, handleRedirect));
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>Nueva orden de trabajo</DialogTitle>
      <DialogContent sx={styles.content}>
        <Grid container>
          <Grid item xs={12} md={12} sx={styles.inputWrapper}>
            <TextField label="Título" name="title" value={workOrder.title} onChange={handleChange} required />
          </Grid>
          <Grid item xs={12} md={12} sx={styles.inputWrapper}>
            <TextField label="Descripción" name="description" value={workOrder.description} onChange={handleChange} required />
          </Grid>
          
          <Grid item xs={12} md={12} sx={styles.inputWrapper}>
            <TextField select label="Tipo" name="serviceType" value={workOrder.serviceType} onChange={handleChange} required>
              {services.map(service => <MenuItem key={service._id} value={service._id}>{service.name}</MenuItem>)}
                </TextField>
          </Grid>
          <Grid item xs={12} md={12} sx={styles.inputWrapper}>
            <SearchInput label="Cliente" name="client" callToApi={searchClients} placeholder={"Sin Clientes"} onSelected={(value)=>setWorkOrder({ ...workOrder, requestedBy: value?._id })}/>
            {/* <TextField label="Client" name="client" value={workOrder.client} onChange={handleChange} required /> */}
          </Grid>
          <Grid item xs={12} md={12} sx={styles.inputWrapper}>
            <SearchInput label="Socio" name="provider" callToApi={searchProviders} placeholder={"Sin Socio"} onSelected={(value)=>setWorkOrder({ ...workOrder, requestedTo: value?._id })}/>
            {/* <TextField label="Socio" name="provider" value={workOrder.provider} onChange={handleChange} required /> */}
          </Grid>
          <Grid item xs={12} md={12} sx={styles.inputWrapper}>
            <AddressSearchInput onSelected={(value)=>setWorkOrder({ ...workOrder, address: value?.description })}/>
            {/* <TextField label="Dirección" name="address" value={workOrder.address} onChange={handleChange} /> */}
          </Grid>
          {/* <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField label="Coordenadas" name="location" value={workOrder.location} onChange={handleChange} required />
          </Grid> */}
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.actions}>
      <Button variant="text" onClick={handleSubmit} disabled={workOrder.title === "" || workOrder.description === "" || workOrder.serviceType === "" || workOrder.requestedTo === "" || workOrder.requestedBy === "" || workOrder.address === ""}>Guardar</Button>
        <Button variant="text" onClick={handleClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default WorkOrdersCreateDialog;
