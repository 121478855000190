import { getLead, getLeads, updateLead } from '../../api/leadsApi';
import { SET_LEADS_LIST, SET_CURRENT_LEAD, RESET_LEADS_STATE } from '../types/leadsTypes';
import { startLoading, endLoading } from './loadingActionCreators';
import { showAlert } from './alertsActionCreators';
import { handleError } from './errorsActionCreators';

export const setLeadsList = leads => ({
  type: SET_LEADS_LIST,
  payload: { leads }
});

export const setCurrentLead = lead => ({
  type: SET_CURRENT_LEAD,
  payload: { lead }
});

export const resetLeadsState = () => ({
  type: RESET_LEADS_STATE,
  payload: { }
});

export const getLeadsList = query => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getLeads(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setLeadsList(data.leads)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
};

export const getCurrentLead = id => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getLead(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentLead(data.lead)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const updateCurrentLead = (id, lead) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  updateLead(token, id, lead)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentLead(data.lead)))
  .then(() => dispatch(showAlert('Lead actualizado', 'success')))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}
