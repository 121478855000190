import { useEffect, useState } from 'react';
import { Paper, Collapse, Typography, Box, Grid, TextField, Button, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {  resetFilterReport, setReportPostalCodeData } from '../../../actions/creators/reportsActionCreators';

const states = [
  'Aguascalientes',      'Baja California',
  'Baja California Sur', 'Chiapas',
  'Chihuahua',           'Ciudad de México',
  'Coahuila',            'Colima',
  'Durango',             'Estado de México',
  'Guanajuato',          'Guerrero',
  'Hidalgo',             'Jalisco',
  'Michoacán',           'Morelos',
  'Nayarit',             'Nuevo León',
  'Oaxaca',              'Puebla',
  'Querétaro',           'Quintana Roo',
  'San Luis Potosí',     'Sinaloa',
  'Sonora',              'Tabasco',
  'Tamaulipas',          'Tlaxcala',
  'Veracruz',            'Yucatán'
]

const styles = {
  paper: {
    marginBottom: '1rem'
  },
  header: {
    padding: '1rem',
    display: 'flex',
    justifyContent: 'around'
  },
  title: {
    flex: 1
  },
  container: {
    padding: '1rem',
    paddingTop: '0'
  },
  inputWrapper: {
    paddingX: '0.5rem'
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-around'
  }
}

const ReportsFilter = ({ reportType, state, city, postalCode,  onChange, onClear, onSubmit}) => {
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(true);

  const dispatch = useDispatch();

  const reports = useSelector(state => state.reports);


    useEffect(() => 
        postalCode.length === 5 
            ? dispatch(setReportPostalCodeData(postalCode)) 
            : null,
    [postalCode]);

    useEffect(() => {
        let  e = {
            target:{
                name:'state',
                value:reports?.state??''
            }
        };
        onChange(e)
            e = {
            target:{
                name:'city',
                value:reports?.city??''
            }
        };
        onChange(e)
    }, [reports])

    const handlerClear = () =>{
        dispatch(resetFilterReport())
        onClear();
    }
    

  return (
    <Paper elevation={3} sx={styles.paper}>
      <Box sx={styles.header}>
        <Grid container justifyContent={'center'}>
            <Grid item xs={12} md={6}>
              <TextField margin='' select label="Tipo de reporte" name="reportType" value={reportType} onChange={onChange}>
                  <MenuItem value="Socios">Socios</MenuItem>
              </TextField>
            </Grid>
        </Grid>
      </Box>
      <Collapse in={open}>
        <Grid item xs={12} md={12}>
            <Typography mx={3} variant="h6" fontWeight="bold" sx={styles.title}>Filtros</Typography>
        </Grid>
        <Grid container component="form" sx={styles.container} onSubmit={onSubmit}>
        <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField 
                type='text' 
                label="Código Postal" 
                name="postalCode" 
                inputProps={{ maxLength: 5 }} 
                value={postalCode} 
                onChange={onChange} 
            />       
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField select label="Estado" name="state" value={state} onChange={onChange}>
              <MenuItem value="">Todos</MenuItem>
              {states.map(localState => <MenuItem key={localState} value={localState}>{localState}</MenuItem>)}         
            </TextField>
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField type='text' label="Ciudad" name="city" value={city} onChange={onChange} />       
          </Grid>
          <Grid item xs={12} sx={styles.actions}>
            <Button type="submit">Descargar</Button>
            <Button onClick={handlerClear} color="neutral">Limpiar</Button>
          </Grid>
        </Grid>
      </Collapse>
    </Paper>
  );
}

export default ReportsFilter;