const { REACT_APP_API_BASE_URL } = process.env;

export const getActivation = (token, id) => new Promise((resolve, reject) => {
  const request = new Request(`${REACT_APP_API_BASE_URL}/providers/${id}/activation`, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const uploadActivationPhoto = (token, id, photo, type) => new Promise((resolve, reject) => {
  const data = new FormData();
  data.append('photo', photo);

  const request = new Request(`${REACT_APP_API_BASE_URL}/providers/${id}/activation/${type}`, {
    method: 'PUT',
    body: data,
    headers: {
      'Authorization': 'Bearer ' + token
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const finishActivation = (token, id) => new Promise((resolve, reject) => {
  const request = new Request(`${REACT_APP_API_BASE_URL}/providers/${id}/activation`, {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + token
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});
