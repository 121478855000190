import { getBundle, getBundles, createBundle, updateBundle } from '../../api/bundlesApi';
import { SET_BUNDLES_LIST, SET_CURRENT_BUNDLE, RESET_BUNDLES_STATE } from '../types/bundlesTypes';
import { startLoading, endLoading } from './loadingActionCreators';
import { showAlert } from './alertsActionCreators';
import { handleError } from './errorsActionCreators';

export const setBundlesList = bundles => ({
  type: SET_BUNDLES_LIST,
  payload: { bundles }
});

export const setCurrentBundle = bundle => ({
  type: SET_CURRENT_BUNDLE,
  payload: { bundle }
});

export const resetBundlesState = () => ({
  type: RESET_BUNDLES_STATE,
  payload: { }
});

export const getBundlesList = query => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getBundles(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setBundlesList(data.bundles)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
};

export const getCurrentBundle = id => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getBundle(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentBundle(data.bundle)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const createNewBundle = (bundle, callback) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  createBundle(token, bundle)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(data => callback(data.bundle))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const updateCurrentBundle = (id, bundle) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  updateBundle(token, id, bundle)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentBundle(data.bundle)))
  .then(() => dispatch(showAlert('Paquete actualizado', 'success')))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}
