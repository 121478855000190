import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Card, CardContent, MenuItem, TextField } from '@mui/material';
import Chart from 'chart.js/auto';

import { getMetricsLastWorkOrders, getMetricsTotals } from '../../../actions/creators/metricsActionCreators';

const styles = {
  container: {
    height: '75vh',
    display: 'flex',
    flexDirection: 'column'
  },
  chart: {
    flex: '1'
  }
}

const data = {
  labels: ['', '', '', '', '', '', ''],
  datasets: [{
    label: 'Ordenes de trabajo',
    backgroundColor: '#FFA62B',
    borderColor: '#FFA62B',
    data: [0, 0, 0, 0, 0, 0, 0],
  }]
}

const HomeWorkOrdersChart = () => {
  const canvas = useRef();
  const [lapse, setLapse] = useState('7.day.7 días');
  const [title, setTitle] = useState('7 días');

  const lastWorkOrders = useSelector(state => state.metrics.lastWorkOrders);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMetricsLastWorkOrders({ lapse: 'day', range: 7 }));
  }, []);

  useEffect(() => {
    const chart = new Chart(canvas.current, {
      type: 'line',
      data,
      options: {
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: 'Servicios últimos ' + title,
            font: { size: 30 }
          }
        }
      }
    });

    data.labels = lastWorkOrders.map(w => w.label);
    data.datasets[0].data = lastWorkOrders.map(w => w.count);

    chart.update();

    return () => chart.destroy();
  }, [canvas, lastWorkOrders]);

  const handleChange = e => {
    setLapse(e.target.value);
    
    const [range, lapse, title] = e.target.value?.split('.');

    setTitle(title);

    dispatch(getMetricsLastWorkOrders({ lapse, range }));
    dispatch(getMetricsTotals());
  }

  return (
    <Card>
      <CardContent sx={styles.container}>
        <TextField select value={lapse} onChange={handleChange}>
          <MenuItem value="7.day.7 días">7 días</MenuItem>
          <MenuItem value="30.day.30 días">30 días</MenuItem>
          <MenuItem value="6.month.6 meses">6 meses</MenuItem>
          <MenuItem value="12.month.12 meses">12 meses</MenuItem>
        </TextField>
        <Box sx={styles.chart}>
          <canvas style={styles.canvas} ref={canvas} />
        </Box>
      </CardContent>
    </Card>
  );
}

export default HomeWorkOrdersChart;
