import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Card, CardHeader, CardContent, TextField, Button, Table, TableBody, Typography } from '@mui/material';
import WorkOrderDetailsQuotesItem from './WorkOrderDetailsQuotesItem';

import { createNewQuote } from '../../../../actions/creators/quotesActionCreators';

const styles = {
  card: {
    marginBottom: '1.5rem'
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  table: {
    marginTop: '1rem'
  },
  inputWrapper: {
    paddingX: '0.5rem'
  }
}

const WorkOrderDetailsQuotes = props => {
  const [data, setData] = useState({ quote: 0, description: '', duration: '' });

  const quotes = useSelector(state => state.quotes.list);
  const dispatch = useDispatch();

  const handleChange = e => setData({ ...data, [e.target.name]: e.target.value });

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(createNewQuote(props.id, data));
  }

  return (
    <Card sx={styles.card}>
      <CardHeader title="Presupuestos" />
      <CardContent>
        <Typography variant="h6" fontWeight="bold">Nuevo Presupuesto</Typography>
        <Grid container>
          <Grid container item xs={12} md={8} lg={9}>
            <Grid item xs={12} sx={styles.inputWrapper}>
              <TextField label="Descripción" name="description" value={data.description} onChange={handleChange} />
            </Grid>
            <Grid item xs={12} md={6} sx={styles.inputWrapper}>
              <TextField type="number" label="Precio" name="quote" value={data.quote} onChange={handleChange} />
            </Grid>
            <Grid item xs={12} md={6} sx={styles.inputWrapper}>
              <TextField label="Duración" name="duration" value={data.duration} onChange={handleChange} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} lg={3} sx={styles.buttonWrapper}>
            <Button color="primary" onClick={handleSubmit}>Añadir</Button>
          </Grid>
        </Grid>
        <Table sx={styles.table}>
          <TableBody>
            {quotes?.map(quote => <WorkOrderDetailsQuotesItem key={quote._id} {...quote} />)}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

export default WorkOrderDetailsQuotes;
