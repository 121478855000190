import { Box, Typography } from '@mui/material';

const styles = {
  message: {
    borderRadius: '10px',
    display: 'flex',
    maxWidth: '70%',
    color: 'primary.contrastText',
    padding: '0.75rem',
    marginBottom: '0.5rem',
    cursor: 'pointer'
  },
  img: {
    maxWidth: '100%'
  }
}

const WorkOrderDetailsChatMessage = props => {
  const clientSent = props.client === props.from;

  const handleDelete = () => {
    props.onDelete(props.id, props.content);
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: clientSent ? 'start' : 'end' }}>
      <Box sx={{ ...styles.message, backgroundColor: clientSent ? 'primary.main' : 'neutral.main' }} onClick={handleDelete}>
        {props.type === 'text' ?
          <Typography>{props.content}</Typography> :
          <img src={props.content} alt={props.id} style={styles.img} />
        }
      </Box>
    </Box>
  );
}

export default WorkOrderDetailsChatMessage;
