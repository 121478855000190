import { SET_PAYMENTS_LIST, SET_CURRENT_PAYMENT, RESET_PAYMENTS_STATE } from '../actions/types/paymentsTypes';

const defaultState = {
  list: [],
  current: { }
};

const paymentsReducer = (state = defaultState, { type, payload }) => {
  const newState = { ...state };

  switch(type) {
    case SET_PAYMENTS_LIST: newState.list = [...payload.payments]; break;
    case SET_CURRENT_PAYMENT: newState.current = { ...payload.payment }; break;
    case RESET_PAYMENTS_STATE: newState.list = []; newState.current = { }; break;

    default: break;
  }

  return newState;
}

export default paymentsReducer;
