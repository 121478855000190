import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Button } from '@mui/material';
import { AccountCircle, Article, Assignment, AssignmentInd, Build, CardMembership, GroupAdd, LocalActivity, Report, ReportProblem, Sell, SentimentVerySatisfied, Addchart} from '@mui/icons-material';
import { logout } from '../actions/creators/authActionCreators';
import SummarizeIcon from '@mui/icons-material/Summarize';
const items = [
  { text: 'Clientes', icon: <AccountCircle />, path: '/dashboard/clientes' },
  { text: 'Socios', icon: <AssignmentInd />, path: '/dashboard/socios' },
  { text: 'Órdenes de trabajo', icon: <Assignment />, path: '/dashboard/ordenes' },
  { text: 'Servicios', icon: <Build />, path: '/dashboard/servicios' },
  { text: 'Beneficios', icon: <SentimentVerySatisfied />, path: '/dashboard/beneficios' },
  { text: 'Productos', icon: <CardMembership />, path: '/dashboard/productos' },
  { text: 'Pólizas', icon: <Article />, path: '/dashboard/polizas' },
  { text: 'Siniestros', icon: <ReportProblem />, path: '/dashboard/siniestros' },
  { text: 'Leads', icon: <GroupAdd />, path: '/dashboard/leads' },
  { text: 'Promociones', icon: <LocalActivity />, path: '/dashboard/promociones' },
  { text: 'Paquetes', icon: <Sell />, path: '/dashboard/paquetes' },
  { text: 'Incidencias', icon: <Report />, path: '/dashboard/incidencias' },
  { text: 'Marketing', icon: <Addchart />, path: '/dashboard/marketing' },
  { text: 'IKEA/DEMO', icon: <Addchart />, path: '/dashboard/ikea-demo' },
  { text: 'Reportes', icon: <SummarizeIcon />, path: '/dashboard/reports' },
];

const styles = {
  drawer: {
    width: 230,
    flex: 1
  },
  logoWrapper: {
    justifyContent: 'center'
  },
  logo: {
    width: '90%',
    cursor: 'pointer'
  },
  dividerWrapper: {
    marginY: '0.75rem',
    paddingX: '1rem'
  },
  buttonWrapper: {
    textAlign: 'center',
    padding: '1rem'
  }
}

const AppDrawer = props => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth)

  const handleNavigate = path => () => {
    navigate(path);
    props.onClose();
  }

  const handleLogout = () => {
    dispatch(logout(() => navigate('/login')));
  }

  if(!auth) return

  let filteredItems;

  if (auth.org === 'milusos') {
    filteredItems = items; // Show all items when auth.org is "all"
  } else if (auth.org === 'broktool') {
    filteredItems = items.filter(item => item.text === 'Clientes' || item.text === 'Órdenes de trabajo');
  }

  return (
    <Drawer {...props}>
      <Box
        sx={styles.drawer}
        role="presentation"
      >
        <List>
          <ListItem sx={styles.logoWrapper}>
            <img src="/img/milusos-brand-logo.png" alt="milusos logo" style={styles.logo} onClick={handleNavigate("")} />
          </ListItem>
          <Box sx={styles.dividerWrapper}>
            <Divider />
          </Box>
          {filteredItems?.map(item => 
            <ListItemButton key={item.text} onClick={handleNavigate(item.path)}>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          )}
        </List>
      </Box>
      <Box sx={styles.buttonWrapper}>
        <Button fullWidth variant="text" onClick={handleLogout}>Cerrar sesión</Button>
      </Box>
    </Drawer>
  );
}

export default AppDrawer;
