import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import BenefitsListItem from './BenefitsListItem';

const BenefitsListTable = props => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead color="primary">
          <TableRow>
            <TableCell>Título</TableCell>
            <TableCell>Descripción</TableCell>
            <TableCell>Estado</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Audiencia</TableCell>
            <TableCell>Creada</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.benefits?.map(benefit => <BenefitsListItem key={benefit._id} {...benefit} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default BenefitsListTable;
