import { getBenefit, getBenefits, createBenefit, updateBenefit, uploadBenefitImage } from '../../api/benefitsApi';
import { SET_BENEFITS_LIST, SET_CURRENT_BENEFIT, RESET_BENEFITS_STATE } from '../types/benefitsTypes';
import { startLoading, endLoading } from './loadingActionCreators';
import { showAlert } from './alertsActionCreators';
import { handleError } from './errorsActionCreators';

export const setBenefitsList = benefits => ({
  type: SET_BENEFITS_LIST,
  payload: { benefits }
});

export const setCurrentBenefit = benefit => ({
  type: SET_CURRENT_BENEFIT,
  payload: { benefit }
});

export const resetBenefitsState = () => ({
  type: RESET_BENEFITS_STATE,
  payload: { }
});

export const getBenefitsList = query => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getBenefits(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setBenefitsList(data.benefits)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
};

export const getCurrentBenefit = id => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getBenefit(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentBenefit(data.benefit)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const createNewBenefit = (benefit, callback) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  createBenefit(token, benefit)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(data => callback(data.benefit))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const updateCurrentBenefit = (id, benefit) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  updateBenefit(token, id, benefit)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentBenefit(data.benefit)))
  .then(() => dispatch(showAlert('Beneficio actualizado', 'success')))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const uploadCurrentBenefitImage = (id, image) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  uploadBenefitImage(token, id, image)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentBenefit(data.benefit)))
  .then(() => dispatch(showAlert('Imagen actualizada', 'success')))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}
