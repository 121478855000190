import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Grid, TextField, Dialog, DialogContent, DialogTitle, Button, DialogActions, MenuItem } from '@mui/material';

import { createNewBenefit } from '../../../actions/creators/benefitsActionCreators';

const styles = {
  container: {
    display: 'block'
  },
  content: {
    paddingY: 0
  },
  actions: {
    paddingTop: 0,
    paddingRight: '1rem'
  },
  inputWrapper: {
    paddingX: '0.25rem'
  },
}

const BenefitsCreateDialog = props => {
  const [benefit, setBenefit] = useState({
    title: '',
    type: ''
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = e => {
    e.preventDefault();
    props.onClose();
  }

  const handleChange = e => {
    setBenefit({ ...benefit, [e.target.name]: e.target.value });
  }

  const handleRedirect = id => {
    navigate('/dashboard/beneficios/' + id);
  }

  const handleSubmit = e => {
    e.preventDefault();

    dispatch(createNewBenefit(benefit, handleRedirect));
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>Nuevo beneficio</DialogTitle>
      <DialogContent sx={styles.content}>
        <Grid container>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField label="Título" name="title" value={benefit.title} onChange={handleChange} />
          </Grid>
          <TextField select label="Tipo" name="type" value={benefit.type} onChange={handleChange}>
            <MenuItem value="Seguro">Seguro</MenuItem>
            <MenuItem value="Crédito">Crédito</MenuItem>
            <MenuItem value="Anuncio">Anuncio</MenuItem>
          </TextField>
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.actions}>
      <Button variant="text" onClick={handleSubmit}>Guardar</Button>
        <Button variant="text" onClick={handleClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default BenefitsCreateDialog;
