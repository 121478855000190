export const getUserPath = userType => {
  let path = '/dashboard/';

  switch(userType) {
    case 'Client': path += 'clientes/'; break;
    case 'Provider': path += 'socios/'; break;
    case 'Affiliator': path += 'afiliadores/'; break;
    case 'Admin': path += 'administradores/'; break;
    default: break;
  }

  return path;
}

export const getUserType = userType => {
  switch(userType) {
    case 'Client': return 'Cliente';
    case 'Provider': return 'Socio';
    case 'Affiliator': return 'Afiliador';
    case 'Admin': return 'Administrador';
    default: return 'Sin asignar';
  }
}
