import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import AccidentsListTable from './AccidentsListTable';
import AccidentsFilter from './AccidentsFilter';
import AppPagination from '../../../components/AppPagination';

import { getAccidentsList, resetAccidentsState } from '../../../actions/creators/accidentsActionCreators';
import { useFilteredPagination } from '../../../utils/tablesUtils';

const defaultFilter = {
  identifier: '',
  policy: '',
  email: '',
  phoneNumber: ''
};

const Accidents = () => {
  const {
    filter, 
    handleChange, 
    onSubmit, 
    onClear, 
    nextPage, 
    prevPage, 
    page
  } = useFilteredPagination(defaultFilter, resetAccidentsState, getAccidentsList);
  const dispatch = useDispatch();
  const accidents = useSelector(state => state.accidents.list);

  useEffect(() => {
    return () => dispatch(resetAccidentsState());
  }, [dispatch]);

  return (
    <>
      <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '1rem' }}>Accidents</Typography>
      <AccidentsFilter {...filter} onChange={handleChange} onSubmit={onSubmit} onClear={onClear} />
      <AccidentsListTable accidents={accidents} />
      <AppPagination page={page} prev={prevPage} next={nextPage} hasNextPage={accidents.length !== 0} />
    </>
  );
}

export default Accidents;
