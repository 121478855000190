import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardContent } from '@mui/material';
import Chart from 'chart.js/auto';

import { getMetricsServicesCosts } from '../../../actions/creators/metricsActionCreators';

const styles = {
  card: {
    marginBottom: '1.5rem'
  },
  container: {
    height: '70vh',
    display: 'flex',
    flexDirection: 'column'
  },
  chart: {
    flex: '1'
  }
}

const buildCostsChart = costs => ({
  type: 'bar',
  data: {
    labels: costs.map(s => s.serviceRef?.name ?? 'Servicio'),
    datasets: [{
      label: 'Costo promedio',
      backgroundColor: 'rgba(54, 162, 235, 0.7)',
      data: costs.map(s => s.avg),
    }]
  },
  options: {
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: 'Costos por servicio',
        font: { size: 30 }
      },
      tooltip: {
        callbacks: {
          footer: tooltipFooter(costs),
        }
      }
    }
  }
});

const tooltipFooter = statistics => items => {
  const index = items[0].dataIndex;

  return `Muestra: ${statistics[index].count ?? 0}\nMin: ${statistics[index].min ?? 0}\nMax: ${statistics[index].max ?? 0}\nσ: ${statistics[index].sd ?? 0}`;
}

const ServicesCostsChart = () => {
  const canvas = useRef();

  const costs = useSelector(state => state.metrics.services.costs);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMetricsServicesCosts());
  }, []);

  useEffect(() => {
    const chart = new Chart(canvas.current, buildCostsChart(costs));

    chart.update();

    return () => chart.destroy();
  }, [canvas, costs]);

  return (
    <Card elevation={3} sx={styles.card}>
      <CardContent sx={styles.container}>
        <Box sx={styles.chart}>
          <canvas style={styles.canvas} ref={canvas} />
        </Box>
      </CardContent>
    </Card>
  );
}

export default ServicesCostsChart;
