import { Chip } from '@mui/material';

const styles = {
  chip: {
    margin: '0.5rem'
  }
}

const ProviderDetailsServicesItem = props => {
  const handleDelete = e => {
    e.preventDefault();
    props.onDelete(props.serviceRef._id);
  }

  const handleClick = e => {
    e.preventDefault();
    props.onToggle(props.serviceRef._id);
  }

  return (
    <Chip
      label={props.serviceRef.name}
      data-id={props.serviceRef._id}
      color={props.available ? 'primary' : 'error'}
      onClick={handleClick}
      onDelete={handleDelete}
      sx={styles.chip}
    />
  );
}

export default ProviderDetailsServicesItem;
