import { SET_QUOTES_LIST, SET_CURRENT_QUOTE, RESET_QUOTES_STATE } from '../actions/types/quotesTypes';

const defaultState = {
  list: [],
  current: { }
};

const quotesReducer = (state = defaultState, { type, payload }) => {
  const newState = { ...state };

  switch(type) {
    case SET_QUOTES_LIST: newState.list = [...payload.quotes]; break;
    case SET_CURRENT_QUOTE: newState.current = { ...payload.quote }; break;
    case RESET_QUOTES_STATE: newState.list = []; newState.current = { }; break;

    default: break;
  }

  return newState;
}

export default quotesReducer;
