import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import PoliciesListTable from './PoliciesListTable';
import PoliciesFilter from './PoliciesFilter';
import AppPagination from '../../../components/AppPagination';

import { getPoliciesList, resetPoliciesState } from '../../../actions/creators/policiesActionCreators';
import { useFilteredPagination } from '../../../utils/tablesUtils';

const defaultFilter = {
  series: '',
};

const Policies = () => {
  const {
    filter, 
    handleChange, 
    onSubmit, 
    onClear, 
    nextPage, 
    prevPage, 
    page
  } = useFilteredPagination(defaultFilter, resetPoliciesState, getPoliciesList);
  const dispatch = useDispatch();
  const policies = useSelector(state => state.policies.list);

  useEffect(() => {
    return () => dispatch(resetPoliciesState());
  }, [dispatch]);


  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '1rem' }}>Pólizas</Typography>
      </Box>
      <PoliciesFilter {...filter} onChange={handleChange} onSubmit={onSubmit} onClear={onClear} />
      <PoliciesListTable policies={policies} />
      <AppPagination page={page} prev={prevPage} next={nextPage} hasNextPage={policies.length !== 0} />
    </>
  );
}

export default Policies;
