import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import WorkOrdersListItem from './WorkOrdersListItem';

const WorkOrdersListTable = props => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead color="primary">
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Título</TableCell>
            <TableCell>Organización</TableCell>
            <TableCell>Solicitada por</TableCell>
            <TableCell>Asignada a</TableCell>
            <TableCell>Servicio</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Creada en</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.workOrders?.map(workOrder => <WorkOrdersListItem key={workOrder._id} {...workOrder} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default WorkOrdersListTable;
