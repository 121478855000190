import { SHOW_ERROR, CLOSE_ERROR } from '../actions/types/errorsTypes';

const defaultState = {
  show: false,
  errors: []
};

const errorsReducer = (state = defaultState, { type, payload }) => {
  const newState = { ...state };

  switch(type) {
    case SHOW_ERROR: newState.show = true; newState.errors = payload.error; break;
    case CLOSE_ERROR: newState.show = false; break;
    default: break;
  }

  return newState;
}

export default errorsReducer;
