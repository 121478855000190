const { REACT_APP_API_BASE_URL } = process.env;

export const getBenefits = (token, query) => new Promise((resolve, reject) => {
  const queryParams = new URLSearchParams({
    results: 10,
    ...query
  }).toString();

  const request = new Request(REACT_APP_API_BASE_URL + '/benefits?' + queryParams, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getBenefit = (token, id) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/benefits/' + id, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const createBenefit = (token, benefit) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/benefits', {
    method: 'POST',
    body: JSON.stringify({ benefit }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const updateBenefit = (token, id, benefit) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/benefits/' + id, {
    method: 'PUT',
    body: JSON.stringify({ benefit }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const uploadBenefitImage = (token, id, image) => new Promise((resolve, reject) => {
  const data = new FormData();
  data.append('image', image);

  const request = new Request(`${REACT_APP_API_BASE_URL}/benefits/${id}/image`, {
    method: 'PUT',
    body: data,
    headers: {
      'Authorization': 'Bearer ' + token
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});
