/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import ProvidersListTable from './ProvidersListTable';
import ProvidersFilter from './ProvidersFilter';
import ProvidersCreateDialog from './ProvidersCreateDialog';
import AppPagination from '../../../components/AppPagination';

import { getProvidersList, resetProvidersState } from '../../../actions/creators/providersActionCreators';
// import {useNavigate, useSearchParams } from 'react-router-dom';
import { useFilteredPagination } from '../../../utils/tablesUtils';

const defaultFilter = {
  name: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  service: '',
  status: '',
  address: '',
  state: '',
  invoice: ''
};

const Providers = () => {
  const {
    filter, 
    handleChange, 
    onSubmit, 
    onClear, 
    nextPage, 
    prevPage, 
    page
  } = useFilteredPagination(defaultFilter, resetProvidersState, getProvidersList);
  
  const dispatch = useDispatch();
  const providers = useSelector(state => state.providers.list);
  const [showCreate, setShowCreate] = useState(false);

  useEffect(() => {
    return () => dispatch(resetProvidersState());
  }, [dispatch]);

  const openCreate = () => setShowCreate(true);
  const closeCreate = () => setShowCreate(false);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '1rem' }}>Socios</Typography>
        <Button sx={{ marginTop: '0' }} color="primary" onClick={openCreate}>Nuevo</Button>
      </Box>
      <ProvidersFilter {...filter} onChange={handleChange} onSubmit={onSubmit} onClear={onClear} />
      <ProvidersListTable providers={providers} />
      <AppPagination page={page} prev={prevPage} next={nextPage} hasNextPage={providers.length !== 0}/>
      <ProvidersCreateDialog open={showCreate} onClose={closeCreate} />
    </>
  );
}



export default Providers;
