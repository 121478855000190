import { TableRow, TableCell } from '@mui/material';
import { Link } from 'react-router-dom';

const LeadsListItem = props => {
  return (
    <TableRow>
      <TableCell><Link to={props._id}>{props.email}</Link></TableCell>
      <TableCell>{props.name} {props.lastName}</TableCell>
      <TableCell>{props.phoneNumber}</TableCell>
      <TableCell>{new Date(props.updatedAt)?.toLocaleString()}</TableCell>
      <TableCell>{new Date(props.createdAt)?.toLocaleString()}</TableCell>
    </TableRow>
  );
}

export default LeadsListItem;
