import { TableRow, TableCell } from '@mui/material';
import { Link } from 'react-router-dom';

const BenefitsListItem = props => {
  const audiences = {
    Client: 'Clientes',
    Provider: 'Socios'
  }

  return (
    <TableRow>
      <TableCell><Link to={props._id}>{props.title}</Link></TableCell>
      <TableCell>{props.description}</TableCell>
      <TableCell>{props.enabled ? 'Encendido' : 'Apagado'}</TableCell>
      <TableCell>{props.type}</TableCell>
      <TableCell>{props.audiences?.map(audience => audiences[audience])?.join(', ')}</TableCell>
      <TableCell>{new Date(props.createdAt)?.toLocaleString()}</TableCell>
    </TableRow>
  );
}

export default BenefitsListItem;
