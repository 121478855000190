import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { login } from '../../actions/creators/authActionCreators';

const Wrapper = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(4)
}));

const Login = () => {
  const [state, setState] = useState({ email: '', password: '' });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  }

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(login(state.email, state.password, handleLogin));
  }

  const handleLogin = () => {
    navigate('/dashboard')
  }

  return (
    <Wrapper container justifyContent="center" alignItems="center" minHeight="100vh">
      <Grid item maxWidth="550px">
        <Card>
          <CardContent>
            <Grid container padding={2} component="form" onSubmit={handleSubmit}>
              <Grid item xs={12} textAlign="center" marginBottom={2}>
                <Typography variant="h4">Inicia sesión</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField label="Correo" name="email" value={state.email} onChange={handleChange} />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Contraseña" type="password" name="password" value={state.password} onChange={handleChange} />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button type="submit">Entrar</Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Wrapper>
  );
}

export default Login;
