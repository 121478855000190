import { getService, getServices, createService, updateService } from '../../api/servicesApi';
import { SET_SERVICES_LIST, SET_CURRENT_SERVICE } from '../types/servicesTypes';
import { startLoading, endLoading } from './loadingActionCreators';
import { showAlert } from './alertsActionCreators';
import { handleError } from './errorsActionCreators';

export const setServicesList = services => ({
  type: SET_SERVICES_LIST,
  payload: { services }
});

export const setCurrentService = service => ({
  type: SET_CURRENT_SERVICE,
  payload: { service }
});

export const getServicesList = query => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getServices(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setServicesList(data.services)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
};

export const getCurrentService = id => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getService(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentService(data.service)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const createNewService = (service, callback) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  createService(token, service)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(data => callback(data.service))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const updateCurrentService = (id, service) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  updateService(token, id, service)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentService(data.service)))
  .then(() => dispatch(showAlert('Servicio actualizado', 'success')))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}
