import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import ProductsListItem from './ProductsListItem';

const ProductsListTable = props => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead color="primary">
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell>Descripción</TableCell>
            <TableCell>Estado</TableCell>
            <TableCell>Prefijo</TableCell>
            <TableCell>Creada</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.products?.map(product => <ProductsListItem key={product._id} {...product} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ProductsListTable;
