import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardHeader, CardContent, CardMedia, Button, Typography, Box } from '@mui/material';

import BenefitDetailsImageDialog from './BenefitDetailImageDialog';

const { REACT_APP_HOST_URL } = process.env;

const styles = {
  card: {
    marginBottom: '1.5rem',
    maxWidth: '500px',
    mx: 'auto'
  },
  media: {
    height: '25vh',
    cursor: 'pointer'
  },
  avatar: {
    width: '80px',
    height: '80px',
    cursor: 'pointer'
  },
  header: {
    color: '#000',
    backgroundColor: '#FFF',
    textAlign: 'start'
  },
  content: {
    paddingTop: '0'
  },
  bottonWrapper: {
    textAlign: 'center',
    marginTop: '1rem'
  },
  button: {
    borderRadius: '1rem'
  }
}


const BenefitDetailsPreview = props => {
  const [openImage, setOpenImage] = useState(false);
  const [timestamp, setTimestamp] = useState(new Date().toISOString());

  const current = useSelector(state => state.benefits.current);
    
  useEffect(() => {
    setTimestamp(new Date().toISOString());
  }, [current]);

  const handleOpenImage = () => setOpenImage(true);
  const handleCloseImage = () => setOpenImage(false);

  const imageUrl = (props.imageUrl ? REACT_APP_HOST_URL + props.imageUrl : '/img/missing.png') + '?timestamp=' + timestamp;

  return (
    <>
      <Card sx={styles.card}>
        <CardMedia component="img" image={imageUrl} sx={styles.media} onClick={handleOpenImage} />
        <CardHeader sx={styles.header}
          title={props.title}
        />
        <CardContent sx={styles.content}>
          <Typography variant='body1'>{props.description}</Typography>
          <Box textAlign="end">
            <Button color="success" sx={styles.button} href={props.ctaUrl} target="_blank">{props.ctaText ?? 'CTA'}</Button>
          </Box>
        </CardContent>
      </Card>
      <BenefitDetailsImageDialog id={props._id} src={imageUrl} open={openImage} onClose={handleCloseImage} />
    </>
  )
}

export default BenefitDetailsPreview;
