import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Grid, TextField, Dialog, DialogContent, DialogTitle, Button, DialogActions, MenuItem } from '@mui/material';

import { createNewProduct } from '../../../actions/creators/productsActionCreators';

const styles = {
  container: {
    display: 'block'
  },
  content: {
    paddingY: 0
  },
  actions: {
    paddingTop: 0,
    paddingRight: '1rem'
  },
  inputWrapper: {
    paddingX: '0.25rem'
  },
}

const ProductsCreateDialog = props => {
  const [product, setProduct] = useState({
    name: '',
    description: '',
    type: '',
    prefix: '',
    price: 0,
    duration: 0
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = e => {
    e.preventDefault();
    props.onClose();
  }

  const handleChange = e => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  }

  const handleRedirect = id => {
    navigate('/dashboard/productos/' + id);
  }

  const handleSubmit = e => {
    e.preventDefault();

    dispatch(createNewProduct(product, handleRedirect));
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>Nuevo producto</DialogTitle>
      <DialogContent sx={styles.content}>
        <Grid container>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField label="Nombre" name="name" value={product.name} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField label="Descripción" name="description" value={product.description} onChange={handleChange} />
          </Grid>
          <TextField select label="Tipo" name="type" value={product.type} onChange={handleChange}>
            <MenuItem value="Seguro">Seguro</MenuItem>
            <MenuItem value="Crédito">Crédito</MenuItem>
            <MenuItem value="Anuncio">Anuncio</MenuItem>
          </TextField>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField label="Prefijo" name="prefix" value={product.prefix} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField type="number" label="Precio" name="price" value={product.price} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField type="number" label="Duración (días)" name="duration" value={product.duration} onChange={handleChange} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.actions}>
      <Button variant="text" onClick={handleSubmit}>Guardar</Button>
        <Button variant="text" onClick={handleClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ProductsCreateDialog;
