import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Grid, TextField, Dialog, DialogContent, DialogTitle, Button, DialogActions, InputAdornment, IconButton, Typography, Box } from '@mui/material';

import { createNewBundle } from '../../../actions/creators/bundlesActionCreators';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const styles = {
  container: {
    display: 'block'
  },
  content: {
    paddingY: 0
  },
  actions: {
    paddingTop: 0,
    paddingRight: '1rem'
  },
  inputWrapper: {
    paddingX: '0.25rem'
  },
}

const BundlesCreateDialog = props => {
  const [bundle, setBundle] = useState({
    name: '',
    price: '',
    description: '',
    stripeSubscription: '',
    requiredForm:'',
    benefitForm:''
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = e => {
    e.preventDefault();
    props.onClose();
  }

  const handleChange = e => {
    setBundle({ ...bundle, [e.target.name]: e.target.value });
  }

  const handleRedirect = id => {
    navigate('/dashboard/paquetes/' + id);
  }

  const handleSubmit = e => {
    e.preventDefault();
    const bundle = {
      ...bundle, requirements,benefits
    }
    dispatch(createNewBundle(bundle, handleRedirect));
  }

  const [requirements, setRequirements] = useState([]);

  const handleRequirements = () => {
    const isRequirements = requirements.find((req)=>req===bundle.requiredForm);
    if(isRequirements){
      setBundle((prevState) => {return {...prevState, 'requiredForm':''};});
      return;
    }
    setRequirements((prevState)=>{ return [...prevState, bundle.requiredForm];});
    setBundle((prevState) => {return {...prevState, 'requiredForm':''};});
  };

  const handleRemoveRequirements = (req) => {
    const newRequirements = requirements.filter((requirement) => requirement!==req);
    console.log({newRequirements});
    setRequirements([...newRequirements]);
  }

  const [benefits, setBenefits] = useState([]);

  const handleBenefits = () => {
    const isBenefits = benefits.find((req)=>req===bundle.benefitForm);
    if(isBenefits){
      setBundle(prevState=>{ return{...prevState,'benefitForm':''};});
      return;
    }
    setBenefits(prevState=> {return [...prevState, bundle.benefitForm]});
    setBundle(prevState=>{ return{...prevState,'benefitForm':''};});
  }

  const handleRemoveBenefits= (name) => {
    const newBenefits = benefits.filter((benefit) => benefit!==name);
    console.log({newBenefits});
    setBenefits([...newBenefits]);
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>Nuevo paquete</DialogTitle>
      <DialogContent sx={styles.content}>
        <Grid container>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField label="Nombre" name="name" value={bundle.name} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField label="Precio" name="price" value={bundle.price} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField label="IDE de Stripe" name="stripeSubscription" value={bundle.stripeSubscription} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField multiline rows={3} label="Descripción" name="description" value={bundle.description} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
                <TextField fullWidth label="Requisitos" name="requiredForm" value={bundle.requiredForm} onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleRequirements}>
                        <AddCircleIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <Typography variant='h5' component={'p'}>Requisitos</Typography>
            <ul>
              {
                requirements.map(
                  (req => 
                    <li key={req}>
                      <Box display={'flex'} flexDirection='row'>
                        <Box flex='1 1' display={'flex'} justifyContent='left' alignItems={'center'}>
                          <Typography variant='h6' component={'p'}>{req}</Typography>
                        </Box>
                        <Box flex='0 0 50' pr={2}>
                          <IconButton onClick={()=>handleRemoveRequirements(req)}>
                            <RemoveCircleIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </li>
                ))
              }
            </ul>
          </Grid>

          <Grid item xs={12} sx={styles.inputWrapper}>
                <TextField fullWidth label="Beneficio" name="benefitForm" value={bundle.benefitForm} onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleBenefits}>
                        <AddCircleIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <Typography variant='h5' component={'p'}>Beneficios</Typography>
            <ul>
              {
                benefits.map(
                  (ben => 
                    <li key={ben}>
                      <Box display={'flex'} flexDirection='row'>
                        <Box flex='1 1' display={'flex'} justifyContent='left' alignItems={'center'}>
                          <Typography variant='h6' component={'p'}>{ben}</Typography>
                        </Box>
                        <Box flex='0 0 50' pr={2}>
                          <IconButton onClick={()=>handleRemoveBenefits(ben)}>
                            <RemoveCircleIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </li>
                ))
              }
            </ul>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.actions}>
      <Button variant="text" onClick={handleSubmit}>Guardar</Button>
        <Button variant="text" onClick={handleClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default BundlesCreateDialog;
