import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import LeadsListItem from './LeadsListItem';

const LeadsListTable = props => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead color="primary">
          <TableRow>
            <TableCell>Correo</TableCell>
            <TableCell>Nombre</TableCell>
            <TableCell>Teléfono</TableCell>
            <TableCell>Actualizado</TableCell>
            <TableCell>Creado</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.leads?.map(lead => <LeadsListItem key={lead._id} {...lead} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default LeadsListTable;
