import { Card, CardContent, CardMedia, Typography } from "@mui/material";

const { REACT_APP_HOST_URL } = process.env;

const styles = {
  card: {
    display: 'inline-block',
    margin: '1rem',
    maxWidth: '300px'
  },
}

const ServicesItemCard = props => {
  return (
    <Card sx={styles.card}>
      <CardMedia sx={styles.media} component="img" src={REACT_APP_HOST_URL + props.icon} />
      <CardContent>
        <Typography variant="body1" fontWeight="bold" align="center">{props.name}</Typography>
        <Typography variant="body2" align="center">{props._id}</Typography>
      </CardContent>
    </Card>
  );
}

export default ServicesItemCard;
