
import { startLoading, endLoading } from './loadingActionCreators';
import { handleError } from './errorsActionCreators';
import { SET_ORGANIZATIONS_LIST } from '../types/organizationsTypes';
import { getOrganizations } from '../../api/organizationsApi';

export const setOrganizationsList = organizations => ({
  type: SET_ORGANIZATIONS_LIST,
  payload: { organizations }
});

export const getOrganizationsList = query => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getOrganizations(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setOrganizationsList(data.organizations)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
};

