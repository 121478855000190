import { Box, Card, CardHeader, CardContent, Typography, TextField, Button, MenuItem, Table, TableBody, TableRow, TableCell } from '@mui/material';

const styles = {
  card: {
    marginBottom: '1.5rem'
  }
}

const WorkOrderDetailsCard = props => {
  const onlyRead = props.org !== "milusos"
  return (
    <Card sx={styles.card}>
      <CardHeader title="Órden de trabajo" />
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Organización</TableCell>
              <TableCell>{props?.organization?.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Título</TableCell>
              <TableCell>{props.title}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Descripción</TableCell>
              <TableCell>{props.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>
                <TextField select label="Status" name="status" value={props.status} onChange={props.onChange} disabled={onlyRead}>
                  <MenuItem value="Esperando">Esperando</MenuItem>
                  <MenuItem value="Aceptado">Aceptado</MenuItem>
                  <MenuItem value="Iniciado">Iniciado</MenuItem>
                  <MenuItem value="Completado">Completado</MenuItem>
                  <MenuItem value="Terminado">Terminado</MenuItem>
                  <MenuItem value="Cancelado">Cancelado</MenuItem>
                </TextField>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Servicio</TableCell>
              <TableCell>{props.serviceType?.name ?? props.other}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Dirección</TableCell>
              <TableCell>{props.address}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Precio</TableCell>
              <TableCell>{props.cost ? `$${props.cost}` : 'Sin precio'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Box sx={{ textAlign: 'center' }}>
          {props.org === "milusos" && <Button variant="contained" onClick={props.onSubmit}>Guardar</Button>}          
        </Box>
        <Typography align="right" variant="body2">Solicitado el: {new Date(props.createdAt)?.toLocaleString()}</Typography>
      </CardContent>
    </Card>
  );
}

export default WorkOrderDetailsCard;
