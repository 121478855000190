import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import AccidentsListItem from './AccidentsListItem';

const AccidentsListTable = props => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead color="primary">
          <TableRow>
            <TableCell>Identificador</TableCell>
            <TableCell>Correo</TableCell>
            <TableCell>Teléfono</TableCell>
            <TableCell>Póliza</TableCell>
            <TableCell>Creado en</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.accidents?.map(accident => <AccidentsListItem key={accident._id} {...accident} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default AccidentsListTable;
