import { CLEAR_TOKEN, SET_TOKEN } from '../actions/types/authTypes';

const defaultState = {
  token: null,
  org: null
};

const authReducer = (state = defaultState, { type, payload }) => {
  const newState = { ...state };

  switch(type) {
    case SET_TOKEN: 
      newState.token = payload.token; 
      newState.org = payload.org; 
      break;
    case CLEAR_TOKEN: 
      newState.token = null;
      newState.org = null;
      break;
    default: break;
  }

  return newState;
}

export default authReducer;
