import { TableRow, TableCell } from '@mui/material';
import { Link } from 'react-router-dom';

import { getPromoAction } from '../../../utils/promosUtils';

const PromosListItem = props => {
  return (
    <TableRow>
      <TableCell><Link to={props._id}>{props.code}</Link></TableCell>
      <TableCell>{getPromoAction(props.action)}</TableCell>
      <TableCell>{props.value}</TableCell>
      <TableCell>{new Date(props.createdAt)?.toLocaleString()}</TableCell>
    </TableRow>
  );
}

export default PromosListItem;
