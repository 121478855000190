import { SET_SERVICES_LIST, SET_CURRENT_SERVICE } from '../actions/types/servicesTypes';

const defaultState = {
  list: [],
  current: { }
};

const servicesReducer = (state = defaultState, { type, payload }) => {
  const newState = { ...state };

  switch(type) {
    case SET_SERVICES_LIST: newState.list = [...payload.services]; break;
    case SET_CURRENT_SERVICE: newState.current = { ...payload.service }; break;
    default: break;
  }

  return newState;
}

export default servicesReducer;
