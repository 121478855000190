import { RESET_CLIENTS_STATE, SET_CLIENTS_LIST, SET_CURRENT_CLIENT } from '../actions/types/clientsTypes';

const defaultState = {
  list: [],
  current: { }
};

const clientsReducer = (state = defaultState, { type, payload }) => {
  const newState = { ...state };

  switch(type) {
    case SET_CLIENTS_LIST: newState.list = [...payload.clients]; break;
    case SET_CURRENT_CLIENT: newState.current = { ...payload.client }; break;
    case RESET_CLIENTS_STATE: newState.list = []; newState.current = { }; break;
    default: break;
  }

  return newState;
}

export default clientsReducer;
