import { initializeApp } from 'firebase/app';
import { getFirestore, onSnapshot, doc, query, collection, addDoc, deleteDoc, serverTimestamp, orderBy } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDKaqFbedZloVRdp1AdLCY5bTOfDQC2jEE',
  authDomain: 'milusos-224606.firebaseapp.com',
  databaseURL: 'https://milusos-224606.firebaseio.com',
  projectId: 'milusos-224606',
  storageBucket: 'milusos-224606.appspot.com',
  messagingSenderId: '755201292781',
  appId: '1:755201292781:web:6c650993e6262730ba0fe4',
  measurementId: 'G-6NR48ZDMSH'
};

const { REACT_APP_FIRESTORE_CHATS_COLLECTION } = process.env;

export const initializeFirebase = () => initializeApp(firebaseConfig);

export const listenChat = (workOrderId, callback) => {
  const db = getFirestore();

  const q = query(collection(db, REACT_APP_FIRESTORE_CHATS_COLLECTION, workOrderId, 'messages'), orderBy('date'));

  return onSnapshot(q, querySnapshot => callback(querySnapshot));
}

export const sendMessage = async (workOrderId, message) => {
  const db = getFirestore();

  await addDoc(collection(db, REACT_APP_FIRESTORE_CHATS_COLLECTION, workOrderId, 'messages'), {
    ...message,
    date: serverTimestamp()
  });
}

export const deleteMessage = async (workOrderId, messageId) => {
  const db = getFirestore();

  await deleteDoc(doc(db, REACT_APP_FIRESTORE_CHATS_COLLECTION, workOrderId, messageId));
}
