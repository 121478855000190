import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';

import ServicesItemCard from './ServicesItemCard';

import { getServicesList } from '../../../actions/creators/servicesActionCreators';

const ServicesList = () => {
  const services = useSelector(state => state.services.list);
  const dispatch = useDispatch();

  useEffect(() => dispatch(getServicesList()), []);

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="h5" fontWeight="bold" sx={{ my: '1rem' }}>Lista de servicios</Typography>
      {services.map(service => <ServicesItemCard key={service._id} {...service} />)}
    </Box>
  );
}

export default ServicesList;
