const { REACT_APP_API_BASE_URL } = process.env;

export const getProviders = (token, query) => new Promise((resolve, reject) => {
  const queryParams = new URLSearchParams({
    results: 10,
    ...query
  }).toString();

  const request = new Request(REACT_APP_API_BASE_URL + '/providers?' + queryParams, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getProvider = (token, id) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/providers/' + id, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const createProvider = (token, provider) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/providers/new', {
    method: 'POST',
    body: JSON.stringify({ provider }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const updateProvider = (token, id, provider) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/providers/' + id, {
    method: 'PUT',
    body: JSON.stringify({ provider }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const uploadProviderPhoto = (token, id, photo, type) => new Promise((resolve, reject) => {
  const data = new FormData();
  data.append('photo', photo);

  const request = new Request(`${REACT_APP_API_BASE_URL}/providers/${id}/${type}`, {
    method: 'PUT',
    body: data,
    headers: {
      'Authorization': 'Bearer ' + token
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const resetProviderPassword = (token, id, password) => new Promise((resolve, reject) => {
  const request = new Request(`${REACT_APP_API_BASE_URL}/providers/${id}/reset-password`, {
    method: 'PUT',
    body: JSON.stringify({ data: { password } }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});
