import { Typography } from '@mui/material';

import ServicesList from './ServicesList';
import ServicesCharts from './ServicesCharts';

const Services = () => {
  return (
    <>
      <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '1rem' }}>Servicios</Typography>
      <ServicesCharts />
      <ServicesList />
    </>
  );
}

export default Services;
