export const PROMOS_ACTIONS = {
  ADD_SERVICE_COUNT: 'ADD_SERVICE_COUNT',
  APPLY_PERCENTAGE_DISCOUNT: 'APPLY_PERCENTAGE_DISCOUNT',
  APPLY_AMOUNT_DISCOUNT: 'APPLY_AMOUNT_DISCOUNT'
}

export const getPromoAction = action => {
  switch(action) {
    case PROMOS_ACTIONS.APPLY_AMOUNT_DISCOUNT: return 'Descuento monto';
    case PROMOS_ACTIONS.APPLY_PERCENTAGE_DISCOUNT: return 'Descuento porcentaje';
    default: return action;
  }
}
