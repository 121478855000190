import { TableRow, TableCell } from '@mui/material';
import { Link } from 'react-router-dom';

const ProductsListItem = props => {
  return (
    <TableRow>
      <TableCell><Link to={props._id}>{props.name}</Link></TableCell>
      <TableCell>{props.description}</TableCell>
      <TableCell>{props.enabled ? 'Encendido' : 'Apagado'}</TableCell>
      <TableCell>{props.prefix}</TableCell>
      <TableCell>{new Date(props.createdAt)?.toLocaleString()}</TableCell>
    </TableRow>
  );
}

export default ProductsListItem;
