import { useSelector } from 'react-redux';
import { Card, CardHeader, CardContent, Table, TableBody, TableRow, TableCell } from '@mui/material';
import WorkOrderDetailsPaymentsItem from './WorkOrderDetailsPaymentsItem';

const styles = {
  card: {
    marginBottom: '1.5rem'
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  table: {
    marginTop: '1rem'
  },
  tableHead: {
    fontWeight: 'bold'
  },
  inputWrapper: {
    paddingX: '0.5rem'
  }
}

const WorkOrderDetailsPayments = () => {
  const payments = useSelector(state => state.payments.list);

  return (
    <Card sx={styles.card}>
      <CardHeader title="Pagos" />
      <CardContent>
        <Table sx={styles.table}>
          <TableBody>
            <TableRow>
              <TableCell sx={styles.tableHead}>Cantidad</TableCell>
              <TableCell sx={styles.tableHead}>Descripción</TableCell>
              <TableCell sx={styles.tableHead}>Pagado</TableCell>
              <TableCell sx={styles.tableHead}>Método</TableCell>
            </TableRow>
            {payments?.map(payment => <WorkOrderDetailsPaymentsItem key={payment._id} {...payment} />)}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

export default WorkOrderDetailsPayments;
