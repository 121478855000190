import { getWorkOrder, getWorkOrders, createWorkOrder, updateWorkOrder, reassignWorkOrder, reesendNotificationWorkOrder, deleteWorkOrder } from '../../api/workOrdersApi';
import { SET_WORK_ORDERS_LIST, SET_CURRENT_WORK_ORDER, RESET_WORK_ORDERS_STATE } from '../types/workOrdersTypes';
import { startLoading, endLoading } from './loadingActionCreators';
import { showAlert } from './alertsActionCreators';
import { handleError } from './errorsActionCreators';

export const setWorkOrdersList = workOrders => ({
  type: SET_WORK_ORDERS_LIST,
  payload: { workOrders }
});

export const setCurrentWorkOrder = workOrder => ({
  type: SET_CURRENT_WORK_ORDER,
  payload: { workOrder }
});

export const resetWorkOrdersState = () => ({
  type: RESET_WORK_ORDERS_STATE,
  payload: { }
});

export const getWorkOrdersList = query => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getWorkOrders(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setWorkOrdersList(data.workOrders)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
};

export const getCurrentWorkOrder = id => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getWorkOrder(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentWorkOrder(data.workOrder)))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const createNewWorkOrder = (workOrder, callback) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  createWorkOrder(token, workOrder)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(data => callback(data.workOrder))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const updateCurrentWorkOrder = (id, workOrder) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  updateWorkOrder(token, id, workOrder)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentWorkOrder(data.workOrder)))
  .then(() => dispatch(showAlert('Orden actualizada', 'success')))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const reassignCurrentWorkOrder = (id, providerEmail) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  reassignWorkOrder(token, id, { providerEmail })
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentWorkOrder(data.workOrder)))
  .then(() => dispatch(showAlert('Orden reasignada', 'success')))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const reesendNotificationCurrentWorkOrder = (id) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  reesendNotificationWorkOrder(token, id)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(() => dispatch(showAlert('Notificación enviada', 'success')))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

export const deleteCurrentWorkOrder = (id) => (dispatch, getState) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  deleteWorkOrder(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(() => dispatch(showAlert('Órden de trabajo Eliminada', 'success')))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
}

