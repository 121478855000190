import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import AccidentDetailsCard from './AccidentDetailsCard';

import { getCurrentAccident, resetAccidentsState, updateCurrentAccident } from '../../../../actions/creators/accidentsActionCreators';
import AccidentDetailsFiles from './AccidentDetailsFiles';

const styles = {
  title: {
    marginBottom: '1rem'
  },
  column: {
    paddingX: '1rem'
  }
}

const AccidentDetails = () => {
  const params = useParams();

  const [accident, setAccident] = useState({
    _id: '',
    identifier: '',
    policy: '',
    email: '',
    phoneNumber: '',
    files: []
  });
  
  const dispatch = useDispatch();
  const current = useSelector(state => state.accidents.current);
  
  useEffect(() => setAccident({ ...accident, ...current }), [current]);

  const handleChange = e => {
    setAccident({ ...accident, [e.target.name]: e.target.value });
  }

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(updateCurrentAccident(params.id, accident));
  }

  useEffect(() => {
    dispatch(getCurrentAccident(params.id));
    return () => dispatch(resetAccidentsState())
  }, []);

  return (
    <>
      <Typography variant="h5" sx={styles.title} fontWeight="bold">Siniestro {params.id}</Typography>
      <Grid container>
        <Grid item xs={12} sx={styles.column}>
          <AccidentDetailsCard {...accident} onChange={handleChange} onSubmit={handleSubmit} />
          <AccidentDetailsFiles {...accident} />
        </Grid>
      </Grid>
    </>
  );
}

export default AccidentDetails;
