import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';

import BenefitDetailsForm from './BenefitDetailsForm';
import BenefitDetailsPreview from './BenefitDetailsPreview';

import { getCurrentBenefit, resetBenefitsState, updateCurrentBenefit } from '../../../../actions/creators/benefitsActionCreators';

const styles = {
  title: {
    marginBottom: '1rem'
  }
}

const BenefitDetails = () => {
  const [benefit, setBenefit] = useState({
    _id: '',
    title: '',
    description: '',
    enabled: false,
    ctaText: '',
    ctaUrl: '',
    type: '',
    audiences: []
  });

  const params = useParams();
  const dispatch = useDispatch();
  const current = useSelector(state => state.benefits.current);

  useEffect(() => {
    dispatch(getCurrentBenefit(params.id));
    return () => dispatch(resetBenefitsState());
  }, []);
  
  useEffect(() => setBenefit({ ...benefit, ...current }), [current]);

  const handleChange = e => {
    setBenefit({ ...benefit, [e.target.name]: e.target.value });
  }

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(updateCurrentBenefit(params.id, benefit));
  }

  return (
    <>
      <Typography variant="h5" sx={styles.title} fontWeight="bold">Beneficio {benefit._id}</Typography>
      <Grid container>
        <Grid item sm={12} md={7} sx={{ paddingX: '1rem' }}>
          <BenefitDetailsForm {...benefit} onChange={handleChange} onSubmit={handleSubmit} />
        </Grid>
        <Grid item sm={12} md={5} sx={{ paddingX: '1rem' }}>
          <BenefitDetailsPreview {...benefit} />
        </Grid>
      </Grid>
    </>
  );
}

export default BenefitDetails;
