import { TableRow, TableCell } from '@mui/material';
import { Link } from 'react-router-dom';

const ProvidersListItem = props => {
  return (
    <TableRow>
      <TableCell><Link to={props._id}>{props.email}</Link></TableCell>
      <TableCell>{props.name} {props.lastName}</TableCell>
      <TableCell>{props.phoneNumber}</TableCell>
      <TableCell>{props.status}</TableCell>
      <TableCell>{props.contacted?"Si":"No"}</TableCell>
      <TableCell>{props?.offeredServices?.map(service => service?.serviceRef?.name).join(', ')}</TableCell>
      <TableCell>{new Date(props.createdAt)?.toLocaleString()}</TableCell>
    </TableRow>
  );
}

export default ProvidersListItem;
