import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Card, CardHeader, CardContent, TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import { deleteCurrentWorkOrder, reassignCurrentWorkOrder, reesendNotificationCurrentWorkOrder } from '../../../../actions/creators/workOrdersActionCreators';
import { useNavigate } from 'react-router-dom';

const styles = {
  card: {
    marginBottom: '1.5rem'
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    gap: "1em"
  }
}

const WorkOrderDetailsActions = props => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(reesendNotificationCurrentWorkOrder(props.id));
  }

  const handleDelete = e => {
    e.preventDefault();
    dispatch(deleteCurrentWorkOrder(props.id));
    navigate("/dashboard/ordenes");
  }

  return (
    <Card sx={styles.card}>
      <CardHeader title="Acciones" />
      <CardContent>
        <Box sx={styles.buttonWrapper}>
          <Button onClick={handleSubmit} disabled={props.status !=="Esperando"}>Notificar</Button>
          <Button onClick={handleClickOpen} color="error">Eliminar</Button>
        </Box>
      </CardContent>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Eliminar Órden de trabajo?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Estás seguro que quieres eliminar esta Órden de trabajo
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleDelete} autoFocus color="error">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default WorkOrderDetailsActions;
