const { REACT_APP_API_BASE_URL } = process.env;

export const getPromos = (token, query) => new Promise((resolve, reject) => {
  const queryParams = new URLSearchParams({
    results: 10,
    ...query
  }).toString();

  const request = new Request(REACT_APP_API_BASE_URL + '/promos?' + queryParams, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getPromo = (token, id) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/promos/' + id, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const createPromo = (token, promo) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/promos', {
    method: 'POST',
    body: JSON.stringify({ promo }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const updatePromo = (token, id, promo) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/promos/' + id, {
    method: 'PUT',
    body: JSON.stringify({ promo }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});
