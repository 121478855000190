import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Collapse, Typography, Box, Grid, TextField, Button, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { getServicesList } from '../../../actions/creators/servicesActionCreators';
import { getOrganizationsList } from '../../../actions/creators/organizationsActionCreators';

const styles = {
  paper: {
    marginBottom: '1rem'
  },
  header: {
    padding: '1rem',
    display: 'flex',
    justifyContent: 'around'
  },
  title: {
    flex: 1
  },
  container: {
    padding: '1rem',
    paddingTop: '0'
  },
  inputWrapper: {
    paddingX: '0.5rem'
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-around'
  }
}

const WorkOrdersFilter = props => {
  const dispatch = useDispatch();
  const services = useSelector(state => state.services.list);
  const organizations = useSelector(state => state.organizations.list);
  const auth = useSelector(state => state.auth)

  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(!open);

  useEffect(() => dispatch(getServicesList()), []);
  useEffect(() => dispatch(getOrganizationsList()), []);

  return (
    <Paper elevation={3} sx={styles.paper}>
      <Box sx={styles.header} onClick={toggleOpen}>
        <Typography variant="h6" fontWeight="bold" sx={styles.title}>Filtro</Typography>
        <ArrowDropDownIcon />
      </Box>
      <Collapse in={open}>
        <Grid container component="form" sx={styles.container} onSubmit={props.onSubmit}>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Título" name="title" value={props.title} onChange={props.onChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Descripción" name="description" value={props.description} onChange={props.onChange} />
          </Grid>
          <Grid item xs={12} md={3} sx={styles.inputWrapper}>
            <TextField select label="Status" name="status" value={props.status} onChange={props.onChange}>
              <MenuItem value="">Cualquiera</MenuItem>
              <MenuItem value="Esperando">Esperando</MenuItem>
              <MenuItem value="Aceptado">Aceptado</MenuItem>
              <MenuItem value="Iniciado">Iniciado</MenuItem>
              <MenuItem value="Completado">Completado</MenuItem>
              <MenuItem value="Terminado">Terminado</MenuItem>
              <MenuItem value="Cancelado">Cancelado</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={3} sx={styles.inputWrapper}>
            <TextField select label="Servicio" name="service" value={props.service} onChange={props.onChange}>
              <MenuItem value="">Cualquiera</MenuItem>
              {services.map(service => <MenuItem key={service._id} value={service._id}>{service.name}</MenuItem>)}
            </TextField>
          </Grid>          
          {auth.org ==="milusos" && 
            <>
              <Grid item xs={12} md={3} sx={styles.inputWrapper}>
                <TextField select label="De socio" name="record" value={props.record} onChange={props.onChange}>
                  <MenuItem value={false}>No</MenuItem>
                  <MenuItem value={true}>Sí</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={3} sx={styles.inputWrapper}>
                <TextField select label="Organización" name="organization" value={props.organization} onChange={props.onChange}>
                  <MenuItem value="Todas">Todas</MenuItem>
                  {organizations?.map(organization => <MenuItem key={organization._id} value={organization._id}>{organization.name}</MenuItem>)}
                </TextField>
              </Grid>
            </>          
          }          
          <Grid item xs={12} sx={styles.actions}>
            <Button type="submit">Buscar</Button>
            <Button onClick={props.onClear} color="neutral">Limpiar</Button>
          </Grid>
        </Grid>
      </Collapse>
    </Paper>
  );
}

export default WorkOrdersFilter;
