import { Chip } from '@mui/material';

const styles = {
  audienceChip: {
    margin: '0 0.5rem'
  }
}

const BenefitDetailsFormAudience = props => {
  const isInAudiences = new Set(props.audiences).has(props.value);

  const handleChange = () => {
    const audiences =  isInAudiences ? props.audiences.filter(a => a !== props.value) : [...props.audiences, props.value];

    props.onChange({ target: { value: [...audiences], name: 'audiences' } });
  }

  return (
    <Chip sx={styles.audienceChip} label={props.label} color="primary" variant={isInAudiences ? 'filled' : 'outlined'} onClick={handleChange} />
  );
}

export default BenefitDetailsFormAudience;
