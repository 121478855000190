import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import BundlesListTable from './BundlesListTable';
import BundlesFilter from './BundlesFilter';
import BundlesCreateDialog from './BundlesCreateDialog';
import AppPagination from '../../../components/AppPagination';

import { getBundlesList, resetBundlesState } from '../../../actions/creators/bundlesActionCreators';
import { useFilteredPagination } from '../../../utils/tablesUtils';

const defaultFilter = {
  name: ''
};

const Bundles = () => {
  const {
    filter, 
    handleChange, 
    onSubmit, 
    onClear, 
    nextPage, 
    prevPage, 
    page
  } = useFilteredPagination(defaultFilter, resetBundlesState, getBundlesList);
  const dispatch = useDispatch();
  const bundles = useSelector(state => state.bundles.list);
  const [showCreate, setShowCreate] = useState(false);

  useEffect(() => {
    return () => dispatch(resetBundlesState());
  }, [dispatch]);

  const openCreate = () => setShowCreate(true);
  const closeCreate = () => setShowCreate(false);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '1rem' }}>Paquetes</Typography>
        <Button sx={{ marginTop: '0' }} color="primary" onClick={openCreate}>Nuevo</Button>
      </Box>
      <BundlesFilter {...filter} onChange={handleChange} onSubmit={onSubmit} onClear={onClear} />
      <BundlesListTable bundles={bundles} />
      <AppPagination page={page} prev={prevPage} next={nextPage} hasNextPage={bundles.length !== 0}/>
      <BundlesCreateDialog open={showCreate} onClose={closeCreate} />
    </>
  );
}

export default Bundles;
