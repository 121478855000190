import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import moment from 'moment';

import ServicesStatisticsFilter from './ServicesStatisticsFilter';
import ServicesCostsChart from './ServicesCostsChart';
import ServicesStatisticsChart from './ServicesStatisticsChart';

import { getMetricsServicesStatistics, getMetricsServicesCosts } from '../../../actions/creators/metricsActionCreators';


const defaultFilter = {
  interval: false,
  start: moment().format('YYYY-MM-DD'),
  end: moment().format('YYYY-MM-DD')
};

const ServicesCharts = () => {
  const [filter, setFilter] = useState({ ...defaultFilter });

  const dispatch = useDispatch();

  const handleChange = e => setFilter({ ...filter, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value });

  const handleSubmit = e => {
    e.preventDefault();

    const f = { };

    filter.start.length > 0 ? f.start = filter.start : null;
    filter.end.length > 0 ? f.end = filter.end : null;

    dispatch(getMetricsServicesStatistics(filter.interval ? f : { }));
    dispatch(getMetricsServicesCosts(filter.interval ? f : { }));
  }

  const handleClear = () => setFilter({ ...defaultFilter });

  return (
    <Box>
      <ServicesStatisticsFilter {...filter} onChange={handleChange} onSubmit={handleSubmit} onClear={handleClear} />
      <ServicesStatisticsChart />
      <ServicesCostsChart />
    </Box>
  );
}

export default ServicesCharts;
