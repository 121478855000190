import { RESET_PROMOS_STATE, SET_PROMOS_LIST, SET_CURRENT_PROMO } from '../actions/types/promosTypes';

const defaultState = {
  list: [],
  current: { }
};

const promosReducer = (state = defaultState, { type, payload }) => {
  const newState = { ...state };

  switch(type) {
    case SET_PROMOS_LIST: newState.list = [...payload.promos]; break;
    case SET_CURRENT_PROMO: newState.current = { ...payload.promo }; break;
    case RESET_PROMOS_STATE: newState.list = []; newState.current = { }; break;
    default: break;
  }

  return newState;
}

export default promosReducer;
